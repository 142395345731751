import React, { useState } from 'react';
import axios from 'axios';
import { Button, Form } from 'react-bootstrap';
import Select from 'react-select';


// Icon Imports
import { FaMapLocationDot } from "react-icons/fa6";
import { MdContactMail } from "react-icons/md";
import { MdContactPhone } from 'react-icons/md';
import { MdContacts } from "react-icons/md";
import { FaMoneyBillAlt } from "react-icons/fa";
import { AiFillBank, AiTwotoneProfile } from "react-icons/ai";


// Select Style
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? '2px solid #2849ad' : '2px solid #d1d5db',
    borderRadius: '4px',
    ':hover': {
      border: '2px solid #d1d5db',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#07b1ab' : provided.backgroundColor,
    ':hover': {
      backgroundColor: '#07b1ab60',
    },
    ':first-child': {
      backgroundColor: '#07b1ab60',
    },
  }),
};


const PopupFormDel = () => {
  // Data from Local Storage
  const user = JSON.parse(localStorage.getItem('user'));
  const userRole = user.user_data.role === 'HD' ? 'Head User' : user.role === 'M' ? 'Manager User' : 'Normal User';
  


// List All Payment Profiles by Name
const options = user.delivery_data.map((data, index) => ({
  label: data.del_name ? data.del_name : 'NA',
  value: data
}));

const [showEditForm, setShowEditForm] = useState(true);
const [selectedDsm, setSelectedDsm] = useState(options[0].value);
const [selectedDsmOption, setSelectedDsmOption] = useState(options[0]);


const handleProductSelect = (selectedDsmOption) => {
  setSelectedDsmOption(selectedDsmOption);
  setSelectedDsm(selectedDsmOption.value);
  setShowEditForm(true);
};



// Handling Form Operations
const [formStatus, setFormStatus] = useState('');
const [formValues, setFormValues] = useState({
  route_number: selectedDsm.route_number,
  del_name: '',
  contact_person_name: '',
  contact_person_email: '',
  contact_person_phone: '',
  ddept: '',
  daddress: '',
  sdaddress: '',
  dpostal: '',
  dcity: '',
});

const handleInputChange = (e) => {
  const { name, value } = e.target;
  setFormValues({
    ...formValues,
    [name]: value,
  });
};


const handleSubmit = async (e) => {
  e.preventDefault();
  setFormStatus('Loading...');
  //console.log("Payment ID:  " + selectedDsm.id);
  try {
    const  postData = {
      del_name: formValues.del_name ? formValues.del_name : selectedDsm.del_name,
      contact_person_name: formValues.contact_person_name ? formValues.contact_person_name : selectedDsm.contact_person_name,
      contact_person_email: formValues.contact_person_email ? formValues.contact_person_email : selectedDsm.contact_person_email,
      contact_person_phone: formValues.contact_person_phone ? formValues.contact_person_phone : selectedDsm.contact_person_phone,
      ddept: formValues.ddept ? formValues.ddept : selectedDsm.ddept,
      daddress: formValues.daddress ? formValues.daddress : selectedDsm.daddress,
      sdaddress: formValues.sdaddress ? formValues.sdaddress : selectedDsm.sdaddress,
      dpostal: formValues.dpostal ? formValues.dpostal : selectedDsm.dpostal,
      dcity: formValues.dcity ? formValues.dcity : selectedDsm.dcity,
    }
    console.log("Delivery Form Data: ");
    console.log(postData);

    const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/users/update-delivery/${selectedDsm.id}/`,
    postData
    );
    setFormStatus('Success!');
    console.log(response.message);
  } catch (error) {
    setFormStatus('An error occurred.');
    console.log("Printing Error!!!!");
    console.log(error.response.data);
  }
};





  const rows = [
    { name: "del_name", label: "Profile Name", type: "text" },
    { name: "route_number", label: "Route number", type: "text" },
    { name: "contact_person_name", label: "Contact Personal name", type: "text" },
    { name: "contact_person_email", label: "Contact Personal email", type: "email" },
    { name: "contact_person_phone", label: "Contact Personal mobile", type: "phone" },
    { name: "ddept", label: "Delivery Department", type: "text" },
    { name: "daddress", label: "Delivery Address", type: "address" },
    { name: "sdaddress", label: "Standard Delivery Address", type: "address" },
    { name: "dpostal", label: "Postal code", type: "text" },
    { name: "dcity", label: "City", type: "text" },
  ];
  
  const iconMap = {
    del_name: <AiTwotoneProfile />,
    route_number: <FaMoneyBillAlt />,
    contact_person_name: <MdContacts />,
    contact_person_email: <MdContactMail />,
    contact_person_phone: <MdContactPhone />,
    ddept: <AiFillBank />,
    daddress: <AiFillBank />,
    sdaddress: <AiFillBank />,
    dpostal: <FaMapLocationDot />,
    dcity: <FaMapLocationDot />,
  };
  

  return (
    <>
    {options.length > 1 && (
      <>
      <h2 className="mx-auto mt-10 mb-10 text-center text-xl font-semibold">
        Choose a Payment Profile to Display
      </h2>

    <div className="mx-auto mb-12 flex w-[50%] flex-col justify-around gap-14 sm:gap-6 lg:flex-row">
        <Select
          options={options}
          styles={customStyles}
          className="m-auto w-full"
          onChange={handleProductSelect}
          value={selectedDsmOption}
        />
      </div>
      </>
    )}
    {showEditForm && (
    <Form onSubmit={handleSubmit} className="bg-white py-4 px-12">
      {rows.map((row) => (
        <div key={row.name} className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
          <span className="text-xl text-custom-green">
            {iconMap[row.name]}
          </span>
          <label className="popup_review_label">{row.label}</label>
          <div className="grow">
            {row.name === 'route_number' ? (
              <input
                className="input w-full"
                type={row.type}
                name={row.name}
                value={selectedDsm.route_number}
                disabled
              />
            ) : (
              <input
                className="input w-full"
                type={row.type}
                name={row.name}
                value={formValues[row.name]}
                onChange={handleInputChange}
              />
            )}
          </div>
        </div>
      ))}
      <div className="form-status text-red-500 font-semibold text-sm flex items-center justify-center">
        {formStatus}
    </div>
      <Button
        variant="primary"
        type="submit"
        className="mx-auto my-3 block cursor-pointer border-0 bg-custom-blue px-[100px] py-[10px] text-sm font-semibold"
      >
        Submit
      </Button>
    </Form>
  )}
    </>
  );
};

export default PopupFormDel;