import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Local Imports
import Report from './ad-hock/Reports3';
import ItemBase from './allItems/Items';
import OrderBase from './orders/ordersBase';
import ChatPage from './chat/ChatPage';
import EntityBase from './entity/entityBase';
import TL2 from './notify/TL2';
import Invoice from './orders/invoice/Invoice';

// Icons
import { MdOutlineInventory } from 'react-icons/md';
import { IoChatbubblesOutline } from 'react-icons/io5';
import { MdAdminPanelSettings } from "react-icons/md";
import { BiSolidReport } from "react-icons/bi";
import { icon } from '@fortawesome/fontawesome-svg-core';

export default function BoardBase() {
  // Display Pages at will once selected
  const [selectedPage, setSelectedPage] = useState('Reports');

  const pages = {
    'Reports': Report,
    'Items': ItemBase,
    'OrderBase': OrderBase,
    'EntityBase': EntityBase,
    'Notifications': TL2,
    'Chat': ChatPage,
  };

  // Data from Local Storage
  const user = JSON.parse(localStorage.getItem('user'));

  // Toggling between Tabs
  const tabs = [
    { text: 'Add-Hock Reports', value: 'Reports', status: 'active', icon: <BiSolidReport/>},
    { text: 'Items', value: 'Items', status: 'inactive', icon: <MdOutlineInventory />},
    { text: 'Orders', value: 'OrderBase', status: 'inactive', icon: <BiSolidReport />},
    { text: 'Entity Info.', value: 'EntityBase', status: 'inactive', icon: <BiSolidReport />},
    { text: 'User Activity', value: 'Notifications', status: 'inactive', icon: <BiSolidReport />},
    { text: 'Internal Chat', value: 'Chat', status: 'inactive', icon: <IoChatbubblesOutline />},
  ];

  const handleClick = (value) => {
    setSelectedPage(value);
  };

  const SelectedComponent = pages[selectedPage];

  return (
    <div key="1" className="flex items-start flex-col min-h-screen bg-gray-50">
      <header className="fixed top-0 z-10 w-full bg-gray-50">
        <div className=" mx-auto mb-3 flex max-w-7xl px-4 py-3 shadow-sm">
          <MdAdminPanelSettings className="mt-2 mr-4 text-2xl leading-tight text-custom-blue"/>
          <h1 className="mt-2 text-xl font-bold leading-tight text-custom-blue">
            Assortimed Admin Control Board
          </h1>
         
          <Link to="/" className='ml-auto'>
              <img
                className="ml-auto h-12 w-60"
                src="../img/Logo.png"
                alt="Assortimed Home"
              />
          </Link>
        </div>

        <div>
          <ul className="flex border-b">
            {tabs.map((tab, index) => (
              <li className={tab.value === selectedPage ? '-mb-px mr-1' : 'mr-1'} key={index}>
                <a 
                  className={`bg-white inline-block leading-tight ${tab.value === selectedPage ? 'border-l border-t border-r rounded-t' : ''} py-2 px-4 ${tab.value === selectedPage ? 'text-custom-blue font-bold' : 'text-custom-green font-semibold hover:font-bold hover:text-custom-blue'} cursor-pointer flex items-center`} 
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedPage(tab.value)
                  }}
                >
                  <span className='text-sm mr-2'>{tab.icon}</span>
                  {tab.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </header>
      
      {/**Place Holder for Different Componenets */}
      <main className="pt-36 w-full">
        <div className='p-2'>
          <SelectedComponent />
        </div>
      </main>
    </div>
  );
};
