import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";


// Icon Imports
import { MdFormatListBulleted } from "react-icons/md";
import { MdFormatListBulletedAdd } from "react-icons/md";
import { MdOutlinePlaylistPlay } from "react-icons/md";
import { FcCancel } from "react-icons/fc";
import { FaEdit, FaSave } from "react-icons/fa";


// Local Imports
import Product1 from "./Products1";



export default function NewProductItem() {
    

    // Navigate Different Company Choices
    const [oneProduct, setOneProduct] = useState(true);
    const [newProducts, setNewProducts] = useState(false);

    const addOneProduct = () => {
        setOneProduct(true);
        setNewProducts(false);
    };
    const addNewProducts = () => {
        setNewProducts(true);
        setOneProduct(false);
    };

    const buttons = [
        {id: 1,icon: <MdFormatListBulleted className="text-lg mt-1" />, label: "Add One Product", func: addOneProduct, con: oneProduct},
        {id: 2,icon: <MdOutlinePlaylistPlay className="text-lg mt-1" />, label: "Add Products", func: addNewProducts, con: newProducts},
    ];

    // Edit Action Buttons
    const saveChanges = () => {
        console.log("Save Changes");
    };

    const cancelChanges = () => {
        setOneProduct(false);
        setNewProducts(false);
    };

    const [resultFromChild, setResultFromChild] = useState(null);
    const handleDataReturn = (data) => {
        setResultFromChild(data);
    };


  return (
    <div className="flex flex-col justify-center items-center">

            <div className="flex flex-row gap-4 justify-center w-[70%] mb-4">
                {buttons.map((button) => (
                <div key={button.id} className="flex items-end md:mb-0 md:w-auto">
                    <button onClick={button.func} 
                            className={`w-full rounded-md text-custom-blue border-2
                                        px-10 py-2 font-semibold ${button.con ? 'bg-custom-blue text-white' : 'hover:bg-custom-blue hover:text-white'}
                                        sm:w-auto flex flex-row gap-2 justify-center`}>
                    {button.icon}
                    <span className="text-lg">{button.label}</span>
                    </button>
                </div>
                ))}
            </div>

            
            { oneProduct || newProducts ?
            <div className="w-full rounded-lg">

                {oneProduct?
                    // <Product1 dsmRecord={dsmRecord} onDataReturn={handleDataReturn} />
                    <></>
                : null}

                {newProducts?
                    <Product1/>
                : null}


                { oneProduct ?
                <div className="flex items-start justify-center mb-4 gap-2"> 
                        <button  className=" w-full rounded-md text-red-500 border-2 px-6 py-2 font-semibold hover:bg-red-500 hover:text-white sm:w-auto flex flex-row gap-2 justify-center"
                                onClick={cancelChanges}>
                                <FcCancel className="text-xl"/>
                                <span>Cancel Changes</span>
                        </button>
                        <button  
                                className=" w-full rounded-md hover:text-custom-blue text-slate-50 border-2 px-6 py-2 font-semibold bg-custom-blue  hover:bg-white sm:w-auto flex flex-row gap-2 justify-center">
                                <FaSave className="text-xl"/>
                                <span>Save Changes</span>
                        </button>
                </div> : null}
            </div> : null}
        </div>
  );
}