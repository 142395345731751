import React, { useRef, useState, useEffect } from "react";
import { Modal, Box } from '@mui/material';
import Select from 'react-select';
import axios from "axios";

// Icon Imports 
import { FaArrowAltCircleLeft, FaArrowAltCircleRight, FaPlusCircle, FaSave } from "react-icons/fa";


// Local Imports
import './Gallery.css';
import UserDetails from "../summary/UserSum";
import CompanyDetails from "../summary/ComSum";
import { FcCancel } from "react-icons/fc";




export default function NewDSM({ onDataReturn }) {
  const storedProducts = JSON.parse(localStorage.getItem('products'));
  const imgPaths = JSON.parse(localStorage.getItem('imgPaths'));
  //const packItems = dsmRecord?.products || [];

  const productCards = storedProducts.map((product, index) => ({
     value: product || [],
     label: product.name || '',
  }));

  const getProductById = (id) => {
    const product = storedProducts.find(product => product.id === id);
    return product;
  }

  const getProductNameById = (id) => {
    const product = getProductById(id);
    return product.product_name;
  }

  const getProductPIDById = (id) => {
    const product = getProductById(id);
    return product.assortimed_pid;
  }

  const [cards, setCards] = useState([]);

  const galleryRef = useRef(null);

  const scrollLeft = () => {
    galleryRef.current.scrollBy({ left: -200, behavior: 'smooth' });
  };

  const scrollRight = () => {
    galleryRef.current.scrollBy({ left: 200, behavior: 'smooth' });
  };

  // Info Popoups
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupType, setPopupType] = useState(null);

  const handleButtonClick = (type) => {
      setPopupType(type);
      setPopupVisible(true); 
  };


  const modelStyle = {
    position: 'absolute',
    top: '25vh',
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: '50%',
    maxHeight: '50vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
    rounded: 'full',
  };

  const onClose = () => {
    setPopupVisible(false);
    setPopupType(null);
  }


  // Products Items into Package
  const [cardInputValues, setCardInputValues] = useState([]);

  const handleCardInputChange = (e, index, key) => {
    const newInputValues = [...cardInputValues];
    newInputValues[index][key] = e.target.value;
    setCardInputValues(newInputValues);
  };

  // const handleDelete = (index) => {
  //   console.log("Type of cardInputValues:", typeof cardInputValues);
  //   if (Array.isArray(cardInputValues)) {
  //     const newCardInputValues = cardInputValues.filter((_, i) => i !== index);
  //     const newCards = cards.filter((_, i) => i !== index);
  //     setCardInputValues(newCardInputValues);
  //     setCards(newCards);
  //   } else {
  //     console.error("cardInputValues is not an array");
  //   }
  // };

  const handleDelete = (index) => {
    console.log("Type of cardInputValues:", typeof cardInputValues);
    if (typeof cardInputValues === 'object' && cardInputValues !== null) {
      const keys = Object.keys(cardInputValues);
      const keyToDelete = keys[index];
  
      if (keyToDelete !== undefined) {
        const { [keyToDelete]: _, ...newCardInputValues } = cardInputValues;
        const newCards = cards.filter((_, i) => i !== index);
  
        setCardInputValues(newCardInputValues);
        setCards(newCards);
      } else {
        console.error("Invalid index for cardInputValues");
      }
    } else {
      console.error("cardInputValues is not an object");
    }
  };


  // Add products to package section
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleSelectChange = (selectedOption) => {
    setSelectedProduct(selectedOption);
    // Save the selected product value to some place
    //console.log("Selected product:", selectedOption);
  };

  const handleNewCardInputChange = (e, productId, key) => {
   setCardInputValues({
     ...cardInputValues,
     [productId]: {
       ...cardInputValues[productId],
       [key]: e.target.value,
     },
   });
 };

 
 const addNewProduct = (product, lowerStockLimit, upperStockLimit, productStatus) => {
   const newCardInputValues = {
     ...cardInputValues,
     [product.id]: {
       lower_spec_stock: Number(lowerStockLimit) || 100,
       upper_spec_stock: Number(upperStockLimit) || 1000,
       product_status: productStatus || 'active',
       product_status_date: new Date().toISOString().slice(0, 10),
       productRecord: product,
     },
   };
 
   setCardInputValues(newCardInputValues);
 
   const newCard = {
     item: {
       lower_spec_stock: Number(lowerStockLimit) || 100,
       upper_spec_stock: Number(upperStockLimit) || 1000,
       product_status: productStatus || 'active',
       product_status_date: new Date().toISOString().slice(0, 10),
     },
     product: product,
     imgSrc: imgPaths[product.assortimed_pid][0],
   };
 
   setCards([...cards, newCard]);
 };


  // ADD and Cancel Actions
  const cancelChanges = () => {
    onDataReturn();
  };


  // Enter Products List
  const formattedDate = (new Date()).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
  const [inputValues, setInputValues] = useState({
   dsmName: { value: '', disabled: false},
   department: { value: '', disabled: false },
   dsmStatus: { value: '', disabled: false },
   dsmStatusDate: { value: '', disabled: true },
   numOfProducts: { value: 0, disabled: true }, // Caculated on Site
   creationFee: { value: 0.00, disabled: true },
   creationFeeStatus: { value: '', disabled: false },
   creationDate: { value: '', disabled: true },
   refillPeriod: { value: '', disabled: false },
   refillDate: { value: '', disabled: true }, // Caculated on Site
   refillProposalStatus: { value: '', disabled: false },
   monthlyDSMFee: { value: 0.00, disabled: true }, // Caculated on Site
 });

  const handleInputChange = (e, key) => {
    setInputValues({
      ...inputValues,
      [key]: {
        ...inputValues[key],
        value: e.target.value,
      },
    });
  };


  const getCreationFee = (numOfItems) => {
   if (numOfItems === 0) {
     return 0;
   } else if (numOfItems > 0 && numOfItems <= 15) {
     return 250;
   } else if (numOfItems > 15 && numOfItems <= 25) {
     return 375;
   } else if (numOfItems > 25 && numOfItems <= 35) {
     return 450;
   } else {
     return 625;
   }
 };

  useEffect(() => {
    // Update docPrice when numOfProducts changes
    const numOfProducts = cards.length || 0;
    setInputValues((prevValues) => ({
      ...prevValues,
      numOfProducts: { ...prevValues.numOfProducts, value: numOfProducts },
    }));
  }, [cards]);

  useEffect(() => {
    // Update docPrice when creationFee changes
    const creationFee = getCreationFee(cards.length);
    setInputValues((prevValues) => ({
      ...prevValues,
      creationFee: { ...prevValues.creationFee, value: creationFee },
    }));
  }, [inputValues.numOfProducts.value]);


  useEffect(() => {
    // Update docPrice when monthlyDSMFee changes
    const monthlyDSMFee = getCreationFee(cards.length);
    setInputValues((prevValues) => ({
      ...prevValues,
      monthlyDSMFee: { ...prevValues.monthlyDSMFee, value: monthlyDSMFee },
    }));
  }, [inputValues.numOfProducts.value]);

  useEffect(() => {
    if (inputValues.dsmStatus.value !== '') {
      // Update docPrice when dsmStatus changes
      setInputValues((prevValues) => ({
        ...prevValues,
        dsmStatusDate: { ...prevValues.dsmStatusDate, value: new Date().toISOString().slice(0, 10) },
      }));
    }
  }, [inputValues.dsmStatus.value]);

  
  useEffect(() => {
    if (inputValues.creationFeeStatus.value !== '') {
      // Update docPrice when creationStatus changes
      setInputValues((prevValues) => ({
        ...prevValues,
        creationDate: { ...prevValues.creationDate, value: new Date().toISOString().slice(0, 10) },
      }));
    }
  }, [inputValues.creationFeeStatus.value]);

  useEffect(() => {
    if (inputValues.refillPeriod.value !== '') {
      const refillPeriodDays = parseInt(inputValues.refillPeriod.value, 10);
      if (!isNaN(refillPeriodDays)) {
        const today = new Date();
        const refillDate = new Date(today);
        refillDate.setDate(today.getDate() + refillPeriodDays);
  
        setInputValues((prevValues) => ({
          ...prevValues,
          refillDate: { ...prevValues.refillDate, value: refillDate.toISOString() },
        }));
      }
    }
  }, [inputValues.refillPeriod.value]);
  
  
  // Preparing Company and User Lists
    // reading user Data
    const user = JSON.parse(localStorage.getItem('user'));
    // options for Company
    const [comlist, setComlist] = useState([]);
    const [companyId, setCompanyId] = useState(null);
    const [ dsmCompany, setDSMCompany ] = useState([]);
    const [userlist, setUserlist] = useState([]);
    const [userOptionsList, setUserOptionsList] = useState([]);
    const [creatorId, setCreatorId ] = useState(null);
    const [ dsmCreator, setDSMCreator ] = useState([]);
  
    const getCompanies = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/admin/comlist/`);
        //console.log("Companies:   " + JSON.stringify(response.data));
        setComlist(response.data);
      } catch (error) {
        console.error(`Error retrieving companies: ${error}`);
      }
    };
    
    useEffect(() => {
      getCompanies();
    }, []);
    
    const Company = comlist.map(com => ({
      value: com.id,
      label: com.cname
    }));


    const sidePackInfo = [
      {head: 'Package Comapny', value: 'CHECK', type: 'com', options: Company,
        selectedItem: companyId,
        setSelectedItem: setCompanyId},
      {head: 'Package Creator', value: 'CHECK', type: 'user', options: userOptionsList,
        selectedItem: creatorId,
        setSelectedItem: setCreatorId},
   ];

    useEffect(() => {
      if (companyId) {
        // Update user list
        const newUserlist = user.company_users
          .filter(user => user.company === companyId.value);
        const userOptions = newUserlist.map(user => ({
          value: user.id,
          label: user.fullname
        }));
        setUserOptionsList(userOptions);
        setUserlist(newUserlist);
        // Additional logic for setting pay record from company list
        const company = comlist.find(com => com.id === companyId.value);
        setDSMCompany(company);
      } else {
        setUserlist([]);
        setDSMCompany(null);
      }
    }, [companyId]);

    useEffect(() => {
      if (companyId) {
        // Additional logic for setting pay record from company list
        const company = comlist.find(com => com.id === companyId.value);
        setDSMCompany(company);
      } else {
        setUserlist([]);
        setDSMCompany(null);
        setDSMCreator(null);
        setCreatorId(null);
      }
    }, [companyId]);


    useEffect(() => {
      if (creatorId) {
        // Additional logic for setting pay record from company list
        const user = userlist.find(user =>  user.id === creatorId.value);
        setDSMCreator(user);
      } else {
        setDSMCreator(null);
        setCreatorId(null);
      }
    }, [creatorId]);
  


  // First time Upload Doc. Data
  const [successMessages, setSuccessMessages] = useState([]);

  // Prepare and post package record to the server
  const createProductsArray = () => {
    const products = Object.keys(cardInputValues).map(productId => {
      const { lower_spec_stock, upper_spec_stock, product_status, product_status_date, productRecord } = cardInputValues[productId];
  
      return {
         product: Number(productRecord.id),
         lower_spec_stock: Number(lower_spec_stock),
         upper_spec_stock: Number(upper_spec_stock),
         product_status: product_status,
         product_active_date: product_status === 'active' ? product_status_date : null,
         product_non_active_date: product_status === 'non_active' ? product_status_date : null,
         product_paused_date: product_status === 'paused' ? product_status_date : null,
      };
    });
  
    return products;
  };


  const [ alertMessages, setAlertMessages ] = useState([]);
  const validateInputValues = (inputValues) => {
    const messages = [];
  
    for (const key in inputValues) {
      if (inputValues.hasOwnProperty(key)) {
        const { value, disabled } = inputValues[key];
        if (key === 'numOfProducts' && value < 2) {
          messages.push('You need to add at least 2 products to the package!');
        } else if (!disabled && ['', 0, 0.00, null, undefined].includes(value) && key !== 'refillProposalStatus') {
          messages.push(`The ${key} is missing!`);
        }
      }
    }
  
    return messages;
  };


  const [ dsmRecord, setDSMRecord ] = useState([]);
  const saveChanges = async () => {
    console.log('Click Save...');
      let newAlertMessages = [];
      const productsList = createProductsArray();
      newAlertMessages = validateInputValues(inputValues);
      
      if (dsmCreator === null || dsmCompany === null) {
        if (dsmCreator === null && dsmCompany !== null) {
            newAlertMessages = [...newAlertMessages, 'Select a Package creator!'];
        } else if (dsmCompany === null && dsmCreator !== null ) {
            newAlertMessages = [...newAlertMessages, 'Select a Package company!'];
        } else {
          newAlertMessages = [...newAlertMessages, 'Select a Package company and creator!'];
        }
      }
      
      if (newAlertMessages.length > 0) {
        setAlertMessages(newAlertMessages);
        return;
      } else {
        setAlertMessages([]);
      }

      const dsmData = {
        company: dsmCreator?.company || '',
        dsm_creator: dsmCreator?.id || '',
        dsm_name: inputValues.dsmName.value || '',
        department: inputValues.department.value || '',
        num_of_products: inputValues.numOfProducts.value || (productsList.length),
        creation_fee: parseFloat(inputValues.creationFee.value) || getCreationFee(productsList.length),
        creation_date: new Date().toISOString(),
        creation_fee_status: inputValues.creationFeeStatus.value || 'created',
        total_price_of_proposal: null,
        refill_period: inputValues.refillPeriod.value || null,
        refill_proposal_status: inputValues.refillProposalStatus.value || null,
        refill_proposal_date: inputValues.refillDate.value || null,
        dsm_status: inputValues.dsmStatus.value || 'active',
        dsm_active_date: (inputValues.dsmStatus === 'active' || inputValues.dsmStatus === '') ? new Date().toISOString() : null,
        paused_date: inputValues.dsmStatus === 'paused' ? new Date().toISOString() : null,
        non_active_date: inputValues.dsmStatus === 'non_active' ? new Date().toISOString() : null,
      monthly_fee_dsm: parseFloat(inputValues.monthlyDSMFee.value) || getCreationFee(productsList.length),
      products: productsList,
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/products/dsm/create/`,
            dsmData
        );

        if (response.status === 200 || response.status === 201) {
            const packID = response.data.id;
            console.log("Package Created saved successfully!");
            setAlertMessages([]);
            setSuccessMessages(['DSM Created saved successfully!']);
            setDSMRecord(response.data);
            // setSelectedProduct(null);
            // setShowHiddenDiv(false);
            // setCardInputValues([]);
        } else {
          setSuccessMessages([]);
          console.error("Error saving changes: ", response);
          setAlertMessages(['There is another DSM with this name!']);
        }

      } catch (error) {
        setSuccessMessages([]);
        console.error("Error saving changes: ", error);
        if (error.response.data.dsm_name) {
          setAlertMessages(['There is another DSM with this name!']);
        } else {
          setAlertMessages(['Error saving changes, Please Try again Later!']);
        }
      }

      // console.log("Saving changes:");
      // console.log(inputValues);
      // //console.log(alertMessages);
      // console.log(dsmData);
      // //console.log(cardInputValues);
      // console.log(productsList);
      // // setCards([]);
  };

  return (
    <div className="w-full mb-8">
      <div className="w-full flex flex-row gap-4 mt-8">
        <div className="flex-1 w-[50%] bg-white shadow-sm border-1 border-gray-300">
          <table className="w-full p-4">
            <tbody className="font-semibold">
              {Object.keys(inputValues).map((key, index) => (
                <tr className="border-b border-gray-200" key={index}>
                  { key === 'monthlyDSMFee' ? (
                      <td className="p-3 text-left w-1/2">Monthly DSM Fee</td>
                  ) : (
                    <td className="p-3 text-left w-1/2">{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</td>
                  )}
                  <td className="p-3 text-right w-1/2 h-full font-bold">
                    { key === 'dsmStatus' ? (
                      <select
                        value={inputValues[key].value}
                        onChange={(e) => handleInputChange(e, key)}
                        className="w-full text-right text-sm text-gray-600"
                        disabled={inputValues[key].disabled}
                      >
                        <option value="">Select Package Status</option>
                        <option value="active">Active</option>
                        <option value="paused">Paused</option>
                        <option value="non_active">Non Active</option>
                      </select>
                    ) : key === 'creationFeeStatus' ? (
                     <select
                       value={inputValues[key].value}
                       onChange={(e) => handleInputChange(e, key)}
                       className="w-full text-right text-sm text-gray-600"
                       disabled={inputValues[key].disabled}
                     >
                       <option value="">Select Creation Fee Status</option>
                       <option value="created">Created</option>
                       <option value="invoiced">Invoiced</option>
                       <option value="paid">Paid</option>
                     </select>
                   ) : key === 'refillProposalStatus' ? (
                      <select
                        value={inputValues[key].value}
                        onChange={(e) => handleInputChange(e, key)}
                        className="w-full text-right text-sm text-gray-600"
                        disabled={inputValues[key].disabled}
                      >
                        <option value="">Select Refill Proposal Status</option>
                        <option value="pending">Pending</option>
                        <option value="accepted">Accepted</option>
                        <option value="rejected">Rejected</option>
                      </select>
                    ) : (
                      <input
                        type="text"
                        value={inputValues[key].value}
                        onChange={(e) => handleInputChange(e, key)}
                        className={`w-full h-[30px] p-1 text-right border-1 border-custom-green rounded text-sm ${inputValues[key].disabled ? 'bg-gray-100 text-gray-400' : 'text-gray-600'}`}
                        disabled={inputValues[key].disabled}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/** Products and Pack Info. */}
        <div className="flex-1 w-[50%] flex flex-col items-center">
          <div className="w-[100%] flex flex-col items-center">
            <div className="flex overflow-hidden w-full" ref={galleryRef}>
              {cards.map((item, index) => (
                <div className="min-w-full text-center items-center relative" key={index}>
                  {/* Delete Button */}
                  <button 
                    className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded"
                    onClick={() => handleDelete(index)}
                  >
                    Delete
                  </button>
                  {/* Render your item here */}
                  <img className="w-48 h-48 object-cover mx-auto" src={item.imgSrc || ''} alt={item.product.name || ''} />
                  <p className="mt-4 text-custom-blue font-semibold text-center">
                    {item.product.name}
                  </p>
                  <table className="w-full p-4 mt-4 shadow-sm border-1 border-gray-300">
                    <tbody className="font-semibold">
                    <tr className="border-b border-gray-200">
                        <td className="p-3 text-left w-1/2">Lower Stock Limit</td>
                        <td className="p-3 text-right w-1/2 h-[30px] font-bold">
                          <input
                            type="number"
                            min="1"
                            value={cardInputValues[index]?.lower_spec_stock || ''}
                            placeholder={item.item.lower_spec_stock}
                            onChange={(e) => handleCardInputChange(e, index, 'lower_spec_stock')}
                            className="w-full h-[30px] p-1 text-right border-1 border-custom-green rounded text-sm text-gray-600"
                          />
                        </td>
                      </tr>
                      <tr className="border-b border-gray-200">
                        <td className="p-3 text-left w-1/2">Upper Stock Limit</td>
                        <td className="p-3 text-right w-1/2 h-[30px] font-bold">
                          <input
                            type="number"
                            min="1"
                            value={cardInputValues[index]?.upper_spec_stock || ''}
                            placeholder={item.item.upper_spec_stock}
                            onChange={(e) => handleCardInputChange(e, index, 'upper_spec_stock')}
                            className="w-full h-[30px] p-1 text-right border-1 border-custom-green rounded text-sm text-gray-600"
                          />
                        </td>
                      </tr>
                      <tr className="border-b border-gray-200">
                        <td className="p-3 text-left w-1/2">Product Status</td>
                        <td className="p-3 text-right w-1/2 font-bold">
                          <select
                            value={cardInputValues[selectedProduct.value.id]?.product_status || ''}
                            onChange={(e) => handleCardInputChange(e, index, 'product_status')}
                            className="w-full text-right text-sm text-gray-600"
                          >
                            <option value="">Select Product Status</option>
                            <option value="active">Active</option>
                            <option value="paused">Paused</option>
                            <option value="non_active">Non Active</option>
                          </select>
                        </td>
                      </tr>
                      <tr className="border-b border-gray-200">
                        <td className="p-3 text-left w-1/2">Product Status Date</td>
                        <td className="p-3 text-right w-1/2 h-[30px] font-bold">
                          <input
                            type="text"
                            value={cardInputValues[index]?.product_status_date || ''}
                            placeholder={item.item.product_status_date}
                            disabled = {true}
                            className="w-full h-[30px] p-1 text-right border-1 border-custom-green rounded text-sm text-gray-400"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
            </div>

            <div className="flex justify-center">
              <button className="bg-white border-none cursor-pointer text-custom-blue text-2xl p-2 mx-2"
                onClick={scrollLeft}>
                <FaArrowAltCircleLeft />
              </button>
              <button className="bg-white border-none cursor-pointer text-custom-blue text-2xl p-2 mx-2"
                onClick={scrollRight}>
                <FaArrowAltCircleRight />
              </button>
            </div>
          </div>


         {/** Add new Products */}
         {/* Checkbox to toggle hidden div */}
         <div className="w-[100%] mt-2 bg-white shadow-sm border-1 border-gray-300 p-2">
            <label className="flex items-center gap-2 ml-2 underline text-lg text-custom-blue font-bold">
              Add new Products
            </label>
          </div>

          {/* Hidden div */}
            <div className="w-[100%] flex flex-col mt-2 bg-white shadow-sm border-1 border-gray-300 p-4">
               <span className="text-gray-800 font-bold">Select a Product:</span>
               <Select options={productCards} className="mt-4 font-semibold text-gray-600"
                        onChange={handleSelectChange}/>
               
              {selectedProduct && (
              <div className="flex overflow-hidden w-full mt-4">
                <div className="min-w-full text-center items-center relative">
                  {/* Render your item here */}
                  <button 
                          className="absolute top-0 right-0 border-1 rounded cursor-pointer text-white text-sm bg-custom-blue p-2 mx-2"
                           onClick={() =>
                            addNewProduct(
                              selectedProduct.value,
                              cardInputValues[selectedProduct.value.id]?.lower_spec_stock || '',
                              cardInputValues[selectedProduct.value.id]?.upper_spec_stock || '',
                              cardInputValues[selectedProduct.value.id]?.product_status || ''
                            )
                          }>
                           ADD
                     </button>
                  <img className="w-48 h-48 object-cover mx-auto"  src={imgPaths[selectedProduct.value.assortimed_pid][0]} alt={selectedProduct.label} />
                  <p className="mt-4 text-custom-blue font-bold text-center">
                    {selectedProduct.label}
                  </p>
                  <div className="flex justify-center items-center w-full gap-4 text-custom-blue">
                     <p className="mt-4 font-semibold text-center">
                        {selectedProduct.value.category}
                     </p>
                     <p className="mt-4 font-semibold text-center">
                        {selectedProduct.value.brand}
                     </p>
                     <p className="mt-4 font-semibold text-center">
                        € {selectedProduct.value.sales_price_c1}
                     </p>
                  </div>
                  <table className="w-full p-4 mt-4 shadow-sm border-1 border-gray-300">
                    <tbody className="font-semibold">
                    <tr className="border-b border-gray-200">
                        <td className="p-3 text-left w-1/2">Quantity per Product</td>
                        <td className="p-3 text-right w-1/2 h-[30px] font-bold">
                          <input
                            type="number"
                            min="1"
                            value={cardInputValues[selectedProduct.value.id]?.lower_spec_stock || ''}
                            onChange={(e) => handleNewCardInputChange(e, selectedProduct.value.id, 'lower_spec_stock')}
                            className="w-full h-[30px] p-1 text-right border-1 border-custom-green rounded text-sm text-gray-600"
                          />
                        </td>
                      </tr>
                      <tr className="border-b border-gray-200">
                      <td className="p-3 text-left w-1/2">Quantity per Product</td>
                        <td className="p-3 text-right w-1/2 h-[30px] font-bold">
                          <input
                            type="number"
                            min="1"
                            value={cardInputValues[selectedProduct.value.id]?.upper_spec_stock || ''}
                            onChange={(e) => handleNewCardInputChange(e, selectedProduct.value.id, 'upper_spec_stock')}
                            className="w-full h-[30px] p-1 text-right border-1 border-custom-green rounded text-sm text-gray-600"
                          />
                        </td>
                      </tr>
                      <tr className="border-b border-gray-200">
                        <td className="p-3 text-left w-1/2">Product Status</td>
                        <td className="p-3 text-right w-1/2 font-bold">
                          <select
                            value={cardInputValues[selectedProduct.value.id]?.product_status || ''}
                            onChange={(e) => handleNewCardInputChange(e, selectedProduct.value.id, 'product_status')}
                            className="w-full text-right text-sm text-gray-600"
                          >
                            <option value="">Select Product Status</option>
                            <option value="active">Active</option>
                            <option value="paused">Paused</option>
                            <option value="non_active">Non Active</option>
                          </select>
                        </td>
                      </tr>
                      <tr className="border-b border-gray-200">
                      <td className="p-3 text-left w-1/2">Quantity per Product</td>
                        <td className="p-3 text-right w-1/2 h-[30px] font-bold">
                          <input
                            type="text"
                            disabled = { true }
                            value={new Date().toISOString().slice(0, 10)}
                            className="w-full h-[30px] p-1 text-right border-1 border-custom-green rounded text-sm text-gray-400"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            </div>


          <div className="w-[100%] mt-2 bg-white shadow-sm border-1 border-gray-300">
            <table className="w-full p-4">
              <tbody className="font-bold">
                {sidePackInfo.map((info, index) => (
                  <tr key={index}>
                    <td className="p-3 text-left w-2/5">{info.head}</td>
                    <td className="w-2/5">
                        <Select options={info.options}
                                value={info.selectedItem}
                                onChange={info.setSelectedItem} />
                    </td>
                    <td className="px-3 py-2 text-center w-1/5 font-bold">
                      <button
                        type="button"
                        className="p-2 border-1 border-custom-blue text-custom-blue rounded hover:bg-custom-blue hover:text-white text-sm"
                        onClick={() => handleButtonClick(info.type)}>
                        {info.value}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Modal
                open={popupVisible}
                onClose={() => setPopupVisible(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box sx={modelStyle}>
                    {popupType === 'user' ? (
                        <UserDetails userRecord={dsmCreator || null} onClose={onClose} />
                      ) : 
                      popupType === 'com' ? (
                        <CompanyDetails comRecord={dsmCompany || null} onClose={onClose} />
                      ) : null}
                  </Box>
              </Modal>
          </div>
        </div>
      </div>

      {/** Save Buttons Section */}
      <div className="w-full items-center flex flex-col justify-center mt-10">
        {(alertMessages.length > 0 ) && (
          <div className="flex text-left flex-col gap-1 mb-10">
            {alertMessages.map((message, index) => (
              <p key={index} className="text-red-500 text-sm font-bold">{message}</p>
            ))}
          </div>
        )}
        {(successMessages.length > 0 ) && (
          <div className="flex text-left flex-col gap-1 mb-10">
            {successMessages.map((message, index) => (
                  <p key={index} className="text-teal-500 text-sm font-bold">{message}</p>
                ))}
          </div>
          )}  
          <div className="flex items-start justify-center mb-4 gap-2"> 
                <button  className=" w-full rounded-md text-red-500 border-2 px-6 py-2 font-semibold hover:bg-red-500 hover:text-white sm:w-auto flex flex-row gap-2 justify-center"
                    onClick={cancelChanges}>
                        <FcCancel className="text-xl"/>
                        <span>Cancel</span>
                  </button>
                <button  
                    className=" w-full rounded-md hover:text-custom-blue text-slate-50 border-2 px-6 py-2 font-semibold bg-custom-blue  hover:bg-white sm:w-auto flex flex-row gap-2 justify-center"
                    onClick={saveChanges}>
                        <FaSave className="text-xl"/>
                        <span>Create DSM</span>
                  </button>
          </div>
      </div>
    </div>
  );
}