import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";


// Icon Imports
import { MdFormatListBulleted } from "react-icons/md";
import { MdFormatListBulletedAdd } from "react-icons/md";
import { MdOutlinePlaylistPlay } from "react-icons/md";
import { FcCancel } from "react-icons/fc";
import { FaEdit, FaSave } from "react-icons/fa";


// Local Imports
import DisplayDSM from "./displayDSM";
import EditDSM from "./editDSM";
import NewDSM from "./newDSM";




export default function DsmItem() {
    // Get all DSMs
    const [dsms, SetDsms] = useState([]);
    useEffect(() => {
        const getDsms = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/products/dsm/all/`);
        
                if (response.status === 200 || response.status === 201) {
                    console.log('dsmItems get all');
                    SetDsms(response.data);
                } else {
                    console.log("Error getting dsms status: ", response.status);
                }
            } catch (error) {
                console.log("Error getting dsms status: ", error);
            }
        }

        getDsms();
    }, []);


    // 3 Tabs for DSMs
    const [dsmId, setDsmId] = useState(null);
    const [dsmRecord, setDsmRecord] = useState(null);
    const dsmOptions = dsms.map(dsm => ({
        value: dsm.id,
        label: dsm.dsm_name || dsm.daddress,
    }));
    useEffect(() => {
        if (dsmId && dsms) {
            const selectedDsm = dsms.find(dsm => dsm.id === dsmId.value);
            if (selectedDsm) { 
                setDsmRecord(selectedDsm);
            }
        }
    }, [dsmId]);
    // console.log("Companies List Options:   " + JSON.stringify(dsmRecord));


    // Navigate Different Company Choices
    const [display, setDisplay] = useState(true);
    const [edit, setEdit] = useState(false);
    const [newDsm, setNewDsm] = useState(false);

    const displayInfo = () => {
        setDisplay(true);
        setEdit(false);
        setNewDsm(false);
    };
    const editInfo = () => {
        setEdit(true);
        setDisplay(false);
        setNewDsm(false);
    };
    const newInfo = () => {
        setNewDsm(true);
        setDisplay(false);
        setEdit(false);
    };

    const buttons = [
        {id: 1,icon: <MdFormatListBulleted className="text-lg mt-1" />, label: "Display DSM", func: displayInfo, con: display},
        {id: 2,icon: <MdOutlinePlaylistPlay className="text-lg mt-1" />, label: "Edit DSM", func: editInfo, con: edit},
        {id: 3,icon: <MdFormatListBulletedAdd className="text-lg mt-1" />, label: "New DSM", func: newInfo, con: newDsm},
    ];

    // Edit Action Buttons
    const saveChanges = () => {
        console.log("Save Changes");
    };

    const cancelChanges = () => {
        setDisplay(false);
        setEdit(false);
        setNewDsm(false);
        setDsmRecord(null);
        setDsmId(null);
    };

    const [resultFromChild, setResultFromChild] = useState(null);
    const handleDataReturn = (data) => {
        setResultFromChild(data);
    };


  return (
    <div className="flex flex-col justify-center items-center">

            <div className="flex flex-row gap-4 justify-center w-[70%] mb-4">
                {buttons.map((button) => (
                <div key={button.id} className="flex items-end md:mb-0 md:w-auto">
                    <button onClick={button.func} 
                            className={`w-full rounded-md text-custom-blue border-2
                                        px-10 py-2 font-semibold ${button.con ? 'bg-custom-blue text-white' : 'hover:bg-custom-blue hover:text-white'}
                                        sm:w-auto flex flex-row gap-2 justify-center`}>
                    {button.icon}
                    <span className="text-lg">{button.label}</span>
                    </button>
                </div>
                ))}
            </div>

            
            { display || edit || newDsm ?
            <div className="w-[90%] border-1 border-custom-blue p-4 rounded-lg">
                { display || edit ?
                <div className="flex flex-row justify-center w-[90%]">
                    <div className="flex flex-row justify-center w-[70%]">
                        <label className="text-lg uppercase font-sans font-bold text-gray-700 mt-1">DSM</label>
                        <div className="w-[50%] ml-8 text-gray-600 font-bold mb-4">
                            <Select options={dsmOptions}
                                    value={dsmId}
                                    onChange={setDsmId}/>
                        </div>
                    </div>
                </div> : null}


                {display?
                    <DisplayDSM dsmRecord={dsmRecord} onDataReturn={handleDataReturn} />
                : null}


                {edit?
                    <EditDSM dsmRecord={dsmRecord} onDataReturn={handleDataReturn} />
                : null}

                {newDsm?
                    <NewDSM dsmRecord={dsmRecord} onDataReturn={handleDataReturn} />
                : null}


                {/* { edit || newDsm ?
                <div className="flex items-start justify-center mb-4 gap-2"> 
                        <button  className=" w-full rounded-md text-red-500 border-2 px-6 py-2 font-semibold hover:bg-red-500 hover:text-white sm:w-auto flex flex-row gap-2 justify-center"
                                onClick={cancelChanges}>
                                <FcCancel className="text-xl"/>
                                <span>Cancel Changes</span>
                        </button>
                        <button  
                                className=" w-full rounded-md hover:text-custom-blue text-slate-50 border-2 px-6 py-2 font-semibold bg-custom-blue  hover:bg-white sm:w-auto flex flex-row gap-2 justify-center">
                                <FaSave className="text-xl"/>
                                <span>Save Changes</span>
                        </button>
                </div> : null} */}

                { display ?
                <div className="flex items-start justify-center mb-4 gap-2"> 
                        <button  className=" w-full rounded-md text-red-500 border-2 px-12 py-2 font-semibold hover:bg-red-500 hover:text-white sm:w-auto flex flex-row gap-2 justify-center"
                                onClick={cancelChanges}>
                                <FcCancel className="text-xl"/>
                                <span>Cancel</span>
                        </button>
                        <button  className=" w-full rounded-md hover:text-custom-blue text-slate-50 border-2 px-6 py-2 font-semibold bg-custom-blue  hover:bg-white sm:w-auto flex flex-row gap-2 justify-center"
                                onClick={editInfo}>
                                <FaEdit className="text-xl"/>
                                <span>Edit DSM</span>
                        </button>
                </div> : null}
            </div> : null}
        </div>
  );
}