import React from 'react';

// Icons Imports
import { MdApartment } from 'react-icons/md';
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia';
import { IoMdPricetags } from 'react-icons/io';
import { BsCalendarDateFill } from "react-icons/bs";
import { MdLanguage } from "react-icons/md";
import { FaSuitcase } from "react-icons/fa";
import { FaEuroSign } from "react-icons/fa";




const InfoPer = () => {
  // Data from Local Storage
  const user = JSON.parse(localStorage.getItem('user'));
  const userRole = user.user_data.role === 'HD' ? 'Head User' : user.role === 'M' ? 'Manager User' : 'Normal User';
  //console.log(user);

  const rows = [
    {
      icon: <LiaFileInvoiceDollarSolid />,
      label: "Email",
      info: user.user_data.email,
    },
    {
      icon: <IoMdPricetags />,
      label: "Mobile Number",
      info: user.user_data.uphone,
    },
    {
      icon: <BsCalendarDateFill />,
      label: "Date of Birth",
      info: user.user_data.date_of_birth,
    },
    {
      icon: <MdApartment />,
      label: "Department",
      info: user.user_data.department_name,
    },
    {
      icon: <MdApartment />,
      label: "Department code",
      info: user.user_data.department_code,
    },
    {
      icon: <FaSuitcase />,
      label: "Job Title",
      info: user.user_data.function,
    },
    {
      icon: <MdLanguage />,
      label: "Language Preference",
      info: user.user_data.language_preference,
    },
    {
      icon: <FaEuroSign />,
      label: "Assigned Budget",
      info: `${user.user_data.budget} €`,
    },
  ];

  return (
    <table className="table-auto w-full">
      <tbody>
        {rows.map((row) => (
          <tr key={row} className='h-14'>
            <td className="w-1/4 px-20 py-2 text-left bg-teal-100-accent"
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <div className="flex items-center">
              <span className='text-base font-bold text-custom-green'>
                {row.icon}
                </span>
              
              <span className="ml-3 font-semibold"
                    style={{fontSize: "16px"}}>
                {row.label}
                </span>
            </div>
          </td>
            <td className="border-b px-20 py-2 text-right">
            <span className="ml-2 font-semibold"
                  style={{fontSize: "16px"}}>
              {row.info}
              </span>
              </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default InfoPer;