import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const generatePDF = async (element) => {
  const scale = 4; // Increase the scale to improve quality
  const canvas = await html2canvas(element, {
    scale: scale,
    useCORS: true,
    width: element.scrollWidth,
    height: element.scrollHeight
  });

  const imgData = canvas.toDataURL('image/png');
  const pdf = new jsPDF({
    orientation: 'landscape',
    unit: 'px',
    format: [canvas.width / scale, canvas.height / scale]
  });

  pdf.addImage(imgData, 'PNG', 0, 0, canvas.width / scale, canvas.height / scale);
  return pdf.output('blob');
};

export default generatePDF;