import React from "react";
import { FaWindowClose } from "react-icons/fa";



export default function CompanyDetails({comRecord, onClose})  {
    
    const companyData = [
        {head: "company name", value: comRecord?.cname || 'NA'},
        {head: "COD", value: comRecord?.cod_number || 'NA'},
        {head: "Contract start date", value: comRecord?.contract_start_date || 'NA'},
        {head: "Contract end date", value: comRecord?.contract_end_date || 'NA'},
        {head: "VAT", value: comRecord?.vat || 'NA'},
        {head: "Address", value: comRecord?.caddress || 'NA'},
        {head: "Postal code", value: comRecord?.cpostalcode || 'NA'},
        {head: "City", value: comRecord?.ccity || 'NA'},
        {head: "Country", value: comRecord?.ccountry || 'NA'},
        {head: "Main Office", value: comRecord?.mainoffice || 'NA'},
    ];

    const handleClose = () => {
        onClose();
    };

    return (
        <div name="userdata">
            <div className="w-full flex justify-center items-center">
                <button
                    className="absolute top-4 right-2 text-red-500 rounded-full p-1"
                    onClick={handleClose}
                >
                    <FaWindowClose className="text-4xl"/>
                </button>
            </div>
            <div className="border-1 border-gray-400 w-full h-full mt-10">
                <table className="w-full">
                    <tbody>
                    {companyData.map((info, index) => (
                     <tr className="border-b border-gray-200">
                        <td className="p-3 text-left w-1/2">{info.head}</td>
                        <td className="p-3 text-right w-1/2 font-bold">{info.value}</td>
                     </tr>
                     ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}