import React, { useRef, useState, useEffect } from "react";
import { Modal, Box } from '@mui/material';
import Select from 'react-select';
import axios from "axios";

// Icon Imports 
import { FaArrowAltCircleLeft, FaArrowAltCircleRight, FaPlusCircle, FaSave } from "react-icons/fa";


// Local Imports
import './Gallery.css';
import UserDetails from "../summary/UserSum";
import CompanyDetails from "../summary/ComSum";
import { FcCancel } from "react-icons/fc";




export default function EditDSM({ dsmRecord, onDataReturn }) {
  const storedProducts = JSON.parse(localStorage.getItem('products'));
  const imgPaths = JSON.parse(localStorage.getItem('imgPaths'));
  const packItems = dsmRecord?.products || [];

  const productCards = storedProducts.map((product, index) => ({
     value: product || [],
     label: product.name || '',
  }));

  const getProductById = (id) => {
    const product = storedProducts.find(product => product.id === id);
    return product;
  }

  const getProductNameById = (id) => {
    const product = getProductById(id);
    return product.product_name;
  }

  const getProductPIDById = (id) => {
    const product = getProductById(id);
    return product.assortimed_pid;
  }

  const [cards, setCards] = useState([]);

  useEffect(() => {
    setCards(packItems.map((product, index) => ({
      item: product,
      product: getProductById(product.product),
      imgSrc: imgPaths[getProductPIDById(product.product)][0]
    })));
  }, [dsmRecord]);

  const galleryRef = useRef(null);

  const scrollLeft = () => {
    galleryRef.current.scrollBy({ left: -200, behavior: 'smooth' });
  };

  const scrollRight = () => {
    galleryRef.current.scrollBy({ left: 200, behavior: 'smooth' });
  };

  // Info Popoups
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupType, setPopupType] = useState(null);

  const handleButtonClick = (type) => {
      setPopupType(type);
      setPopupVisible(true); 
  };

  const sidePackInfo = [
    {head: 'DSM Creator', value: dsmRecord?.dsm_creator?.fullname, type: 'user'},
    {head: 'DSM Comapny', value: dsmRecord?.company?.cname, type: 'com'},
 ];


  const modelStyle = {
    position: 'absolute',
    top: '25vh',
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: '50%',
    maxHeight: '50vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
    rounded: 'full',
  };

  const onClose = () => {
    setPopupVisible(false);
    setPopupType(null);
  }

  const [inputValues, setInputValues] = useState({
    id: { value: '', disabled: true },
    department: { value: '', disabled: false },
    dsmStatus: { value: '', disabled: false },
    dsmStatusDate: { value: '', disabled: true },
    numOfProducts: { value: '', disabled: true }, // Caculated on Site
    creationFee: { value: '', disabled: true },
    creationFeeStatus: { value: '', disabled: false },
    creationDate: { value: '', disabled: true },
    refillPeriod: { value: '', disabled: false },
    refillDate: { value: '', disabled: true }, // Caculated on Site
    refillProposalStatus: { value: '', disabled: false },
    monthlyDSMFee: { value: '', disabled: true }, // Caculated on Site
  });

  useEffect(() => {
    setInputValues({
      id: { value: dsmRecord?.id || '', disabled: true },
      department: { value: dsmRecord?.department || '', disabled: false },
      dsmStatus: {
        value: dsmRecord?.dsm_status
          ? dsmRecord.dsm_status
          : '',
        disabled: false,
      },
      dsmStatusDate: {
        value: dsmRecord?.dsm_status
          ? (dsmRecord.dsm_status === 'active'
            ? dsmRecord.dsm_active_date
            : (dsmRecord.dsm_status === 'paused'
              ? dsmRecord.dsm_paused_date
              : dsmRecord.dsm_non_active_date)
          )?.slice(0, 10)
          : '',
        disabled: true,
      },
      numOfProducts: { value: dsmRecord?.num_of_products ? dsmRecord.num_of_products + ' products' : '', disabled: true },
      creationFee: { value: dsmRecord?.creation_fee ? '€ ' + dsmRecord.creation_fee : '', disabled: true },
      creationFeeStatus: { value: dsmRecord?.creation_fee_status || '', disabled: false },
      creationDate: { value: dsmRecord?.creation_date?.slice(0, 10) || '', disabled: true },
      refillPeriod: { value: dsmRecord?.refill_period || '', disabled: false },
      refillDate: { value: dsmRecord?.refill_proposal_date?.slice(0, 10) || '', disabled: true },
      refillProposalStatus: { value: dsmRecord?.refill_proposal_status || '', disabled: false },
      monthlyDSMFee: { value: dsmRecord?.monthly_fee_dsm ? '€ ' + dsmRecord.monthly_fee_dsm : '', disabled: true },
    });
  }, [dsmRecord]);

  const handleInputChange = (e, key) => {
    setInputValues({
      ...inputValues,
      [key]: {
        ...inputValues[key],
        value: e.target.value,
      },
    });
  };

  const [cardInputValues, setCardInputValues] = useState([]);

  useEffect(() => {
    setCardInputValues(
      dsmRecord?.products?.map(product => ({
        lower_spec_stock: product.lower_spec_stock ? product.lower_spec_stock : '',
        upper_spec_stock: product.upper_spec_stock ? product.upper_spec_stock : '',
        product_status: product.product_status ? product.product_status : '',
        product_status_date: product?.product_status
        ? (product.product_status === 'active'
          ? product.product_active_date
          : (product.product_status === 'paused'
            ? product.product_paused_date
            : product.product_non_active_date))?.slice(0, 10)
            : '',
        productRecord: getProductById(product.product),
      })) || []
    );
  }, [dsmRecord]);

  const handleCardInputChange = (e, index, key) => {
    const newInputValues = [...cardInputValues];
    newInputValues[index][key] = e.target.value;
    setCardInputValues(newInputValues);
  };

  const handleDelete = (index) => {
    if (typeof cardInputValues === 'object' && cardInputValues !== null) {
      const keys = Object.keys(cardInputValues);
      const keyToDelete = keys[index];
  
      if (keyToDelete !== undefined) {
        const { [keyToDelete]: _, ...newCardInputValues } = cardInputValues;
        const newCards = cards.filter((_, i) => i !== index);
  
        setCardInputValues(newCardInputValues);
        setCards(newCards);
      } else {
        console.error("Invalid index for cardInputValues");
      }
    } else {
      console.error("cardInputValues is not an object");
    }
  };


  // Add products to package section
  const [showHiddenDiv, setShowHiddenDiv] = useState(false);

  const handleCheckboxChange = (e) => {
    setShowHiddenDiv(e.target.checked);
  };

  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleSelectChange = (selectedOption) => {
    setSelectedProduct(selectedOption);
  };

  const handleNewCardInputChange = (e, productId, key) => {
   setCardInputValues({
     ...cardInputValues,
     [productId]: {
       ...cardInputValues[productId],
       [key]: e.target.value,
     },
   });
 };

 
 const addNewProduct = (product, lowerStockLimit, upperStockLimit, productStatus) => {
  const newCardInputValues = {
    ...cardInputValues,
    [product.id]: {
      lower_spec_stock: Number(lowerStockLimit) || 100,
      upper_spec_stock: Number(upperStockLimit) || 1000,
      product_status: productStatus,
      product_status_date: new Date().toISOString().slice(0, 10),
      productRecord: product,
    },
  };

  setCardInputValues(newCardInputValues);

    const newCard = {
      item: {
        lower_spec_stock: Number(lowerStockLimit),
        upper_spec_stock: Number(upperStockLimit),
        product_status: productStatus,
        product_status_date: new Date().toISOString().slice(0, 10),
      },
      product: product,
      imgSrc: imgPaths[product.assortimed_pid][0],
    };

    setCards([...cards, newCard]);
  };


  const getCreationFee = (numOfItems) => {
    if (numOfItems === 0) {
      return 0;
    } else if (numOfItems > 0 && numOfItems <= 15) {
      return 250;
    } else if (numOfItems > 15 && numOfItems <= 25) {
      return 375;
    } else if (numOfItems > 25 && numOfItems <= 35) {
      return 450;
    } else {
      return 625;
    }
  };


  useEffect(() => {
    // Update docPrice when numOfProducts changes
    const numOfProducts = cards.length || 0;
    setInputValues((prevValues) => ({
      ...prevValues,
      numOfProducts: { ...prevValues.numOfProducts, value: numOfProducts },
    }));
  }, [cards]);

  useEffect(() => {
    // Update docPrice when creationFee changes
    const creationFee = getCreationFee(cards.length);
    setInputValues((prevValues) => ({
      ...prevValues,
      creationFee: { ...prevValues.creationFee, value: creationFee },
    }));
  }, [inputValues.numOfProducts.value]);


  useEffect(() => {
    // Update docPrice when monthlyDSMFee changes
    const monthlyDSMFee = getCreationFee(cards.length);
    setInputValues((prevValues) => ({
      ...prevValues,
      monthlyDSMFee: { ...prevValues.monthlyDSMFee, value: monthlyDSMFee },
    }));
  }, [inputValues.numOfProducts.value]);

  useEffect(() => {
    if (inputValues.dsmStatus.value !== '') {
      // Update docPrice when dsmStatus changes
      setInputValues((prevValues) => ({
        ...prevValues,
        dsmStatusDate: { ...prevValues.dsmStatusDate, value: new Date().toISOString().slice(0, 10) },
      }));
    }
  }, [inputValues.dsmStatus.value]);


  useEffect(() => {
    if (inputValues.creationFeeStatus.value !== '') {
      // Update docPrice when creationStatus changes
      setInputValues((prevValues) => ({
        ...prevValues,
        creationDate: { ...prevValues.creationDate, value: new Date().toISOString().slice(0, 10) },
      }));
    }
  }, [inputValues.creationFeeStatus.value]);

  useEffect(() => {
    if (inputValues.refillPeriod.value !== '') {
      const refillPeriodDays = parseInt(inputValues.refillPeriod.value, 10);
      if (!isNaN(refillPeriodDays)) {
        const today = new Date();
        const refillDate = new Date(today);
        refillDate.setDate(today.getDate() + refillPeriodDays);

        setInputValues((prevValues) => ({
          ...prevValues,
          refillDate: { ...prevValues.refillDate, value: refillDate.toISOString().slice(0, 10) },
        }));
      }
    }
  }, [inputValues.refillPeriod.value]);

  // ADD and Cancel Actions
  const cancelChanges = () => {
    onDataReturn();
  };

  
  // Saving DSM update by a PUT request
  const createProductsArray = () => {
    const products = Object.keys(cardInputValues).map(productId => {
      const { lower_spec_stock, upper_spec_stock, product_status, product_status_date, productRecord } = cardInputValues[productId];
  
      return {
        product: Number(productRecord.id),
        lower_spec_stock: Number(lower_spec_stock),
        upper_spec_stock: Number(upper_spec_stock),
        product_status: product_status,
        product_active_date: product_status === 'active' ? product_status_date : null,
        product_non_active_date: product_status === 'non_active' ? product_status_date : null,
        product_paused_date: product_status === 'paused' ? product_status_date : null,
      };
    });
  
    return products;
  };

  const [sucessMessage, setSuccessMessages] = useState(null);
  const [failureMessage, setFailureMessages] = useState(null);
  const saveChanges = async () => {
      const productsList = createProductsArray();

      const dsmData = {
        company: dsmRecord.dsm_creator.company,
        dsm_creator: dsmRecord.dsm_creator.id,
        department: inputValues.department.value || dsmRecord.department,
        dsm_status: inputValues.dsmStatus.value || '',
        num_of_products: Number(inputValues.numOfProducts.value) || Number(productsList.length) || dsmRecord.num_of_products,
        creation_fee_status: inputValues.creationFeeStatus.value || dsmRecord.creation_fee_status,
        refill_period: inputValues.refillPeriod.value || dsmRecord?.refill_period,
        refill_proposal_status: inputValues.refillProposalStatus.value || dsmRecord.refill_proposal_status,
        refill_proposal_date: inputValues.refillDate.value || dsmRecord.refill_proposal_date || null,
      monthly_fee_dsm: parseFloat((inputValues.totalPackPrice)) || dsmRecord.total_pack_price,
        products: productsList,
      }


      try {
        const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/products/dsm/adminupdate/${dsmRecord.id}/`,
            dsmData
        );

        if (response.status === 200 || response.status === 201) {
          console.log("Changes saved successfully!");
          setSuccessMessages('Changes saved successfully!');
          // setSelectedProduct(null);
          // setShowHiddenDiv(false);
          // setCardInputValues([]);
        } else {
          console.error("Error saving changes: ", response);
          setFailureMessages('Error saving changes, Please Try again Leter!');
        }
      } catch (error) {
        console.error("Error saving changes: ", error);
        setFailureMessages('Error saving changes, Please Try again Leter!');
      } 
  };

  return (
    <div className="w-full">
      <h1 className="text-center text-lg font-bold text-custom-blue">
        <span className="text-gray-500 mr-4">DSM Name:</span>
        {dsmRecord?.dsm_name || 'Choose a DSM!'}</h1>
      <div className="w-full flex flex-row gap-4 mt-8">
        <div className="flex-1 w-[50%] bg-white shadow-sm border-1 border-gray-300">
          <table className="w-full p-4">
            <tbody className="font-semibold">
              {Object.keys(inputValues).map((key, index) => (
                <tr className="border-b border-gray-200" key={index}>
                  { key === 'monthlyDSMFee' ? (
                      <td className="p-3 text-left w-1/2">Monthly DSM Fee</td>
                  ) : (
                    <td className="p-3 text-left w-1/2">{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</td>
                  )}
                  <td className="p-3 text-right w-1/2 h-full font-bold">
                    {key === 'dsmStatus' ? (
                      <select
                        value={inputValues[key].value}
                        onChange={(e) => handleInputChange(e, key)}
                        className="w-full text-right text-sm text-gray-600"
                        disabled={inputValues[key].disabled}
                      >
                        <option value="">Select Package Status</option>
                        <option value="active">Active</option>
                        <option value="paused">Paused</option>
                        <option value="non_active">Non Active</option>
                      </select>
                    ) : key === 'creationFeeStatus' ? (
                      <select
                        value={inputValues[key].value}
                        onChange={(e) => handleInputChange(e, key)}
                        className="w-full text-right text-sm text-gray-600"
                        disabled={inputValues[key].disabled}
                      >
                        <option value="">Select Creation Fee Status</option>
                        <option value="created">Created</option>
                        <option value="invoiced">Invoiced</option>
                        <option value="paid">Paid</option>
                      </select>
                    ) : key === 'refillProposalStatus' ? (
                      <select
                        value={inputValues[key].value}
                        onChange={(e) => handleInputChange(e, key)}
                        className="w-full text-right text-sm text-gray-600"
                        disabled={inputValues[key].disabled}
                      >
                        <option value="">Select Creation Fee Status</option>
                        <option value="pending">Pending</option>
                        <option value="accepted">Accepted</option>
                        <option value="rejected">Rejected</option>
                      </select>
                    ): (
                      <input
                        type="text"
                        value={inputValues[key].value}
                        onChange={(e) => handleInputChange(e, key)}
                        className={`w-full h-[30px] p-1 text-right border-1 border-custom-green rounded text-sm ${inputValues[key].disabled ? 'bg-gray-100 text-gray-400' : 'text-gray-600'}`}
                        disabled={inputValues[key].disabled}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/** Display and Edit existing Products Section */}
        <div className="flex-1 w-[50%] flex flex-col items-center">
          <div className="w-[100%] flex flex-col items-center">
            <div className="flex overflow-hidden w-full" ref={galleryRef}>
              {cards.map((item, index) => (
                <div className="min-w-full text-center items-center relative" key={index}>
                  {/* Delete Button */}
                  <button 
                    className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded"
                    onClick={() => handleDelete(index)}
                  >
                    Delete
                  </button>
                  {/* Render your item here */}
                  <img className="w-48 h-48 object-cover mx-auto" src={item.imgSrc || ''} alt={item.product.name || ''} />
                  <p className="mt-4 text-custom-blue font-semibold text-center">
                    {item.product.name}
                  </p>
                  <table className="w-full p-4 mt-4 shadow-sm border-1 border-gray-300">
                    <tbody className="font-semibold">
                      <tr className="border-b border-gray-200">
                        <td className="p-3 text-left w-1/2">Lower Stock Limit</td>
                        <td className="p-3 text-right w-1/2 h-[30px] font-bold">
                          <input
                            type="number"
                            min="1"
                            value={cardInputValues[index]?.lower_spec_stock || ''}
                            placeholder={item.item.lower_spec_stock}
                            onChange={(e) => handleCardInputChange(e, index, 'lower_spec_stock')}
                            className="w-full h-[30px] p-1 text-right border-1 border-custom-green rounded text-sm text-gray-600"
                          />
                        </td>
                      </tr>
                      <tr className="border-b border-gray-200">
                        <td className="p-3 text-left w-1/2">Upper Stock Limit</td>
                        <td className="p-3 text-right w-1/2 h-[30px] font-bold">
                          <input
                            type="number"
                            min="1"
                            value={cardInputValues[index]?.upper_spec_stock || ''}
                            placeholder={item.item.upper_spec_stock}
                            onChange={(e) => handleCardInputChange(e, index, 'upper_spec_stock')}
                            className="w-full h-[30px] p-1 text-right border-1 border-custom-green rounded text-sm text-gray-600"
                          />
                        </td>
                      </tr>
                      <tr className="border-b border-gray-200">
                        <td className="p-3 text-left w-1/2">Product Status</td>
                        <td className="p-3 text-right w-1/2 font-bold">
                          <select
                            value={cardInputValues[index]?.product_status || ''}
                            onChange={(e) => handleCardInputChange(e, index, 'product_status')}
                            className="w-full text-right text-sm text-gray-600"
                          >
                            <option value="">Select Product Status</option>
                            <option value="active">Active</option>
                            <option value="paused">Paused</option>
                            <option value="non_active">Non Active</option>
                          </select>
                        </td>
                      </tr>
                      <tr className="border-b border-gray-200">
                        <td className="p-3 text-left w-1/2">Product Status Date</td>
                        <td className="p-3 text-right w-1/2 h-[30px] font-bold">
                          <input
                            type="text"
                            value={cardInputValues[index]?.product_status_date || ''}
                            placeholder={item.item.product_status_date}
                            disabled = {true}
                            className="w-full h-[30px] p-1 text-right border-1 border-custom-green rounded text-sm text-gray-400"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
            </div>

            <div className="flex justify-center">
              <button className="bg-white border-none cursor-pointer text-custom-blue text-2xl p-2 mx-2"
                onClick={scrollLeft}>
                <FaArrowAltCircleLeft />
              </button>
              <button className="bg-white border-none cursor-pointer text-custom-blue text-2xl p-2 mx-2"
                onClick={scrollRight}>
                <FaArrowAltCircleRight />
              </button>
            </div>
          </div>


         {/** Add new Products */}
         {/* Checkbox to toggle hidden div */}
         <div className="w-[100%] mt-2 bg-white shadow-sm border-1 border-gray-300 p-2">
            <label className="flex items-center gap-2 ml-2 text-gray-600 font-bold">
              <input
                type="checkbox"
                className="mr-2 transform scale-150"
                onChange={handleCheckboxChange}
              />
              Add new Products
            </label>
          </div>

          {/* Hidden div */}
          {showHiddenDiv && (
            <div className="w-[100%] flex flex-col mt-2 bg-white shadow-sm border-1 border-gray-300 p-4">
               <span className="text-gray-800 font-bold">Select a Product:</span>
               <Select options={productCards} className="mt-4 font-semibold text-gray-600"
                        onChange={handleSelectChange}/>
               
               {selectedProduct && (
              <div className="flex overflow-hidden w-full mt-4">
                <div className="min-w-full text-center items-center relative">
                  {/* Render your item here */}
                  <button 
                          className="absolute top-0 right-0 border-1 rounded cursor-pointer text-white text-sm bg-custom-blue p-2 mx-2"
                           onClick={() =>
                            addNewProduct(
                              selectedProduct.value,
                              cardInputValues[selectedProduct.value.id]?.lower_spec_stock || '',
                              cardInputValues[selectedProduct.value.id]?.upper_spec_stock || '',
                              cardInputValues[selectedProduct.value.id]?.product_status || ''
                            )
                          }>
                           ADD
                     </button>
                  <img className="w-48 h-48 object-cover mx-auto"  src={imgPaths[selectedProduct.value.assortimed_pid][0]} alt={selectedProduct.label} />
                  <p className="mt-4 text-custom-blue font-bold text-center">
                    {selectedProduct.label}
                  </p>
                  <div className="flex justify-center items-center w-full gap-4 text-custom-blue">
                     <p className="mt-4 font-semibold text-center">
                        {selectedProduct.value.category}
                     </p>
                     <p className="mt-4 font-semibold text-center">
                        {selectedProduct.value.brand}
                     </p>
                     <p className="mt-4 font-semibold text-center">
                        € {selectedProduct.value.sales_price_c1}
                     </p>
                  </div>
                  <table className="w-full p-4 mt-4 shadow-sm border-1 border-gray-300">
                    <tbody className="font-semibold">
                      <tr className="border-b border-gray-200">
                        <td className="p-3 text-left w-1/2">Quantity per Product</td>
                        <td className="p-3 text-right w-1/2 h-[30px] font-bold">
                          <input
                            type="number"
                            min="1"
                            value={cardInputValues[selectedProduct.value.id]?.lower_spec_stock || ''}
                            onChange={(e) => handleNewCardInputChange(e, selectedProduct.value.id, 'lower_spec_stock')}
                            className="w-full h-[30px] p-1 text-right border-1 border-custom-green rounded text-sm text-gray-600"
                          />
                        </td>
                      </tr>
                      <tr className="border-b border-gray-200">
                      <td className="p-3 text-left w-1/2">Quantity per Product</td>
                        <td className="p-3 text-right w-1/2 h-[30px] font-bold">
                          <input
                            type="number"
                            min="1"
                            value={cardInputValues[selectedProduct.value.id]?.upper_spec_stock || ''}
                            onChange={(e) => handleNewCardInputChange(e, selectedProduct.value.id, 'upper_spec_stock')}
                            className="w-full h-[30px] p-1 text-right border-1 border-custom-green rounded text-sm text-gray-600"
                          />
                        </td>
                      </tr>
                      <tr className="border-b border-gray-200">
                        <td className="p-3 text-left w-1/2">Product Status</td>
                        <td className="p-3 text-right w-1/2 font-bold">
                          <select
                            value={cardInputValues[selectedProduct.value.id]?.product_status || ''}
                            onChange={(e) => handleNewCardInputChange(e, selectedProduct.value.id, 'product_status')}
                            className="w-full text-right text-sm text-gray-600"
                          >
                            <option value="">Select Product Status</option>
                            <option value="active">Active</option>
                            <option value="paused">Paused</option>
                            <option value="non_active">Non Active</option>
                          </select>
                        </td>
                      </tr>
                      <tr className="border-b border-gray-200">
                      <td className="p-3 text-left w-1/2">Quantity per Product</td>
                        <td className="p-3 text-right w-1/2 h-[30px] font-bold">
                          <input
                            type="text"
                            disabled = { true }
                            value={new Date().toISOString().slice(0, 10)}
                            className="w-full h-[30px] p-1 text-right border-1 border-custom-green rounded text-sm text-gray-400"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            </div>
          )}


          <div className="w-[100%] mt-2 bg-white shadow-sm border-1 border-gray-300">
            <table className="w-full p-4">
              <tbody className="font-bold">
                {sidePackInfo.map((info, index) => (
                  <tr key={index}>
                    <td className="p-3 text-left w-1/2">{info.head}</td>
                    <td className="px-3 py-2 text-center w-1/2 font-bold">
                      <button
                        type="button"
                        className="p-2 border-1 border-custom-blue text-custom-blue rounded hover:bg-custom-blue hover:text-white"
                        onClick={() => handleButtonClick(info.type)}>
                        {info.value}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Modal
                open={popupVisible}
                onClose={() => setPopupVisible(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box sx={modelStyle}>
                    {popupType === 'user' ? (
                        <UserDetails userRecord={dsmRecord?.dsm_creator || null} onClose={onClose} />
                    ) : popupType === 'com' ? (
                        <CompanyDetails comRecord={dsmRecord?.company || null} onClose={onClose} />
                      ) : null}
                  </Box>
              </Modal>
          </div>
        </div>
      </div>

      {/** Save Buttons Section */}
      <div className="w-full items-center flex flex-col justify-center mt-10">
        { failureMessage && (
            <div className="flex text-left flex-col gap-1 mb-10">
              
                <p className="text-red-500 text-sm font-bold">{failureMessage}</p>
            </div>
          )}
          { sucessMessage && (
              <div className="flex text-left flex-col gap-1 mb-10">
                <p className="text-teal-500 text-sm font-bold">{sucessMessage}</p>
              </div>
          )}
          <div className="flex items-start justify-center mb-4 gap-2"> 
                <button  className=" w-full rounded-md text-red-500 border-2 px-6 py-2 font-semibold hover:bg-red-500 hover:text-white sm:w-auto flex flex-row gap-2 justify-center"
                    onClick={cancelChanges}>
                        <FcCancel className="text-xl"/>
                        <span>Cancel Changes</span>
                  </button>
                <button  
                    className=" w-full rounded-md hover:text-custom-blue text-slate-50 border-2 px-6 py-2 font-semibold bg-custom-blue  hover:bg-white sm:w-auto flex flex-row gap-2 justify-center"
                    onClick={saveChanges}>
                        <FaSave className="text-xl"/>
                        <span>Save Changes</span>
                  </button>
          </div>
      </div>
    </div>
  );
}