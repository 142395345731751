import React, { useState } from 'react';

// Icon Imports
import { FiUploadCloud } from 'react-icons/fi';

function PdfUploader({onFileUpload}) {
  const [errorMessage, setErrorMessage] = useState(null);


  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type !== 'application/pdf') {
      setErrorMessage('Only PDF files are allowed.');
    } else {
      setErrorMessage(null);
      const formData = new FormData();
      formData.append('doc_file', selectedFile);

    console.log("Selected File: " + selectedFile);
    
      // Log the FormData content
      for (let pair of formData.entries()) {
        console.log(pair[0] + ': ' + pair[1]);
      }
  
      
      // Call the callback function with the formData
      onFileUpload(selectedFile);
    }
  };


  return (
    <form encType="multipart/form-data">
    <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
      <span className="text-xl text-custom-green">
        <FiUploadCloud />
      </span>
      <label className="popup_review_label">Upload Description File</label>
      <div className="grow">
        <input
          type="file"
          className="input w-full text-gray-500"
          name="upload_description_file"
          accept=".pdf"
          onChange={handleFileChange}
        />
        {errorMessage && <div className="text-red-500">{errorMessage}</div>}
      </div>
    </div>
  </form>
  );
}

export default PdfUploader;
