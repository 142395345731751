import React, { useState } from "react";



// In ComBase component
export default function PaySum({ payRecord }) {
  
    return (
        payRecord ?
        <div className="w-full mt-3 border-1 border-custom-green px-3 py-1 rounded-lg h-fit shadow-custom font-sans">
        
        <div className="my-1 mb-2 text-xl text-custom-blue font-extrabold flex justify-center">
            <span>{payRecord.pname? payRecord.pname : "payment profile " + payRecord.id}</span>
          </div>

        <div className="my-3">
          <div className="my-1 mt-3 text-lg text-teal-600 font-bold flex justify-center">
            <p>Budget holder Info.</p>
          </div>
          <div className="grid grid-cols-1 my-1 font-semibold">
            <span>- {payRecord.budget_holder_name}</span>
          </div>
          <div className="grid grid-cols-1 my-1 font-semibold">
            <span>- {payRecord.budget_holder_phone}</span>
          </div>
          <div className="grid grid-cols-1 my-1 font-semibold">
            <span>- {payRecord.budget_holder_email}</span>
          </div>
          <div className="my-1 mt-3 text-lg text-teal-600 font-bold flex justify-center">
            <p>Payment Method Info.</p>
          </div>
          <div className="grid grid-cols-2 gap-3 my-1 font-semibold">
            <p className="text-custom-blue">Bank:</p>
            <span>{payRecord.bank}</span>
          </div>
          <div className="grid grid-cols-2 gap-3 my-1 font-semibold">
            <p className="text-custom-blue">IBAN:</p>
            <span>{payRecord.IBAN}</span>
          </div>
          <div className="grid grid-cols-2 gap-3 my-1 font-semibold">
            <p className="text-custom-blue">Swift code:</p>
            <span>{payRecord.swift_code}</span>
          </div>
          <div className="grid grid-cols-2 gap-3 my-1 font-semibold">
            <p className="text-custom-blue">PO number:</p>
            <span>{payRecord.PO_number}</span>
          </div>
        </div>
    </div> : null 
    );
  }