import React, { useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Button, Form } from 'react-bootstrap';

import { FaMapLocationDot } from "react-icons/fa6";
import { MdContactMail } from "react-icons/md";
import { MdContactPhone } from 'react-icons/md';
import { MdContacts } from "react-icons/md";
import { FaMoneyBillAlt } from "react-icons/fa";
import { AiFillBank, AiTwotoneProfile } from "react-icons/ai";

// Select Style
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? '2px solid #2849ad' : '2px solid #d1d5db',
    borderRadius: '4px',
    ':hover': {
      border: '2px solid #d1d5db',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#07b1ab' : provided.backgroundColor,
    ':hover': {
      backgroundColor: '#07b1ab60',
    },
    ':first-child': {
      backgroundColor: '#07b1ab60',
    },
  }),
};

const PopupFormPay = () => {
  // Data from Local Storage
  const user = JSON.parse(localStorage.getItem('user'));
  const userRole = user.user_data.role === 'HD' ? 'Head User' : user.role === 'M' ? 'Manager User' : 'Normal User';

  // List All Payment Profiles by Name
  const options = user.payment_data.map((data, index) => ({
    label: data.pay_name ? data.pay_name : 'NA',
    value: data
  }));

  const [showEditForm, setShowEditForm] = useState(true);
  const [selectedDsm, setSelectedDsm] = useState(options[0].value);
  const [selectedDsmOption, setSelectedDsmOption] = useState(options[0]);

  const handleProductSelect = (selectedDsmOption) => {
    setSelectedDsmOption(selectedDsmOption);
    setSelectedDsm(selectedDsmOption.value);
    setShowEditForm(true);
  };

  // Handling Form Operations
  const [formStatus, setFormStatus] = useState('');
  const [formValues, setFormValues] = useState({
    pay_name: '',
    PO_number: selectedDsm.PO_number,
    budget_holder_name: '',
    budget_holder_email: '',
    budget_holder_phone: '',
    bank: '',
    IBAN: '',
    swift_code: '',
    invoicing_address: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    console.log("Inside Handle Submit!!!!!");

    try {
      const postData = {
        pay_name: formValues.pay_name ? formValues.pay_name : null,
        budget_holder_name: formValues.budget_holder_name ? formValues.budget_holder_name : null,
        budget_holder_email: formValues.budget_holder_email ? formValues.budget_holder_email : null,
        budget_holder_phone: formValues.budget_holder_phone ? formValues.budget_holder_phone : null,
        bank: formValues.bank ? formValues.bank : null,
        IBAN: formValues.IBAN ? formValues.IBAN : null,
        swift_code: formValues.swift_code ? formValues.swift_code : null,
        invoicing_address: formValues.invoicing_address ? formValues.invoicing_address : selectedDsm.invoicing_address,
      };
  
      console.log("post values");
      console.log(postData);
      
      setFormStatus('Loading...');

      const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/users/update-payment/${selectedDsm.id}/`, postData);
      setFormStatus('Success!');
      console.log("Payment Profile Successfully Updated!");
    } catch (error) {
      setFormStatus('An error occurred.');
      console.log("Printing Error!!!!");
      console.log(error.response.data);
    }
  };

  const iconMap = {
    pay_name: <AiTwotoneProfile />,
    PO_number: <FaMoneyBillAlt />,
    budget_holder_name: <MdContacts />,
    budget_holder_email: <MdContactMail />,
    budget_holder_phone: <MdContactPhone />,
    bank: <AiFillBank />,
    IBAN: <AiFillBank />,
    swift_code: <AiFillBank />,
    invoicing_address: <FaMapLocationDot />,
  };

  const rows = [
    { name: "pay_name", label: "Profile name", type: "text" },
    { name: "PO_number", label: "PO number", type: "text" },
    { name: "budget_holder_name", label: "Budget Holder name", type: "text" },
    { name: "budget_holder_email", label: "Budget Holder email", type: "email" },
    { name: "budget_holder_phone", label: "Budget Holder mobile", type: "phone" },
    { name: "bank", label: "Bank", type: "text" },
    { name: "IBAN", label: "IBAN", type: "text" },
    { name: "swift_code", label: "Swift Code", type: "text" },
    { name: "invoicing_address", label: "Invoicing Address", type: "address" },
  ];

  return (
    <>
      {options.length > 1 && (
        <>
          <h2 className="mx-auto mt-10 mb-10 text-center text-xl font-semibold">
            Choose a Payment Profile to Display
          </h2>

          <div className="mx-auto mb-12 flex w-[50%] flex-col justify-around gap-14 sm:gap-6 lg:flex-row">
            <Select
              options={options}
              styles={customStyles}
              className="m-auto w-full"
              onChange={handleProductSelect}
              value={selectedDsmOption}
            />
          </div>
        </>
      )}
      {showEditForm && (
        <Form onSubmit={handleSubmit} className="bg-white py-4 px-12">
          {rows.map((row) => (
            <div key={row.name} className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-custom-green">
                {iconMap[row.name]}
              </span>
              <label className="popup_review_label">{row.label}</label>
              <div className="grow">
                {row.name === 'PO_number' ? (
                  <input
                    className="input w-full"
                    type={row.type}
                    name={row.name}
                    value={selectedDsm.PO_number}
                    disabled
                  />
                ) : (
                  <input
                    className="input w-full"
                    type={row.type}
                    name={row.name}
                    value={formValues[row.name]}
                    onChange={handleInputChange}
                  />
                )}
              </div>
            </div>
          ))}
          <div className="form-status text-red-500 font-semibold text-sm flex items-center justify-center">
            {formStatus}
          </div>
          <Button
            variant="primary"
            type='submit'
            className="mx-auto my-3 block cursor-pointer border-0 bg-custom-blue px-[100px] py-[10px] text-sm font-semibold"
          >
            Submit
          </Button>
        </Form>
      )} 
    </>
  );
};

export default PopupFormPay;