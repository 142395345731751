import React, { useRef, useState, useEffect } from "react";
import { Modal, Box } from '@mui/material';
import Select from 'react-select';
import axios from "axios";

// Icon Imports 
import { FaArrowAltCircleLeft, FaArrowAltCircleRight, FaPlusCircle, FaSave } from "react-icons/fa";


// Local Imports
import './Gallery.css';
import UserDetails from "../summary/UserSum";
import CompanyDetails from "../summary/ComSum";
import DocUpload from "./DocUpload";
import { FcCancel } from "react-icons/fc";




export default function EditPack({ packRecord, onDataReturn }) {
  const storedProducts = JSON.parse(localStorage.getItem('products'));
  const imgPaths = JSON.parse(localStorage.getItem('imgPaths'));
  const packItems = packRecord?.products || [];

  const productCards = storedProducts.map((product, index) => ({
     value: product || [],
     label: product.name || '',
  }));

  const getProductById = (id) => {
    const product = storedProducts.find(product => product.id === id);
    return product;
  }

  const getProductNameById = (id) => {
    const product = getProductById(id);
    return product.product_name;
  }

  const getProductPIDById = (id) => {
    const product = getProductById(id);
    return product.assortimed_pid;
  }

  const [cards, setCards] = useState([]);

  useEffect(() => {
    setCards(packItems.map((product, index) => ({
      item: product,
      product: getProductById(product.product),
      imgSrc: imgPaths[getProductPIDById(product.product)][0]
    })));
  }, [packRecord]);

  const galleryRef = useRef(null);

  const scrollLeft = () => {
    galleryRef.current.scrollBy({ left: -200, behavior: 'smooth' });
  };

  const scrollRight = () => {
    galleryRef.current.scrollBy({ left: 200, behavior: 'smooth' });
  };

  // Info Popoups
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupType, setPopupType] = useState(null);

  const handleButtonClick = (type) => {
      setPopupType(type);
      setPopupVisible(true); 
  };

  const sidePackInfo = [
    {head: 'Package Creator', value: packRecord?.pack_creator?.fullname, type: 'user'},
    {head: 'Package Comapny', value: packRecord?.company?.cname, type: 'com'},
    {head: 'Package Doc.', value: 'Upload Doc.', type: 'doc'},
 ];


  const modelStyle = {
    position: 'absolute',
    top: '25vh',
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: '50%',
    maxHeight: '50vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
    rounded: 'full',
  };

  const onClose = () => {
    setPopupVisible(false);
    setPopupType(null);
  }

  const [inputValues, setInputValues] = useState({
    packageName: {value: '', disabled: false},
    id: { value: '', disabled: true },
    department: { value: '', disabled: false },
    packageStatus: { value: '', disabled: false },
    packageStatusDate: { value: '', disabled: true },
    numOfProducts: { value: '', disabled: true },
    docType: { value: '', disabled: false },
    docPrice: { value: '', disabled: true },
    creationFee: { value: '', disabled: true },
    creationFeeStatus: { value: '', disabled: false },
    creationDate: { value: '', disabled: true },
    totalPackPrice: { value: '', disabled: true },
  });

  useEffect(() => {
    setInputValues({
      packageName: { value: packRecord?.package_name || '', disabled: false },
      id: { value: packRecord?.id || '', disabled: true },
      department: { value: packRecord?.department || '', disabled: false },
      packageStatus: {
        value: packRecord?.package_status
          ? packRecord.package_status
          : '',
        disabled: false,
      },
      packageStatusDate: {
        value: packRecord?.package_status
          ? (packRecord.package_status === 'active'
            ? packRecord.active_date
            : (packRecord.package_status === 'paused'
              ? packRecord.paused_date
              : packRecord.non_active_date)
          )?.slice(0, 10)
          : '',
        disabled: true,
      },
      numOfProducts: { value: packRecord?.num_of_products ? packRecord.num_of_products + ' products' : '', disabled: true },
      docType: { value: packRecord?.doc_type || '', disabled: false },
      docPrice: { value: packRecord?.doc_price ? '€ ' + packRecord.doc_price : '', disabled: true },
      creationFee: { value: packRecord?.creation_fee ? '€ ' + packRecord.creation_fee : '', disabled: true },
      creationFeeStatus: { value: packRecord?.creation_fee_status || '', disabled: false },
      creationDate: { value: packRecord?.creation_date?.slice(0, 10) || '', disabled: true },
      totalPackPrice: { value: packRecord?.total_pack_price ? '€ ' + packRecord.total_pack_price : '', disabled: true },
    });
  }, [packRecord]);

  const handleInputChange = (e, key) => {
    setInputValues({
      ...inputValues,
      [key]: {
        ...inputValues[key],
        value: e.target.value,
      },
    });
  };

  const [cardInputValues, setCardInputValues] = useState([]);

  useEffect(() => {
    setCardInputValues(
      packRecord?.products?.map(product => ({
        quantity_per_product: product.quantity_per_product ? product.quantity_per_product : '',
        product_status: product.product_status ? product.product_status : '',
        productRecord: getProductById(product.product),
      })) || []
    );
  }, [packRecord]);

  const handleCardInputChange = (e, index, key) => {
    const newInputValues = [...cardInputValues];
    newInputValues[index][key] = e.target.value;
    setCardInputValues(newInputValues);
  };

  const handleDelete = (index) => {
    if (Array.isArray(cardInputValues)) {
      const newCardInputValues = cardInputValues.filter((_, i) => i !== index);
      const newCards = cards.filter((_, i) => i !== index);
      setCardInputValues(newCardInputValues);
      setCards(newCards);
    } else {
      console.error("cardInputValues is not an array");
    }
  };


  // Add products to package section
  const [showHiddenDiv, setShowHiddenDiv] = useState(false);

  const handleCheckboxChange = (e) => {
    setShowHiddenDiv(e.target.checked);
  };

  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleSelectChange = (selectedOption) => {
    setSelectedProduct(selectedOption);
    // Save the selected product value to some place
    console.log("Selected product:", selectedOption);
  };

  const handleNewCardInputChange = (e, productId, key) => {
   setCardInputValues({
     ...cardInputValues,
     [productId]: {
       ...cardInputValues[productId],
       [key]: e.target.value,
     },
   });
 };

 
 const addNewProduct = (product, quantityPerProduct, productStatus) => {
  const newCardInputValues = {
    ...cardInputValues,
    [product.id]: {
      quantity_per_product: Number(quantityPerProduct),
      product_status: productStatus,
      productRecord: product,
    },
  };

  setCardInputValues(newCardInputValues);

  const newCard = {
    item: {
      quantity_per_product: Number(quantityPerProduct),
      product_status: productStatus,
    },
    product: product,
    imgSrc: imgPaths[product.assortimed_pid][0],
  };

  setCards([...cards, newCard]);
};




  // ADD and Cancel Actions
  const cancelChanges = () => {
    onDataReturn();
  };

  
  
  const createProductsArray = () => {
    const products = Object.keys(cardInputValues).map(productId => {
      const { quantity_per_product, product_status, productRecord } = cardInputValues[productId];
      //const total_price_per_product = calculateTotalPrice(quantity_per_product);
  
      return {
        product: Number(productRecord.id),
        quantity_per_product: Number(quantity_per_product),
      };
    });
  
    // console.log("Products List: ");
    // console.log(products);

    return products;
  };

  const docPriceMap = {
    colored: 0.95,
    non_colored: 0.5,
  };

  const saveChanges = async () => {
      const productsList = createProductsArray();

      const packData = {
        company: packRecord.pack_creator.company,
        pack_creator: packRecord.pack_creator.id,
        package_name: inputValues.packageName.value || packRecord.package_name,
        department: inputValues.department.value || packRecord.department,
        package_status: inputValues.packageStatus.value || '',
        doc_type: inputValues.docType.value || packRecord.doc_type,
        doc_price: docPriceMap[inputValues.docType] || packRecord.doc_price,
        creation_fee_status: inputValues.creationFeeStatus.value || packRecord.creation_fee_status,
        // package_fulfillment_price: inputValues.package_fulfillment_price || parseFloat(2.75.toFixed(2)),
      total_pack_price: parseFloat((inputValues.totalPackPrice + docPriceMap[inputValues.docType])) || packRecord.total_pack_price,
        num_of_products: productsList.length || 1,
        products: productsList,
      }


      try {
        const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/products/package/adminupdate/${packRecord.id}/`,
            packData
        );

        if (response.status === 200 || response.status === 201) {
          console.log("Changes saved successfully!");
          // setSelectedProduct(null);
          // setShowHiddenDiv(false);
          // setCardInputValues([]);
        } else {
          console.error("Error saving changes: ", response);
        }

      } catch (error) {
        console.error("Error saving changes: ", error);
      }

      console.log("Saving changes:");
      console.log(packData);
      console.log(cardInputValues);
      console.log(productsList);
      //setCards([]);
  };

  return (
    <div className="w-full mb-8">
      <h1 className="text-center text-lg font-bold text-custom-blue">
        <span className="text-gray-500 mr-4">Package Name:</span>
        {packRecord?.package_name || 'Choose a Package!'}</h1>
      <div className="w-full flex flex-row gap-4 mt-8">
        <div className="flex-1 w-[50%] bg-white shadow-sm border-1 border-gray-300">
          <table className="w-full p-4">
            <tbody className="font-semibold">
              {Object.keys(inputValues).map((key, index) => (
                <tr className="border-b border-gray-200" key={index}>
                  <td className="p-3 text-left w-1/2">{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</td>
                  <td className="p-3 text-right w-1/2 h-full font-bold">
                    {key === 'docType' ? (
                      <select
                        value={inputValues[key].value}
                        onChange={(e) => handleInputChange(e, key)}
                        className="w-full text-right text-sm text-gray-600"
                        disabled={inputValues[key].disabled}
                      >
                        <option value="">Select Document Type</option>
                        <option value="colored">Colored</option>
                        <option value="non_colored">Non-Colored</option>
                      </select>
                    ) : key === 'packageStatus' ? (
                      <select
                        value={inputValues[key].value}
                        onChange={(e) => handleInputChange(e, key)}
                        className="w-full text-right text-sm text-gray-600"
                        disabled={inputValues[key].disabled}
                      >
                        <option value="">Select Package Status</option>
                        <option value="active">Active</option>
                        <option value="paused">Paused</option>
                        <option value="non_active">Non Active</option>
                      </select>
                    ) : key === 'creationFeeStatus' ? (
                      <select
                        value={inputValues[key].value}
                        onChange={(e) => handleInputChange(e, key)}
                        className="w-full text-right text-sm text-gray-600"
                        disabled={inputValues[key].disabled}
                      >
                        <option value="">Select Creation Fee Status</option>
                        <option value="created">Created</option>
                        <option value="invoiced">Invoiced</option>
                        <option value="paid">Paid</option>
                      </select>
                    ) : (
                      <input
                        type="text"
                        value={inputValues[key].value}
                        onChange={(e) => handleInputChange(e, key)}
                        className={`w-full h-[30px] p-1 text-right border-1 border-custom-green rounded text-sm ${inputValues[key].disabled ? 'bg-gray-100 text-gray-400' : 'text-gray-600'}`}
                        disabled={inputValues[key].disabled}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex-1 w-[50%] flex flex-col items-center">
          <div className="w-[100%] flex flex-col items-center">
            <div className="flex overflow-hidden w-full" ref={galleryRef}>
              {cards.map((item, index) => (
                <div className="min-w-full text-center items-center relative" key={index}>
                  {/* Delete Button */}
                  <button 
                    className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded"
                    onClick={() => handleDelete(index)}
                  >
                    Delete
                  </button>
                  {/* Render your item here */}
                  <img className="w-48 h-48 object-cover mx-auto" src={item.imgSrc || ''} alt={item.product.name || ''} />
                  <p className="mt-4 text-custom-blue font-semibold text-center">
                    {item.product.name}
                  </p>
                  <table className="w-full p-4 mt-4 shadow-sm border-1 border-gray-300">
                    <tbody className="font-semibold">
                      <tr className="border-b border-gray-200">
                        <td className="p-3 text-left w-1/2">Quantity per Product</td>
                        <td className="p-3 text-right w-1/2 h-[30px] font-bold">
                          <input
                            type="number"
                            min="1"
                            value={cardInputValues[index]?.quantity_per_product || ''}
                            placeholder={item.item.quantity_per_product}
                            onChange={(e) => handleCardInputChange(e, index, 'quantity_per_product')}
                            className="w-full h-[30px] p-1 text-right border-1 border-custom-green rounded text-sm text-gray-600"
                          />
                        </td>
                      </tr>
                      {/* <tr className="border-b border-gray-200">
                        <td className="p-3 text-left w-1/2">Product Status</td>
                        <td className="p-3 text-right w-1/2 font-bold">
                          <select
                            value={cardInputValues[index]?.product_status || ''}
                            onChange={(e) => handleCardInputChange(e, index, 'product_status')}
                            className="w-full text-right text-sm text-gray-600"
                          >
                            <option value="">Select Product Status</option>
                            <option value="active">Active</option>
                            <option value="paused">Paused</option>
                            <option value="non_active">Non Active</option>
                          </select>
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              ))}
            </div>

            <div className="flex justify-center">
              <button className="bg-white border-none cursor-pointer text-custom-blue text-2xl p-2 mx-2"
                onClick={scrollLeft}>
                <FaArrowAltCircleLeft />
              </button>
              <button className="bg-white border-none cursor-pointer text-custom-blue text-2xl p-2 mx-2"
                onClick={scrollRight}>
                <FaArrowAltCircleRight />
              </button>
            </div>
          </div>


         {/** Add new Products */}
         {/* Checkbox to toggle hidden div */}
         <div className="w-[100%] mt-2 bg-white shadow-sm border-1 border-gray-300 p-2">
            <label className="flex items-center gap-2 ml-2 text-gray-600 font-bold">
              <input
                type="checkbox"
                className="mr-2 transform scale-150"
                onChange={handleCheckboxChange}
              />
              Add new Products
            </label>
          </div>

          {/* Hidden div */}
          {showHiddenDiv && (
            <div className="w-[100%] flex flex-col mt-2 bg-white shadow-sm border-1 border-gray-300 p-4">
               <span className="text-gray-800 font-bold">Select a Product:</span>
               <Select options={productCards} className="mt-4 font-semibold text-gray-600"
                        onChange={handleSelectChange}/>
               
               {selectedProduct && (
              <div className="flex overflow-hidden w-full mt-4">
                <div className="min-w-full text-center items-center relative">
                  {/* Render your item here */}
                  <button 
                          className="absolute top-0 right-0 border-1 rounded cursor-pointer text-white text-sm bg-custom-blue p-2 mx-2"
                           onClick={() =>
                            addNewProduct(
                              selectedProduct.value,
                              cardInputValues[selectedProduct.value.id]?.quantity_per_product || '',
                              cardInputValues[selectedProduct.value.id]?.product_status || ''
                            )
                          }>
                           ADD
                     </button>
                  <img className="w-48 h-48 object-cover mx-auto"  src={imgPaths[selectedProduct.value.assortimed_pid][0]} alt={selectedProduct.label} />
                  <p className="mt-4 text-custom-blue font-bold text-center">
                    {selectedProduct.label}
                  </p>
                  <div className="flex justify-center items-center w-full gap-4 text-custom-blue">
                     <p className="mt-4 font-semibold text-center">
                        {selectedProduct.value.category}
                     </p>
                     <p className="mt-4 font-semibold text-center">
                        {selectedProduct.value.brand}
                     </p>
                     <p className="mt-4 font-semibold text-center">
                        € {selectedProduct.value.sales_price_c1}
                     </p>
                  </div>
                  <table className="w-full p-4 mt-4 shadow-sm border-1 border-gray-300">
                    <tbody className="font-semibold">
                      <tr className="border-b border-gray-200">
                        <td className="p-3 text-left w-1/2">Quantity per Product</td>
                        <td className="p-3 text-right w-1/2 h-[30px] font-bold">
                          <input
                            type="number"
                            min="1"
                            value={cardInputValues[selectedProduct.value.id]?.quantity_per_product || ''}
                            onChange={(e) => handleNewCardInputChange(e, selectedProduct.value.id, 'quantity_per_product')}
                            className="w-full h-[30px] p-1 text-right border-1 border-custom-green rounded text-sm text-gray-600"
                          />
                        </td>
                      </tr>
                      {/* <tr className="border-b border-gray-200">
                        <td className="p-3 text-left w-1/2">Product Status</td>
                        <td className="p-3 text-right w-1/2 font-bold">
                          <select
                            value={cardInputValues[selectedProduct.value.id]?.product_status || ''}
                            onChange={(e) => handleNewCardInputChange(e, selectedProduct.value.id, 'product_status')}
                            className="w-full text-right text-sm text-gray-600"
                          >
                            <option value="">Select Product Status</option>
                            <option value="active">Active</option>
                            <option value="paused">Paused</option>
                            <option value="non_active">Non Active</option>
                          </select>
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            </div>
          )}


          <div className="w-[100%] mt-2 bg-white shadow-sm border-1 border-gray-300">
            <table className="w-full p-4">
              <tbody className="font-bold">
                {sidePackInfo.map((info, index) => (
                  <tr key={index}>
                    <td className="p-3 text-left w-1/2">{info.head}</td>
                    <td className="px-3 py-2 text-center w-1/2 font-bold">
                      <button
                        type="button"
                        className="p-2 border-1 border-custom-blue text-custom-blue rounded hover:bg-custom-blue hover:text-white"
                        onClick={() => handleButtonClick(info.type)}>
                        {info.value}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Modal
                open={popupVisible}
                onClose={() => setPopupVisible(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box sx={modelStyle}>
                    {popupType === 'user' ? (
                        <UserDetails userRecord={packRecord?.pack_creator || null} onClose={onClose} />
                      ) : 
                      popupType === 'com' ? (
                        <CompanyDetails comRecord={packRecord?.company || null} onClose={onClose} />
                      ) : 
                      popupType === 'doc' ?(
                        <DocUpload packID={packRecord?.id || null} packName={packRecord?.pack_name || null} onClose={onClose} />
                      ) : null}
                  </Box>
              </Modal>
          </div>
        </div>
      </div>

      {/** Save Buttons Section */}
      <div className="w-full items-center flex justify-center mt-10">
          <div className="flex items-start justify-center mb-4 gap-2"> 
                <button  className=" w-full rounded-md text-red-500 border-2 px-6 py-2 font-semibold hover:bg-red-500 hover:text-white sm:w-auto flex flex-row gap-2 justify-center"
                    onClick={cancelChanges}>
                        <FcCancel className="text-xl"/>
                        <span>Cancel Changes</span>
                  </button>
                <button  
                    className=" w-full rounded-md hover:text-custom-blue text-slate-50 border-2 px-6 py-2 font-semibold bg-custom-blue  hover:bg-white sm:w-auto flex flex-row gap-2 justify-center"
                    onClick={saveChanges}>
                        <FaSave className="text-xl"/>
                        <span>Save Changes</span>
                  </button>
          </div>
      </div>
    </div>
  );
}