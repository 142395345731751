import React, { useRef, useState, useEffect } from "react";
import { Modal, Box } from '@mui/material';
import Select from 'react-select';
import axios from "axios";

// Icon Imports 
import { FaArrowAltCircleLeft, FaArrowAltCircleRight, FaPlusCircle, FaSave } from "react-icons/fa";


// Local Imports
import './Gallery.css';
import UserDetails from "../summary/UserSum";
import CompanyDetails from "../summary/ComSum";
import { FcCancel } from "react-icons/fc";
import FirstDocUpload from "./FirstDocUpload";




export default function NewPack({ onDataReturn }) {
  const storedProducts = JSON.parse(localStorage.getItem('products'));
  const imgPaths = JSON.parse(localStorage.getItem('imgPaths'));
  //const packItems = packRecord?.products || [];

  const productCards = storedProducts.map((product, index) => ({
     value: product || [],
     label: product.name || '',
  }));

  const getProductById = (id) => {
    const product = storedProducts.find(product => product.id === id);
    return product;
  }

  const getProductNameById = (id) => {
    const product = getProductById(id);
    return product.product_name;
  }

  const getProductPIDById = (id) => {
    const product = getProductById(id);
    return product.assortimed_pid;
  }

  const [cards, setCards] = useState([]);

  const galleryRef = useRef(null);

  const scrollLeft = () => {
    galleryRef.current.scrollBy({ left: -200, behavior: 'smooth' });
  };

  const scrollRight = () => {
    galleryRef.current.scrollBy({ left: 200, behavior: 'smooth' });
  };

  // Info Popoups
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupType, setPopupType] = useState(null);

  const handleButtonClick = (type) => {
      setPopupType(type);
      setPopupVisible(true); 
  };


  const modelStyle = {
    position: 'absolute',
    top: '25vh',
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: '50%',
    maxHeight: '50vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
    rounded: 'full',
  };

  const onClose = () => {
    setPopupVisible(false);
    setPopupType(null);
  }


  // Products Items into Package
  const [cardInputValues, setCardInputValues] = useState([]);

  const handleCardInputChange = (e, index, key) => {
    const newInputValues = [...cardInputValues];
    newInputValues[index][key] = e.target.value;
    setCardInputValues(newInputValues);
  };

  const handleDelete = (index) => {
    if (Array.isArray(cardInputValues)) {
      const newCardInputValues = cardInputValues.filter((_, i) => i !== index);
      const newCards = cards.filter((_, i) => i !== index);
      setCardInputValues(newCardInputValues);
      setCards(newCards);
    } else {
      console.error("cardInputValues is not an array");
    }
  };


  // Add products to package section
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleSelectChange = (selectedOption) => {
    setSelectedProduct(selectedOption);
    // Save the selected product value to some place
    //console.log("Selected product:", selectedOption);
  };

  const handleNewCardInputChange = (e, productId, key) => {
   setCardInputValues({
     ...cardInputValues,
     [productId]: {
       ...cardInputValues[productId],
       [key]: e.target.value,
     },
   });
 };

 
 const addNewProduct = (product, quantityPerProduct, productStatus) => {
    const newCardInputValues = {
      ...cardInputValues,
      [product.id]: {
        quantity_per_product: Number(quantityPerProduct) || 1,
        product_status: productStatus,
        productRecord: product,
      },
    };

    setCardInputValues(newCardInputValues);

    const newCard = {
      item: {
        quantity_per_product: Number(quantityPerProduct) || 1,
        product_status: productStatus,
      },
      product: product,
      imgSrc: imgPaths[product.assortimed_pid][0],
    };

    setCards([...cards, newCard]);
  };


  // ADD and Cancel Actions
  const cancelChanges = () => {
    onDataReturn();
  };


  // Enter Products List
  const docPriceMap = {
    colored: 0.95,
    non_colored: 0.5,
  };

  const formattedDate = (new Date()).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
  const [inputValues, setInputValues] = useState({
    packageName: {value: '', disabled: false},
    department: { value: '', disabled: false },
    packageStatus: { value: '', disabled: false },
    packageStatusDate: { value: formattedDate, disabled: true },
    numOfProducts: { value: 0, disabled: true },
    docType: { value: '', disabled: false },
    docPrice: { value: 0.00, disabled: true },
    creationFee: { value: 0.00, disabled: true },
    creationFeeStatus: { value: '', disabled: false },
    creationDate: { value: formattedDate, disabled: true },
    totalPackPrice: { value: 0.00, disabled: true },
  });

  const handleInputChange = (e, key) => {
    setInputValues({
      ...inputValues,
      [key]: {
        ...inputValues[key],
        value: e.target.value,
      },
    });
  };


  useEffect(() => {
    // Update docPrice when docType changes
    const numOfProducts = cards.length || 0;
    setInputValues((prevValues) => ({
      ...prevValues,
      numOfProducts: { ...prevValues.numOfProducts, value: numOfProducts },
    }));
  }, [cards]);


  useEffect(() => {
    // Update docPrice when docType changes
    const docPrice = docPriceMap[inputValues.docType.value] || 0.00;
    setInputValues((prevValues) => ({
      ...prevValues,
      docPrice: { ...prevValues.docPrice, value: docPrice },
    }));
  }, [inputValues.docType.value]);


  useEffect(() => {
    // Update totalPackPrice when docPrice or numOfProducts changes
    const creationFee = (2.75 * inputValues.numOfProducts.value) || 0.00;
    setInputValues((prevValues) => ({
      ...prevValues,
      creationFee: { ...prevValues.creationFee, value: creationFee.toFixed(2) },
    }));
  }, [inputValues.numOfProducts.value]);

  useEffect(() => {
    //console.log("Products Cards in Total Calculations: ");
    //console.log(cards);
    // Update totalPackPrice when docPrice or numOfProducts changes
    const totalProductsPrice = cards.reduce((total, product) => {
      return total + (product.product.sales_price_c1 * product.item.quantity_per_product);
    }, 0.00);
    const totalPackPrice = (parseFloat(inputValues.docPrice.value) + parseFloat(totalProductsPrice) ) || 0.00;
    setInputValues((prevValues) => ({
      ...prevValues,
      totalPackPrice: { ...prevValues.totalPackPrice, value: totalPackPrice.toFixed(2) },
    }));
  }, [inputValues.docPrice.value, inputValues.numOfProducts.value, cards]);
  
  
  // Preparing Company and User Lists
    // reading user Data
    const user = JSON.parse(localStorage.getItem('user'));
    // options for Company
    const [comlist, setComlist] = useState([]);
    const [companyId, setCompanyId] = useState(null);
    const [ packCompany, setPackCompany ] = useState([]);
    const [userlist, setUserlist] = useState([]);
    const [userOptionsList, setUserOptionsList] = useState([]);
    const [creatorId, setCreatorId ] = useState(null);
    const [ packCreator, setPackCreator ] = useState([]);
  
    const getCompanies = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/admin/comlist/`);
        //console.log("Companies:   " + JSON.stringify(response.data));
        setComlist(response.data);
      } catch (error) {
        console.error(`Error retrieving companies: ${error}`);
      }
    };
    
    useEffect(() => {
      getCompanies();
    }, []);
    
    const Company = comlist.map(com => ({
      value: com.id,
      label: com.cname
    }));


    const sidePackInfo = [
      {head: 'Package Comapny', value: 'CHECK', type: 'com', options: Company,
        selectedItem: companyId,
        setSelectedItem: setCompanyId},
      {head: 'Package Creator', value: 'CHECK', type: 'user', options: userOptionsList,
        selectedItem: creatorId,
        setSelectedItem: setCreatorId},
      {head: 'Package Doc.', value: 'Upload Doc.', type: 'doc'},
   ];

    useEffect(() => {
      if (companyId) {
        // Update user list
        const newUserlist = user.company_users
          .filter(user => user.company === companyId.value);
        const userOptions = newUserlist.map(user => ({
          value: user.id,
          label: user.fullname
        }));
        setUserOptionsList(userOptions);
        setUserlist(newUserlist);
        // Additional logic for setting pay record from company list
        const company = comlist.find(com => com.id === companyId.value);
        setPackCompany(company);
      } else {
        setUserlist([]);
        setPackCompany(null);
      }
    }, [companyId]);

    useEffect(() => {
      if (companyId) {
        // Additional logic for setting pay record from company list
        const company = comlist.find(com => com.id === companyId.value);
        setPackCompany(company);
      } else {
        setUserlist([]);
        setPackCompany(null);
        setPackCreator(null);
        setCreatorId(null);
      }
    }, [companyId]);


    useEffect(() => {
      if (creatorId) {
        // Additional logic for setting pay record from company list
        const user = userlist.find(user =>  user.id === creatorId.value);
        setPackCreator(user);
      } else {
        setPackCreator(null);
        setCreatorId(null);
      }
    }, [creatorId]);
  


  // First time Upload Doc. Data
  const [successMessages, setSuccessMessages] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);

  const getDocData = (selectedFile) => {
    setUploadedFile(selectedFile);
  }

  const uploadDoc = async (packID) => {
    if (uploadedFile) {
      // Rename, and Prepare fileData
      const newFileName = 'pack_' + packID + '.pdf';
      const renamedFile = new File([uploadedFile], newFileName, { type: uploadedFile.type });
      const formData = new FormData();
      formData.append('doc_file', renamedFile);
      // Save Uploaded File
      try {
          const fileresponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/media/package/upload/${packID}/`, 
              formData, {
              headers: {
                  'Content-Type': 'multipart/form-data',
              },
          });
          console.log("File Uploaded Sucessfully!");
          setSuccessMessages([...successMessages, 'File Uploaded Sucessflly!']);
      } catch (error) {
          console.error('Error uploading file:', error);
      }
  } 
  // else {
  //     setAlertMessages([...alertMessages, 'Please upload a desc. doc first.']);
  // }
  }
  
  

  // Prepare and post package record to the server
  const createProductsArray = () => {
    const products = Object.keys(cardInputValues).map(productId => {
      const { quantity_per_product, product_status, productRecord } = cardInputValues[productId];
      //const total_price_per_product = calculateTotalPrice(quantity_per_product);
  
      return {
        product: Number(productRecord.id),
        quantity_per_product: Number(quantity_per_product),
      };
    });
  
    return products;
  };


  const [ alertMessages, setAlertMessages ] = useState([]);
  const validateInputValues = (inputValues) => {
    const messages = [];
  
    for (const key in inputValues) {
      if (inputValues.hasOwnProperty(key)) {
        const { value, disabled } = inputValues[key];
        if (key === 'numOfProducts' && value < 2) {
          messages.push('You need to add at least 2 products to the package!');
        } else if (!disabled && ['', 0, 0.00, null, undefined].includes(value)) {
          messages.push(`The ${key} is missing!`);
        }
      }
    }
  
    return messages;
  };


  const [ packRecord, setPackRecord ] = useState([]);
  const saveChanges = async () => {
      let newAlertMessages = [];
      const productsList = createProductsArray();
      newAlertMessages = validateInputValues(inputValues);
      
      if (packCreator === null || packCompany === null) {
        if (packCreator === null && packCompany !== null) {
            newAlertMessages = [...newAlertMessages, 'Select a Package creator!'];
        } else if (packCompany === null && packCreator !== null ) {
            newAlertMessages = [...newAlertMessages, 'Select a Package company!'];
        } else {
          newAlertMessages = [...newAlertMessages, 'Select a Package company and creator!'];
        }
      }

      if (uploadedFile === null && inputValues.docType.value !== '') {
        newAlertMessages = [...newAlertMessages, 'Please upload a Desc. Doc first!'];
      }
      
      if (newAlertMessages.length > 0) {
        setAlertMessages(newAlertMessages);
        return;
      } else {
        setAlertMessages([]);
      }

      const packData = {
        company: packCreator?.company || '',
        pack_creator: packCreator?.id || '',
        package_name: inputValues.packageName.value || '',
        department: inputValues.department.value || '',
        creation_date: new Date().toISOString(),
        creation_fee: parseFloat(inputValues.creationFee.value) || (cards.length * 2.75),
        creation_fee_status: inputValues.creationFeeStatus.value || 'created',
        doc_type: inputValues.docType.value || 'non_colored',
        doc_price: docPriceMap[inputValues.docType.value] || docPriceMap['non_colored'],
        package_status: inputValues.packageStatus.value || 'active',
        active_date: new Date().toISOString(),
        paused_date: null,
        non_active_date: null,
        num_of_products: inputValues.numOfProducts.value || (productsList.length),
        // package_fulfillment_price: inputValues.package_fulfillment_price || parseFloat(2.75.toFixed(2)),
      total_pack_price: parseFloat(inputValues.totalPackPrice.value) || 0.00,
      products: productsList,
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/products/package/create/`,
            packData
        );

        if (response.status === 200 || response.status === 201) {
            const packID = response.data.id;
            // Upload Doc
            await uploadDoc(packID);
            console.log("Package Created saved successfully!");
            setSuccessMessages([...successMessages, 'Package Created saved successfully!']);
            setPackRecord(response.data);
            // setSelectedProduct(null);
            // setShowHiddenDiv(false);
            // setCardInputValues([]);
        } else {
          console.error("Error saving changes: ", response);
        }

      } catch (error) {
        console.error("Error saving changes: ", error);
      }

      // console.log("Saving changes:");
      // //console.log(inputValues);
      // console.log(alertMessages);
      // console.log(packData);
      // //console.log(cardInputValues);
      // console.log(productsList);
      // // setCards([]);
  };

  return (
    <div className="w-full mb-8">
      <div className="w-full flex flex-row gap-4 mt-8">
        <div className="flex-1 w-[50%] bg-white shadow-sm border-1 border-gray-300">
          <table className="w-full p-4">
            <tbody className="font-semibold">
              {Object.keys(inputValues).map((key, index) => (
                <tr className="border-b border-gray-200" key={index}>
                  <td className="p-3 text-left w-1/2">{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</td>
                  <td className="p-3 text-right w-1/2 h-full font-bold">
                    {key === 'docType' ? (
                      <select
                        value={inputValues[key].value}
                        onChange={(e) => handleInputChange(e, key)}
                        className="w-full text-right text-sm text-gray-600"
                        disabled={inputValues[key].disabled}
                      >
                        <option value="">Select Document Type</option>
                        <option value="colored">Colored</option>
                        <option value="non_colored">Non-Colored</option>
                      </select>
                    ) : key === 'packageStatus' ? (
                      <select
                        value={inputValues[key].value}
                        onChange={(e) => handleInputChange(e, key)}
                        className="w-full text-right text-sm text-gray-600"
                        disabled={inputValues[key].disabled}
                      >
                        <option value="">Select Package Status</option>
                        <option value="active">Active</option>
                        <option value="paused">Paused</option>
                        <option value="non_active">Non Active</option>
                      </select>
                    ) : key === 'creationFeeStatus' ? (
                      <select
                        value={inputValues[key].value}
                        onChange={(e) => handleInputChange(e, key)}
                        className="w-full text-right text-sm text-gray-600"
                        disabled={inputValues[key].disabled}
                      >
                        <option value="">Select Creation Fee Status</option>
                        <option value="created">Created</option>
                        <option value="invoiced">Invoiced</option>
                        <option value="paid">Paid</option>
                      </select>
                    ) : (
                      <input
                        type="text"
                        value={inputValues[key].value}
                        onChange={(e) => handleInputChange(e, key)}
                        className={`w-full h-[30px] p-1 text-right border-1 border-custom-green rounded text-sm ${inputValues[key].disabled ? 'bg-gray-100 text-gray-400' : 'text-gray-600'}`}
                        disabled={inputValues[key].disabled}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/** Products and Pack Info. */}
        <div className="flex-1 w-[50%] flex flex-col items-center">
          <div className="w-[100%] flex flex-col items-center">
            <div className="flex overflow-hidden w-full" ref={galleryRef}>
              {cards.map((item, index) => (
                <div className="min-w-full text-center items-center relative" key={index}>
                  {/* Delete Button */}
                  <button 
                    className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded"
                    onClick={() => handleDelete(index)}
                  >
                    Delete
                  </button>
                  {/* Render your item here */}
                  <img className="w-48 h-48 object-cover mx-auto" src={item.imgSrc || ''} alt={item.product.name || ''} />
                  <p className="mt-4 text-custom-blue font-semibold text-center">
                    {item.product.name}
                  </p>
                  <table className="w-full p-4 mt-4 shadow-sm border-1 border-gray-300">
                    <tbody className="font-semibold">
                      <tr className="border-b border-gray-200">
                        <td className="p-3 text-left w-1/2">Quantity per Product</td>
                        <td className="p-3 text-right w-1/2 h-[30px] font-bold">
                          <input
                            type="number"
                            min="1"
                            value={cardInputValues[index]?.quantity_per_product || ''}
                            placeholder={item.item.quantity_per_product}
                            onChange={(e) => handleCardInputChange(e, index, 'quantity_per_product')}
                            className="w-full h-[30px] p-1 text-right border-1 border-custom-green rounded text-sm text-gray-600"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
            </div>

            <div className="flex justify-center">
              <button className="bg-white border-none cursor-pointer text-custom-blue text-2xl p-2 mx-2"
                onClick={scrollLeft}>
                <FaArrowAltCircleLeft />
              </button>
              <button className="bg-white border-none cursor-pointer text-custom-blue text-2xl p-2 mx-2"
                onClick={scrollRight}>
                <FaArrowAltCircleRight />
              </button>
            </div>
          </div>


         {/** Add new Products */}
         {/* Checkbox to toggle hidden div */}
         <div className="w-[100%] mt-2 bg-white shadow-sm border-1 border-gray-300 p-2">
            <label className="flex items-center gap-2 ml-2 underline text-lg text-custom-blue font-bold">
              Add new Products
            </label>
          </div>

          {/* Hidden div */}
            <div className="w-[100%] flex flex-col mt-2 bg-white shadow-sm border-1 border-gray-300 p-4">
               <span className="text-gray-800 font-bold">Select a Product:</span>
               <Select options={productCards} className="mt-4 font-semibold text-gray-600"
                        onChange={handleSelectChange}/>
               
              {selectedProduct && (
              <div className="flex overflow-hidden w-full mt-4">
                <div className="min-w-full text-center items-center relative">
                  {/* Render your item here */}
                  <button 
                          className="absolute top-0 right-0 border-1 rounded cursor-pointer text-white text-sm bg-custom-blue p-2 mx-2"
                           onClick={() =>
                            addNewProduct(
                              selectedProduct.value,
                              cardInputValues[selectedProduct.value.id]?.quantity_per_product || '',
                              cardInputValues[selectedProduct.value.id]?.product_status || ''
                            )
                          }>
                           ADD
                     </button>
                  <img className="w-48 h-48 object-cover mx-auto"  src={imgPaths[selectedProduct.value.assortimed_pid][0]} alt={selectedProduct.label} />
                  <p className="mt-4 text-custom-blue font-bold text-center">
                    {selectedProduct.label}
                  </p>
                  <div className="flex justify-center items-center w-full gap-4 text-custom-blue">
                     <p className="mt-4 font-semibold text-center">
                        {selectedProduct.value.category}
                     </p>
                     <p className="mt-4 font-semibold text-center">
                        {selectedProduct.value.brand}
                     </p>
                     <p className="mt-4 font-semibold text-center">
                        € {selectedProduct.value.sales_price_c1}
                     </p>
                  </div>
                  <table className="w-full p-4 mt-4 shadow-sm border-1 border-gray-300">
                    <tbody className="font-semibold">
                      <tr className="border-b border-gray-200">
                        <td className="p-3 text-left w-1/2">Quantity per Product</td>
                        <td className="p-3 text-right w-1/2 h-[30px] font-bold">
                          <input
                            type="number"
                            min="1"
                            value={cardInputValues[selectedProduct.value.id]?.quantity_per_product || ''}
                            onChange={(e) => handleNewCardInputChange(e, selectedProduct.value.id, 'quantity_per_product')}
                            className="w-full h-[30px] p-1 text-right border-1 border-custom-green rounded text-sm text-gray-600"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            </div>


          <div className="w-[100%] mt-2 bg-white shadow-sm border-1 border-gray-300">
            <table className="w-full p-4">
              <tbody className="font-bold">
                {sidePackInfo.map((info, index) => (
                  <tr key={index}>
                    <td className="p-3 text-left w-1/2">{info.head}</td>
                    { info.type !== 'doc' &&
                    <td>
                        <Select options={info.options}
                                value={info.selectedItem}
                                onChange={info.setSelectedItem} />
                    </td>
                    } 
                    <td className="px-3 py-2 text-center w-1/2 font-bold">
                      <button
                        type="button"
                        className="p-2 border-1 border-custom-blue text-custom-blue rounded hover:bg-custom-blue hover:text-white text-sm"
                        onClick={() => handleButtonClick(info.type)}>
                        {info.value}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Modal
                open={popupVisible}
                onClose={() => setPopupVisible(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box sx={modelStyle}>
                    {popupType === 'user' ? (
                        <UserDetails userRecord={packCreator || null} onClose={onClose} />
                      ) : 
                      popupType === 'com' ? (
                        <CompanyDetails comRecord={packCompany || null} onClose={onClose} />
                      ) : 
                      popupType === 'doc' ?(
                        <FirstDocUpload uploadDoc={getDocData} onClose={onClose} />
                      ) : null}
                  </Box>
              </Modal>
          </div>
        </div>
      </div>

      {/** Save Buttons Section */}
      <div className="w-full items-center flex flex-col justify-center mt-10">
        {(alertMessages.length > 0 ) && (
          <div className="flex text-left flex-col gap-1 mb-10">
            {alertMessages.map((message, index) => (
              <p key={index} className="text-red-500 text-sm font-bold">{message}</p>
            ))}
            {successMessages.map((message, index) => (
              <p key={index} className="text-teal-500 text-sm font-bold">{message}</p>
            ))}
          </div>
        )}
          <div className="flex items-start justify-center mb-4 gap-2"> 
                <button  className=" w-full rounded-md text-red-500 border-2 px-6 py-2 font-semibold hover:bg-red-500 hover:text-white sm:w-auto flex flex-row gap-2 justify-center"
                    onClick={cancelChanges}>
                        <FcCancel className="text-xl"/>
                        <span>Cancel</span>
                  </button>
                <button  
                    className=" w-full rounded-md hover:text-custom-blue text-slate-50 border-2 px-6 py-2 font-semibold bg-custom-blue  hover:bg-white sm:w-auto flex flex-row gap-2 justify-center"
                    onClick={saveChanges}>
                        <FaSave className="text-xl"/>
                        <span>Create Package</span>
                  </button>
          </div>
      </div>
    </div>
  );
}