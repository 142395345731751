import React from "react";



export default function InvoiceSum({ orderRecord, invoiceRecord }) {

  // Items Section
  const pbasket = orderRecord.products? orderRecord.products : [];
  const packbasket = orderRecord.packages? orderRecord.packages : [];
  const dsmbasket = orderRecord.dsms? orderRecord.dsms : [];
  let count = 0;
  //const [pbasket, setpbasket] = useState([]);
  const tableHeads = ['', 'Product name', 'Price per unit', 'Num. of units', 'Total price (excl.)',
                      'Tax per. %', 'Tax amt €', 'Total price (incl.)', ''];


    // Invoice Review - Checkout Model
    const today = new Date();
    const formattedDate = today.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
  

  return (

    <div className="w-full h-full flex flex-col">

      {/** Checkout Summary Section */}
      <div className="w-full h-full flex justify-center mt-2">
          <div className='w-full shadow rounded-lg p-3 '>
              <div className='border-1 border-custom-blue rounded-lg p-4'>
                  <div className='text-custom-blue flex w-full gap-3 border-b-4 border-b-custom-blue'>
                      <div className='w-2/5 border-t-4 border-t-custom-blue flex items-center'>
                        <img className='w-2/3 py-1' src='../../img/Logo.png' alt='logo' /></div>
                      <div className='w-2/5 text-xs border-t-4 border-t-custom-blue'>
                        <p className=' font-bold mb-2'>Name and Address</p>
                        <p className='font-bold'>{orderRecord.company.cname}</p>
                        <p>{invoiceRecord.invoicing_address}</p>
                        <p>{invoiceRecord.budget_holder_phone}</p>
                        <p>{invoiceRecord.PO_number}</p>
                        <p>
                          <span className='font-semibold'>Payment via: </span>
                          {invoiceRecord.bank} - {invoiceRecord.IBAN}</p>
                      </div>
              <div className='w-1/5 text-xs border-t-4 border-t-custom-blue'>
                  <div className='bg-white flex flex-col pb-1'>
                      <p className=' font-bold '>Invoice Number </p>
                      <p className='flex justify-around p-1'>{invoiceRecord.invoice_number}</p>
                    </div>
                  <div className='flex flex-col pb-1'>
                      <p className=' font-bold '>PO Number </p>
                      <p className='flex justify-around p-1'>{invoiceRecord.payment.PO_number}</p>
                  </div>
                  <div className='flex flex-col pb-1 '>
                      <p className=' font-bold border-t-4 border-t-custom-blue'>Date </p>
                      <p className='flex justify-around p-1'>{formattedDate}</p>
                  </div>
                </div>
              </div>
              
              {/** Order Listings Table */}
                <table className="mt-4 mx-auto text-xs text-custom-blue border-collapse border-1 border-custom-blue">
                    <thead>
                      <tr>
                        {tableHeads.slice(0,-1).map((head, index) => (
                          <th key={index} className="border-1 border-white bg-custom-blue text-white text-center p-2">
                            {head}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {pbasket && pbasket.map((item, index) => (
                        <tr key={item.id}>
                        <td className="border-1 border-custom-blue p-2 bg-white text-center">{index + 1}</td>
                        <td className="border-1 border-custom-blue p-2 bg-white text-center">
                          {item.product.name}
                        </td>
                        <td className="border-1 border-custom-blue p-2 bg-white text-center">
                          € {Number(item.product.sales_price_c1).toFixed(2)}
                        </td>
                        <td className="border-1 border-custom-blue p-2 bg-white text-center">
                          {item.quantity ? item.quantity : 2}
                        </td>
                        <td className="border-1 border-custom-blue p-2 bg-white text-center">
                          € {(Number(item.product.sales_price_c1) * (item.quantity ? item.quantity : 2)).toFixed(2)}
                        </td>
                        <td className="border-1 border-custom-blue p-2 bg-white text-center">
                          {((item.tax ? item.tax : 0.14) * 100).toFixed(2)} %
                        </td>
                        <td className="border-1 border-custom-blue p-2 bg-white text-center">
                          € {(Number(item.product.sales_price_c1) * (item.quantity ? item.quantity : 2) * (item.tax ? item.tax : 0.14)).toFixed(2)}
                        </td>
                        <td className="border-1 border-custom-blue p-2 bg-white text-center">
                          € {(Number(item.product.sales_price_c1) * (item.quantity ? item.quantity : 2) * ((item.tax ? item.tax : 0.14) + 1)).toFixed(2)}
                        </td>
                      </tr>
                      ))}
                      {pbasket && (() => {
                        count = pbasket.length + 1;
                        //console.log(`Total items processed: ${count}`);
                        return null;
                      })()}

                      {packbasket && packbasket.map((item, index) => (
                        <tr key={item.id}>
                        <td className="border-1 border-custom-blue p-2 bg-white text-center">{count + index}</td>
                        <td className="border-1 border-custom-blue p-2 bg-white text-center">
                          Creation fee of {item.package.package_name}
                        </td>
                        <td className="border-1 border-custom-blue p-2 bg-white text-center">
                          € {item.package.creation_fee ? Number(item.package.creation_fee).toFixed(2) : "0.00"}
                        </td>
                        <td className="border-1 border-custom-blue p-2 bg-white text-center">
                          {item.quantity ? item.quantity : 1}
                        </td>
                        <td className="border-1 border-custom-blue p-2 bg-white text-center">
                          € {item.record_value_excl ? Number(item.record_value_excl).toFixed(2) : "0.00"}
                        </td>
                        <td className="border-1 border-custom-blue p-2 bg-white text-center">
                        {item.record_value_excl && item.record_value_incl ? 
                            (((Number(item.record_value_incl) - Number(item.record_value_excl)) / Number(item.record_value_excl)) * 100).toFixed(2) : 14.00} %
                        </td>
                        <td className="border-1 border-custom-blue p-2 bg-white text-center">
                          € {(item.record_value_excl && item.record_value_incl) ? ((Number(item.record_value_incl) - Number(item.record_value_excl))).toFixed(2) : 0.00}
                        </td>
                        <td className="border-1 border-custom-blue p-2 bg-white text-center">
                        € {item.record_value_incl ? Number(item.record_value_incl).toFixed(2) : 0.00}
                        </td>
                      </tr>
                      ))}
                      {packbasket && (() => {
                        count = count + packbasket.length + 1;
                        //console.log(`Total items processed: ${count}`);
                        return null;
                      })()}

                      {dsmbasket && dsmbasket.map((item, index) => (
                        <tr key={item.id}>
                        <td className="border-1 border-custom-blue p-2 bg-white text-center">{index + 1}</td>
                        <td className="border-1 border-custom-blue p-2 bg-white text-center">
                          Creation fee of {item.dsm.dsm_name}
                        </td>
                        <td className="border-1 border-custom-blue p-2 bg-white text-center">
                          € {(item.record_value_excl ? Number(item.record_value_excl).toFixed(2) : "0.00")}
                        </td>
                        <td className="border-1 border-custom-blue p-2 bg-white text-center">
                          {item.quantity ? item.quantity : 1}
                        </td>
                        <td className="border-1 border-custom-blue p-2 bg-white text-center">
                          € {(item.record_value_excl * (item.quantity ? item.quantity : 1)).toFixed(2)}
                        </td>
                        <td className="border-1 border-custom-blue p-2 bg-white text-center">
                        {item.record_value_excl && item.record_value_incl ? 
                            (((Number(item.record_value_incl) - Number(item.record_value_excl)) / Number(item.record_value_excl)) * 100).toFixed(2) : 14.00} %
                        </td>
                        <td className="border-1 border-custom-blue p-2 bg-white text-center">
                        € {(item.record_value_excl && item.record_value_incl) ? ((Number(item.record_value_incl) - Number(item.record_value_excl))).toFixed(2) : 0.00}
                        </td>
                        <td className="border-1 border-custom-blue p-2 bg-white text-center">
                          € {(item.record_value_incl ? Number(item.record_value_incl).toFixed(2) : "0.00")}
                        </td>
                      </tr>
                      ))}
              
                      {/** Total Line */}
                      <tr>
                        <td className="border-1 border-white bg-custom-blue text-white p-2 font-semibold text-center" colSpan="4">Total</td>
                        <td className="border-1  border-custom-blue p-2 bg-[#e9edf2] text-center font-bold">
                          € {(invoiceRecord.invoice_total_excl ? Number(invoiceRecord.invoice_total_excl).toFixed(2) : "0.00")}
                        </td>
                        <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">-</td>
                        <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center font-bold">
                          € {(invoiceRecord.invoice_total_excl && invoiceRecord.invoice_total_incl) ? (Number(invoiceRecord.invoice_total_incl) - Number(invoiceRecord.invoice_total_excl)).toFixed(2) : "0.00"}
                        </td>
                        <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center font-bold">
                          € {(invoiceRecord.invoice_total_incl ? Number(invoiceRecord.invoice_total_incl).toFixed(2) : "0.00")}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  

              {/**Summary Table WHite Background */}
              <table className=" mt-4 text-xs text-custom-blue border-collapse border-1 border-custom-blue">
                  <thead>
                    <tr>
                      <th className="border-1 bg-[#e9edf2] border-custom-blue p-2">Order Number</th>
                      <th className="border-1 bg-[#e9edf2] border-custom-blue p-2">Price</th>
                      <th className="border-1 bg-[#e9edf2] border-custom-blue p-2">Contrat Discount</th>
                      <th className="border-1 bg-[#e9edf2] border-custom-blue p-2">Price After Discount</th>
                      <th className="border-1 bg-[#e9edf2] border-custom-blue p-2">Tax</th>
                      <th className="border-1 bg-[#e9edf2] border-custom-blue p-2">Total Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border-1 font-semibold border-custom-blue p-2 bg-white text-center ">{orderRecord.order_number? orderRecord.order_number: "00000000"}</td>
                      <td className="border-1 font-semibold border-custom-blue p-2 bg-white text-center">
                      € {(invoiceRecord.invoice_total_excl ? Number(invoiceRecord.invoice_total_excl).toFixed(2) : "0.00")}</td>
                      <td className="border-1 font-semibold border-custom-blue p-2 bg-white text-center">{orderRecord.discount_id? orderRecord.discount_id: 0} %</td>
                      <td className="border-1 font-semibold border-custom-blue p-2 bg-white text-center">
                      € {(() => {
                      const totalPrice = (invoiceRecord.invoice_total_excl ? Number(invoiceRecord.invoice_total_excl).toFixed(2) : 0.00);
                      const discountPercentage = orderRecord.discount_id ? orderRecord.discount_id : 0;
                      const discountAmount = totalPrice * (discountPercentage / 100);
                      return (totalPrice - discountAmount).toFixed(2);
                    })()}
                </td>
                      <td className="border-1 font-semibold border-custom-blue p-2 bg-white text-center"> 
                      € {(invoiceRecord.invoice_total_excl && invoiceRecord.invoice_total_incl) ? (Number(invoiceRecord.invoice_total_incl) - Number(invoiceRecord.invoice_total_excl)).toFixed(2) : 0.14}</td>
                      <td className="border-1 font-semibold border-custom-blue p-2 bg-white text-center">
                      € {(invoiceRecord.invoice_total_incl ? Number(invoiceRecord.invoice_total_incl).toFixed(2) : 0.00)}
                      </td>
                    </tr>
                  </tbody>
              </table>
                                
              <p className='font-bold text-custom-blue py-5 flex justify-end items-center gap-3'>
                  TOTAL AMOUNT TO PAY 
                  <span className='border-2 border-custom-blue py-1 px-4'>
                  € {(invoiceRecord.invoice_total_incl ? Number(invoiceRecord.invoice_total_incl).toFixed(2) : 0.00)}
                  </span></p>
            </div>
          </div>
      </div> 
    
    </div>
  );
}