import React, { useRef, useState } from "react";
import { Modal, Box } from '@mui/material';

// Local imports
import './Gallery.css';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import UserDetails from "../summary/UserSum";
import CompanyDetails from "../summary/ComSum";
import { ImCross } from "react-icons/im";

export default function DisplayDSM({dsmRecord, onDataReturn}) {
   const storedProducts = JSON.parse(localStorage.getItem('products'));
   const imgPaths = JSON.parse(localStorage.getItem('imgPaths'));
   const packItems = dsmRecord?.products || [];

   const getProductById = (id) => {
      const product = storedProducts.find(product => product.id === id);
      return product;
   }

   const getProductNameById = (id) => {
      const product = getProductById(id);
      return product.product_name;
   }

   const getProductPIDById = (id) => {
      const product = getProductById(id);
      return product.assortimed_pid;
   }

   const cards = packItems.map((product, index) => ({
      item: product,
      product: getProductById(product.product),
      imgSrc: imgPaths[getProductPIDById(product.product)][0]
    }));

   // Package Items Gallery
   const galleryRef = useRef(null);

   const scrollLeft = () => {
     galleryRef.current.scrollBy({ left: -200, behavior: 'smooth' });
   };
 
   const scrollRight = () => {
     galleryRef.current.scrollBy({ left: 200, behavior: 'smooth' });
   };

   // Package Info.
   const dsmInfo = [
      { head: 'DSM ID', value: dsmRecord?.id || '--' },
      { head: 'DSM Dept.', value: dsmRecord?.department || '--' },
      {
        head: 'DSM Status',
        value: dsmRecord?.dsm_status
          ? dsmRecord.dsm_status.charAt(0).toUpperCase() + dsmRecord.dsm_status.slice(1)
          : '--'
      },
      {
        head: 'DSM Status Date',
        value: dsmRecord?.dsm_status
          ? (dsmRecord.dsm_status === 'active'
            ? dsmRecord.dsm_active_date
            : (dsmRecord.dsm_status === 'paused'
              ? dsmRecord.dsm_paused_date
              : dsmRecord.dsm_non_active_date)
          )?.slice(0, 10)
          : '--'
      },
      { head: 'Num of Products', value: dsmRecord?.num_of_products ? dsmRecord.num_of_products + ' products' : '--' },
      { head: 'Creation Fee', value: dsmRecord?.creation_fee ? '€ ' + dsmRecord.creation_fee : '--' },
      { head: 'Creation Fee Status', value: dsmRecord?.creation_fee_status || '--' },
      { head: 'Creation Date', value: dsmRecord?.creation_date?.slice(0, 10) || '--' },
      { head: 'Refill Period', value: dsmRecord?.refill_period || '--'},
      { head: 'Refill Date', value: dsmRecord?.refill_date?.slice(0, 10) || '--'},
      { head: 'Refill Proposal Status', value: dsmRecord?.refill_proposal_status ||'--' },
      { head: 'Monthlu DSM Fee', value: dsmRecord?.monthly_fee_dsm ? '€ ' + dsmRecord.monthly_fee_dsm : '--' },
    ];

   
   
   // Info Popoups
   const [popupVisible, setPopupVisible] = useState(false);
   const [popupType, setPopupType] = useState(null);

   const handleButtonClick = (type) => {
      setPopupType(type);
      setPopupVisible(true);
    };

   const sideDSMInfo = [
      {head: 'DSM Comapny', value: dsmRecord?.company?.cname, type: 'com'},
      {head: 'DSM Creator', value: dsmRecord?.dsm_creator?.fullname, type: 'user'},
   ];

   const modelStyle = {
      position: 'absolute',
      top: '25vh',
      left: '50%',
      transform: 'translate(-50%, 0)',
      width: '50%',
      maxHeight: '50vh',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      overflowY: 'auto',
      rounded: 'full',
    };

   const onClose = () => {
      setPopupVisible(false);
      setPopupType(null);
   }

   return (
      <div className="w-full mb-8">
         <h1 className="text-center text-lg font-bold text-custom-blue">
            <span className="text-gray-500 mr-4">DSM Name:</span> 
            {dsmRecord?.dsm_name || 'Choose a DSM!'}</h1>
         <div className="w-full flex flex-row gap-4 mt-8">
            {/** Package Information */}
            <div className="w-[50%] bg-white shadow-sm border-1 border-gray-300">
               <table className="w-full p-4">
                  <tbody className="font-semibold">
                     {dsmInfo.map((info, index) => (
                     <tr className="border-b border-gray-200">
                        <td className="p-3 text-left w-1/2">{info.head}</td>
                        <td className="p-3 text-right w-1/2 font-bold">{info.value}</td>
                     </tr>
                     ))}
                  </tbody>
               </table>
            </div>

            {/** Pakage Items */}
            <div className="w-[50%] flex flex-col items-center">
               <div className="w-[100%] flex flex-col items-center">
                  <div className="flex overflow-hidden w-full" ref={galleryRef}>
                     {cards.map((item, index) => (
                     <div className="min-w-full text-center items-center" key={index}>
                        {/* Render your item here */}
                        <img className="w-48 h-48 object-cover mx-auto" src={item.imgSrc || ''} alt={item.product.name || ''} />
                        <p className="mt-4 text-custom-blue font-semibold text-center">
                           {item.product.name}</p>
                        <table className="w-full p-4 mt-4 shadow-sm border-1 border-gray-300">
                           <tbody className="font-semibold">
                              <tr className="border-b border-gray-200">
                                 <td className="p-3 text-left w-1/2">Lower Stock Limit</td>
                                 <td className="p-3 text-right w-1/2 font-bold">{item.item.lower_spec_stock + ' items' || ''}</td>
                              </tr>
                              <tr className="border-b border-gray-200">
                                 <td className="p-3 text-left w-1/2">Upper Stock Limit</td>
                                 <td className="p-3 text-right w-1/2 font-bold">{item.item.upper_spec_stock + ' items' || ''}</td>
                              </tr>
                              <tr className="border-b border-gray-200">
                                 <td className="p-3 text-left w-1/2">Product Status</td>
                                 <td className="p-3 text-right w-1/2 font-bold">{item.item.product_status?.charAt(0).toUpperCase() + item.item.product_status.slice(1) || ''}</td>
                              </tr>
                              <tr className="border-b border-gray-200">
                                 <td className="p-3 text-left w-1/2">Product Status Date</td>
                                 <td className="p-3 text-right w-1/2 font-bold">
                                 {item.item.product_status === 'active'? (
                                    item.item.product_active_date?.slice(0, 10)
                                 ) : item.item.product_status === 'paused' ? (
                                    item.item.product_paused_date?.slice(0, 10)
                                 ) : item.item.product_non_active_date?.slice(0, 10) || ''}
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                     ))}
                  </div>
                  <div className="flex justify-center">
                     <button className="bg-white border-none cursor-pointer text-custom-blue text-2xl p-2 mx-2" 
                              onClick={scrollLeft}>
                        <FaArrowAltCircleLeft />
                     </button>
                     <button className="bg-white border-none cursor-pointer text-custom-blue text-2xl p-2 mx-2" 
                              onClick={scrollRight}>
                        <FaArrowAltCircleRight />
                     </button>
                  </div>
               </div>

               {/** Package Clickables */}
               <div className="w-[100%] mt-2 bg-white shadow-sm border-1 border-gray-300">
                  <table className="w-full p-4">
                     <tbody className="font-bold">
                        {sideDSMInfo.map((info, index) => (
                        <tr>
                           <td className="p-3 text-left w-1/2">{info.head}</td>
                           <td className="px-3 py-2 text-center w-1/2 font-bold">
                              <button className="p-2 border-1 border-custom-blue text-custom-blue rounded hover:bg-custom-blue hover:text-white"
                                       onClick={() => handleButtonClick(info.type)}>
                                 {info.value}
                              </button>
                            </td>
                        </tr>
                        ))}
                     </tbody>
                  </table>
                  <Modal
                     open={popupVisible && (popupType === 'user' || popupType === 'com')}
                     onClose={() => setPopupVisible(false)}
                     aria-labelledby="modal-title"
                     aria-describedby="modal-description"
                     >
                     <Box sx={modelStyle}>
                        {popupType === 'user' ? (
                           <UserDetails userRecord={dsmRecord?.dsm_creator || null} onClose={onClose} />
                        ) : 
                        popupType === 'com' ? (
                           <CompanyDetails comRecord={dsmRecord?.company || null} onClose={onClose} />
                        ) : null}
                     </Box>
                  </Modal>
               </div>
            </div>

         </div>
      </div>
   );
}