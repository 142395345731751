import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";


// Icon Imports
import { MdFormatListBulleted } from "react-icons/md";
import { MdFormatListBulletedAdd } from "react-icons/md";
import { MdOutlinePlaylistPlay } from "react-icons/md";
import { FcCancel } from "react-icons/fc";
import { FaEdit, FaSave } from "react-icons/fa";


// Local Imports
import NewProductItem from "./newProductItem";



export default function ProductItem() {
    // Get all products
    const products = JSON.parse(localStorage.getItem("products"));


    // 3 Tabs for products
    const [productId, setProductId] = useState(null);
    const [productRecord, setProductRecord] = useState(null);
    const productOptions = products.map(product => ({
        value: product.id,
        label: product.name,
    }));
    useEffect(() => {
        if (productId && products) {
            const selectedDsm = products.find(dsm => dsm.id === productId.value);
            if (selectedDsm) { 
                setProductRecord(selectedDsm);
            }
        }
    }, [productId]);
    // console.log("Companies List Options:   " + JSON.stringify(dsmRecord));


    // Navigate Different Company Choices
    const [display, setDisplay] = useState(true);
    const [edit, setEdit] = useState(false);
    const [newProduct, setNewProduct] = useState(false);

    const displayInfo = () => {
        setDisplay(true);
        setEdit(false);
        setNewProduct(false);
    };
    const editInfo = () => {
        setEdit(true);
        setDisplay(false);
        setNewProduct(false);
    };
    const newInfo = () => {
        setNewProduct(true);
        setDisplay(false);
        setEdit(false);
    };

    const buttons = [
        {id: 1,icon: <MdFormatListBulleted className="text-lg mt-1" />, label: "Display Product", func: displayInfo, con: display},
        {id: 2,icon: <MdOutlinePlaylistPlay className="text-lg mt-1" />, label: "Edit Product", func: editInfo, con: edit},
        {id: 3,icon: <MdFormatListBulletedAdd className="text-lg mt-1" />, label: "New Product(s)", func: newInfo, con: newProduct},
    ];

    // Edit Action Buttons
    const saveChanges = () => {
        console.log("Save Changes");
    };

    const cancelChanges = () => {
        setDisplay(false);
        setEdit(false);
        setNewProduct(false);
        setProductRecord(null);
        setProductId(null);
    };

    const [resultFromChild, setResultFromChild] = useState(null);
    const handleDataReturn = (data) => {
        setResultFromChild(data);
    };


  return (
    <div className="flex flex-col justify-center items-center">

            <div className="flex flex-row gap-4 justify-center w-[70%] mb-4">
                {buttons.map((button) => (
                <div key={button.id} className="flex items-end md:mb-0 md:w-auto">
                    <button onClick={button.func} 
                            className={`w-full rounded-md text-custom-blue border-2
                                        px-10 py-2 font-semibold ${button.con ? 'bg-custom-blue text-white' : 'hover:bg-custom-blue hover:text-white'}
                                        sm:w-auto flex flex-row gap-2 justify-center`}>
                    {button.icon}
                    <span className="text-lg">{button.label}</span>
                    </button>
                </div>
                ))}
            </div>

            
            { display || edit || newProduct ?
            <div className="w-[90%] border-1 border-custom-blue p-4 rounded-lg">
                { display || edit ?
                <div className="flex flex-row justify-center w-[90%]">
                    <div className="flex flex-row justify-center w-[70%]">
                        <label className="text-lg uppercase font-sans font-bold text-gray-700 mt-1">Products</label>
                        <div className="w-[50%] ml-8 text-gray-600 font-bold mb-4">
                            <Select options={productOptions}
                                    value={productId}
                                    onChange={setProductId}/>
                        </div>
                    </div>
                </div> : null}


                {display?
                    // <Product1 dsmRecord={dsmRecord} onDataReturn={handleDataReturn} />
                    <></>
                : null}


                {edit?
                    // <editDSM dsmRecord={dsmRecord} onDataReturn={handleDataReturn} />
                    <></>
                : null}

                {newProduct?
                    <NewProductItem productRecord={productRecord} onDataReturn={handleDataReturn} />
                : null}


                { edit ?
                <div className="flex items-start justify-center mb-4 gap-2"> 
                        <button  className=" w-full rounded-md text-red-500 border-2 px-6 py-2 font-semibold hover:bg-red-500 hover:text-white sm:w-auto flex flex-row gap-2 justify-center"
                                onClick={cancelChanges}>
                                <FcCancel className="text-xl"/>
                                <span>Cancel Changes</span>
                        </button>
                        <button  
                                className=" w-full rounded-md hover:text-custom-blue text-slate-50 border-2 px-6 py-2 font-semibold bg-custom-blue  hover:bg-white sm:w-auto flex flex-row gap-2 justify-center">
                                <FaSave className="text-xl"/>
                                <span>Save Changes</span>
                        </button>
                </div> : null}

                { display ?
                <div className="flex items-start justify-center mb-4 gap-2"> 
                        <button  className=" w-full rounded-md text-red-500 border-2 px-12 py-2 font-semibold hover:bg-red-500 hover:text-white sm:w-auto flex flex-row gap-2 justify-center"
                                onClick={cancelChanges}>
                                <FcCancel className="text-xl"/>
                                <span>Cancel</span>
                        </button>
                        <button  className=" w-full rounded-md hover:text-custom-blue text-slate-50 border-2 px-6 py-2 font-semibold bg-custom-blue  hover:bg-white sm:w-auto flex flex-row gap-2 justify-center"
                                onClick={editInfo}>
                                <FaEdit className="text-xl"/>
                                <span>Edit Product</span>
                        </button>
                </div> : null}
            </div> : null}
        </div>
  );
}