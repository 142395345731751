import React, { useState } from 'react';
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
import { useFormik } from "formik";
import axios from 'axios';
import { useTranslation } from 'react-i18next';

// Validation Schemes
import * as Yup from 'yup';

// Local Imports
import '../creationPopupStyle.css';
import '../../components/Sign/Login.css';


// Icon Imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { BsFillEyeFill } from 'react-icons/bs';






const personalInfoValidationSchema = Yup.object().shape({
  password: Yup.string().min(8, 'Password is too short - should be 8 chars minimum.').required('Password is required'),
  passwordconfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Password confirm is required'),
});



function PopupPass({ onClose }) {

    // Data from Local Storage
    const user = JSON.parse(localStorage.getItem('user'));
    const userRole = user.user_data.role === 'HD' ? 'Head User' : user.role === 'M' ? 'Manager User' : 'Normal User';
    const accessToken = localStorage.getItem('accessToken');
    const [errorMessage, setErrorMessage] = useState(null);
    const [success, setSuccess] = useState(false);
    const { t } = useTranslation();
    // console.log("accessToken");
    // console.log(accessToken);
  
  const formik = useFormik({
    initialValues: {
      old_password: '',
      password: '',
      passwordconfirm: '',
    },
    validationSchema: personalInfoValidationSchema,
    onSubmit: async values => {
      if (values.password !== values.passwordconfirm) {
        setErrorMessage(t('signprocess.resetperror1'));
        return;
      }
      const resetData = {
        uid: user.user_data.id,
        old_password: values.old_password,
        password: values.password
      }
      console.log("Request Data: ");
      console.log(resetData);

      // Check if any of the required fields are null or undefined
      if (!resetData.uid || !resetData.old_password || !resetData.password) {
          setErrorMessage("You need to enter all values.");
          return;
      }


      try {
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/users/user_reset_password/`, resetData)
        if (response.status === 200 || response.status === 201) {
                //console.log(response.data);
                //console.log(response.status);
                //console.log(resetData);
                setErrorMessage(null);
                setSuccess(true);
          } else {
                setSuccess(false);
                setErrorMessage("Old password is incorrect.");
          }
        }
        catch (error) {
             setSuccess(false);
            setErrorMessage('Password reset failed, Try it again.');  
        };
    },
  });


  // Handling Password visibility
  const [inputType, setInputType] = useState("password");
  const handleClickShowPassword = () => {
    setInputType(inputType === "password" ? "text" : "password");
  };

  return (
    <div className="creation_popup">
      <Modal show={true} onHide={onClose}>
        <Modal.Header className="flex-row-reverse bg-neutral-50">
        <button
            className="btn text-white bg-custom-green hover:bg-[#07b1ab] border-none"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
          { !success ?
          <h2 className="text-2xl m-auto font-sans font-semibold">
            Change your Password
          </h2>
          : null}
        </Modal.Header>
        <Modal.Body>
          {errorMessage? <div className="text-center text-red-400 font-semibold">{errorMessage}
          </div> : null}
          { !success ?  
        <Form onSubmit={formik.handleSubmit} className="bg-white">
          <InputGroup className="form-group mb-3">
              <FormControl
                type={inputType}
                name="old_password"
                placeholder='Type old Password'
                className="form-control inputArea"
                value={formik.values.old_password}
                onChange={formik.handleChange}
              />
              <Button 
                variant="outline-secondary" 
                onClick={handleClickShowPassword} 
                className="inputArea button-no-border"
                type="button"
              >
                <BsFillEyeFill />
              </Button>
            </InputGroup>

          <InputGroup className="form-group mb-3">
            <FormControl
              type={inputType}
              name="password"
              placeholder='Type new Password'
              className="form-control inputArea"
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            <Button 
              variant="outline-secondary" 
              onClick={handleClickShowPassword} 
              className="inputArea button-no-border"
              type="button"
            >
              <BsFillEyeFill />
            </Button>
          </InputGroup>
          {formik.errors.password && <div style={{color: 'red'}}>{formik.errors.password}</div>}

          <InputGroup className="form-group mb-3">
            <FormControl
              type={inputType}
              name="passwordconfirm"
              placeholder='Confirm new Password'
              className="form-control inputArea"
              value={formik.values.passwordconfirm}
              onChange={formik.handleChange}
            />
            <Button 
              variant="outline-secondary" 
              onClick={handleClickShowPassword} 
              className="inputArea button-no-border"
              type="button"
            >
              <BsFillEyeFill />
            </Button>
          </InputGroup>
          {formik.errors.passwordconfirm && <div style={{color:'red'}}>{formik.errors.passwordconfirm}</div>}

          <Button
            variant="primary"
            type="submit"
            className="mx-auto my-3 block cursor-pointer border-0 bg-custom-blue px-[100px] py-[10px] text-sm font-semibold"
          >
            Change Password
          </Button>
        </Form>
        : 
        <div className="text-center text-xl text-green-500 font-semibold">
          <span>Password Changed Successfully!</span>
          <Button
            variant="primary"
            className="mx-auto my-3 block cursor-pointer border-0 bg-custom-blue px-[50px] py-[10px] text-sm font-semibold"
            onClick={onClose}
          >
            OK
          </Button>
          </div> 
        }
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PopupPass;
