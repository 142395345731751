import React, { useRef, useState } from "react";
import { Modal, Box } from '@mui/material';

// Local imports
import './Gallery.css';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import UserDetails from "../summary/UserSum";
import CompanyDetails from "../summary/ComSum";
import DocUpload from "./DocUpload";
import { ImCross } from "react-icons/im";

export default function DisplayPack({packRecord, onDataReturn}) {
   const storedProducts = JSON.parse(localStorage.getItem('products'));
   const imgPaths = JSON.parse(localStorage.getItem('imgPaths'));
   const packItems = packRecord?.products || [];

   const getProductById = (id) => {
      const product = storedProducts.find(product => product.id === id);
      return product;
   }

   const getProductNameById = (id) => {
      const product = getProductById(id);
      return product.product_name;
   }

   const getProductPIDById = (id) => {
      const product = getProductById(id);
      return product.assortimed_pid;
   }

   const cards = packItems.map((product, index) => ({
      item: product,
      product: getProductById(product.product),
      imgSrc: imgPaths[getProductPIDById(product.product)][0]
    }));

   // Package Items Gallery
   const galleryRef = useRef(null);

   const scrollLeft = () => {
     galleryRef.current.scrollBy({ left: -200, behavior: 'smooth' });
   };
 
   const scrollRight = () => {
     galleryRef.current.scrollBy({ left: 200, behavior: 'smooth' });
   };

   // Package Info.
   const packInfo = [
      { head: 'Package ID', value: packRecord?.id || '' },
      { head: 'Package Dept.', value: packRecord?.department || '' },
      {
        head: 'Package Status',
        value: packRecord?.package_status
          ? packRecord.package_status.charAt(0).toUpperCase() + packRecord.package_status.slice(1)
          : ''
      },
      {
        head: 'Package Status Date',
        value: packRecord?.package_status
          ? (packRecord.package_status === 'active'
            ? packRecord.active_date
            : (packRecord.package_status === 'paused'
              ? packRecord.paused_date
              : packRecord.non_active_date)
          )?.slice(0, 10)
          : ''
      },
      { head: 'Num of Products', value: packRecord?.num_of_products ? packRecord.num_of_products + ' products' : '' },
      { head: 'Package Doc. Type', value: packRecord?.doc_type || '' },
      { head: 'Package Doc. Price', value: packRecord?.doc_price ? '€ ' + packRecord.doc_price : '' },
      { head: 'Creation Fee', value: packRecord?.creation_fee ? '€ ' + packRecord.creation_fee : '' },
      { head: 'Creation Fee Status', value: packRecord?.creation_fee_status || '' },
      { head: 'Creation Date', value: packRecord?.creation_date?.slice(0, 10) || '' },
      { head: 'Total Package Price', value: packRecord?.total_pack_price ? '€ ' + packRecord.total_pack_price : '' },
    ];

   
   
   // Info Popoups
   const [popupVisible, setPopupVisible] = useState(false);
   const [popupType, setPopupType] = useState(null);

   const handleButtonClick = (type) => {
      setPopupType(type);
      setPopupVisible(true);
      // setTimeout(() => {
      //   setPopupVisible(false);
      //   setPopupType(null);
      // }, 10000); 
    };

   const sidePackInfo = [
      {head: 'Package Creator', value: packRecord?.pack_creator?.fullname, type: 'user'},
      {head: 'Package Comapny', value: packRecord?.company?.cname, type: 'com'},
      {head: 'Package Doc.', value: 'Open Doc.', type: 'doc'},
   ];

   const modelStyle = {
      position: 'absolute',
      top: '25vh',
      left: '50%',
      transform: 'translate(-50%, 0)',
      width: '50%',
      maxHeight: '50vh',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      overflowY: 'auto',
      rounded: 'full',
    };

   const DocURL = packRecord?.doc_type ? `../../img/database_media/pdfs/packages/pack_${packRecord?.id}.pdf` : '../../img/database_media/pdfs/packages/default.pdf';

   const onClose = () => {
      setPopupVisible(false);
      setPopupType(null);
   }

   return (
      <div className="w-full mb-8">
         <h1 className="text-center text-lg font-bold text-custom-blue">
            <span className="text-gray-500 mr-4">Package Name:</span> 
            {packRecord?.package_name || 'Choose a Package!'}</h1>
         <div className="w-full flex flex-row gap-4 mt-8">
            {/** Package Information */}
            <div className="w-[50%] bg-white shadow-sm border-1 border-gray-300">
               <table className="w-full p-4">
                  <tbody className="font-semibold">
                     {packInfo.map((info, index) => (
                     <tr className="border-b border-gray-200">
                        <td className="p-3 text-left w-1/2">{info.head}</td>
                        <td className="p-3 text-right w-1/2 font-bold">{info.value}</td>
                     </tr>
                     ))}
                  </tbody>
               </table>
            </div>

            {/** Pakage Items */}
            <div className="w-[50%] flex flex-col items-center">
               <div className="w-[100%] flex flex-col items-center">
                  <div className="flex overflow-hidden w-full" ref={galleryRef}>
                     {cards.map((item, index) => (
                     <div className="min-w-full text-center items-center" key={index}>
                        {/* Render your item here */}
                        <img className="w-48 h-48 object-cover mx-auto" src={item.imgSrc || ''} alt={item.product.name || ''} />
                        <p className="mt-4 text-custom-blue font-semibold text-center">
                           {item.product.name}</p>
                        <table className="w-full p-4 mt-4 shadow-sm border-1 border-gray-300">
                           <tbody className="font-semibold">
                              <tr className="border-b border-gray-200">
                                 <td className="p-3 text-left w-1/2">Quantity per Product</td>
                                 <td className="p-3 text-right w-1/2 font-bold">{item.item.quantity_per_product + ' items' || ''}</td>
                              </tr>
                              <tr className="border-b border-gray-200">
                                 <td className="p-3 text-left w-1/2">Product Status</td>
                                 <td className="p-3 text-right w-1/2 font-bold">{item.item.product_status?.charAt(0).toUpperCase() + item.item.product_status.slice(1) || ''}</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                     ))}
                  </div>
                  <div className="flex justify-center">
                     <button className="bg-white border-none cursor-pointer text-custom-blue text-2xl p-2 mx-2" 
                              onClick={scrollLeft}>
                        <FaArrowAltCircleLeft />
                     </button>
                     <button className="bg-white border-none cursor-pointer text-custom-blue text-2xl p-2 mx-2" 
                              onClick={scrollRight}>
                        <FaArrowAltCircleRight />
                     </button>
                  </div>
               </div>

               {/** Package Clickables */}
               <div className="w-[100%] mt-2 bg-white shadow-sm border-1 border-gray-300">
                  <table className="w-full p-4">
                     <tbody className="font-bold">
                        {sidePackInfo.map((info, index) => (
                        <tr>
                           <td className="p-3 text-left w-1/2">{info.head}</td>
                           <td className="px-3 py-2 text-center w-1/2 font-bold">
                              <button className="p-2 border-1 border-custom-blue text-custom-blue rounded hover:bg-custom-blue hover:text-white"
                                       onClick={() => handleButtonClick(info.type)}>
                                 {info.value}
                              </button>
                            </td>
                        </tr>
                        ))}
                     </tbody>
                  </table>
                  <Modal
                     open={popupVisible && (popupType === 'user' || popupType === 'com')}
                     onClose={() => setPopupVisible(false)}
                     aria-labelledby="modal-title"
                     aria-describedby="modal-description"
                     >
                     <Box sx={modelStyle}>
                        {popupType === 'user' ? (
                           <UserDetails userRecord={packRecord?.pack_creator || null} onClose={onClose} />
                        ) : 
                        popupType === 'com' ? (
                           <CompanyDetails comRecord={packRecord?.company || null} onClose={onClose} />
                        ) : null}
                     </Box>
                  </Modal>
                  
                  {/** Display Description Docment */}
                  <Modal
                     open={Boolean(popupVisible && popupType === 'doc')}
                     onClose={onClose}
                     aria-labelledby="modal-title"
                     aria-describedby="modal-description"
                     style={{
                        top: '10%',
                        left: '50%',
                        width: '80%',
                        maxHeight: '75vh',
                        transform: 'translate(-50%, 0)',
                        backgroundColor: 'white',
                        boxShadow: 24,
                        padding: '16px',
                        overflowY: 'auto',
                        borderRadius: '8px',
                      }}>
                     <div>
                     <button
                        onClick={onClose}
                        className="text-lg font-bold  text-red-500"
                     >
                        <ImCross />
                     </button>
                     <iframe 
                     src={DocURL} 
                     width="100%" height="500px" />
                     </div>
                  </Modal>
               </div>
            </div>

         </div>
      </div>
   );
}