import React from "react";
import { FaWindowClose } from "react-icons/fa";



export default function UserDetails({userRecord, onClose})  {

    console.log("Inside User Details!!");
    console.log(userRecord);
    
    const sanitizedEmail = userRecord?.email.replace('@', '');
    const profileImgURL = userRecord?.profile_picture ? `./img/database_media/users/${sanitizedEmail}.jpg` : "./img/database_media/users/default.jpg";
    
    const userData = [
        {head: "User ID", value: userRecord?.id || 'NA'},
        {head: "Full name", value: userRecord?.fullname || 'NA'},
        {head: "Email", value: userRecord?.email || 'NA'},
        {head: "Phone", value: userRecord?.uphone || 'NA'},
        {head: "Preferred Language", value: 
            (userRecord?.language_preference && 
                userRecord?.language_preference === 'de') ? "Dutch"
            : (userRecord?.language_preference === 'en' ? "English"
                : (userRecord?.language_preference === 'de' ? "German": "NA")
            )},
        {head: "Joined date", value: userRecord?.date_joined || 'NA'},
        {head: "Last updated", value: userRecord?.updated_at || 'NA'},
        {head: "User Type", value: (userRecord?.role  && 
            userRecord.role === 'NM' ? "Normal User"
            : (userRecord?.role === 'HD' ? "Head User"
                : (userRecord?.role === 'MG' ? "Manager User" : "NA")
            )
        )},
        {head: "Department", value: userRecord?.department_name || 'NA'},
        {head: "Department Code", value: userRecord?.department_code || 'NA'},
        {head: "Function", value: userRecord?.function || 'NA'},
        {head: "Budget", value: ('€ ' + userRecord?.budget) || 'NA'},
    ];

    const handleClose = () => {
        onClose();
    };

    return (
        <div name="userdata">
            <div className="w-full flex justify-center items-center">
                <img
                className="h-25 w-25 rounded-full"
                src={profileImgURL}
                alt="Profile"
                />
                <button
                    className="absolute top-4 right-2 text-red-500 rounded-full p-1"
                    onClick={handleClose}
                >
                    <FaWindowClose className="text-4xl"/>
                </button>
            </div>
            <div className="border-1 border-gray-400 w-full h-full mt-4">
                <table>
                    <tbody>
                    {userData.map((info, index) => (
                     <tr className="border-b border-gray-200">
                        <td className="p-3 text-left w-1/2">{info.head}</td>
                        <td className="p-3 text-right w-1/2 font-bold">{info.value}</td>
                     </tr>
                     ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}