import React, { useState } from "react";


// Icon Imports
import { BiSolidReport } from "react-icons/bi";

// In ComBase component
export default function UserSum({ userRecord }) {
  const userRole = userRecord?.role ? 
      (userRecord.role === 'HD' ? 'Head User' : 
      (userRecord.role === 'MN' ? 'Manager User' : 
      'Normal User')) 
      : 'No Role';
  
    return (
        userRecord ?
            <div className="w-full mt-3 border-1 border-custom-green px-3 py-1 rounded-lg h-fit shadow-custom font-sans">
              <div className="my-3">
                <div className="my-1 mb-2 text-xl text-custom-blue font-extrabold flex justify-center">
                  <span>{userRecord.fullname}</span>
                </div>
                <div className="grid grid-cols-2 gap-5 my-1 mb-2 font-semibold">
                  <p className="text-custom-blue">System Role</p>
                  <span>{userRole}</span>
                </div>
                <div className="my-1 mt-3 text-lg text-teal-600 font-bold flex justify-center">
                  <p>Contract Info.</p>
                </div>
                <div className="grid grid-cols-2 gap-5 my-1 font-semibold">
                  <span className="text-custom-blue">Business email</span>
                </div>
                <div className="grid grid-cols-2 gap-5 my-1 mb-2 font-semibold">
                  <span>{userRecord.email}</span>
                </div>
                <div className="grid grid-cols-2 gap-5 my-1 font-semibold">
                  <span className="text-custom-blue">Mobile</span>
                </div>
                <div className="grid grid-cols-2 gap-5 my-1 mb-2 font-semibold">
                  <span>{userRecord.uphone}</span>
                </div>

                <div className="my-1 mt-3 text-lg text-teal-600 font-bold flex justify-center">
                  <p>Profession Info.</p>
                </div>

                
                <div className="grid grid-cols-2 gap-5 my-1 font-semibold">
                  <span className="text-custom-blue">Job Title</span>
                  <span className="text-custom-blue">Budget</span>
                </div>
                <div className="grid grid-cols-2 gap-5 my-1 mb-2 font-semibold">
                  <span>{userRecord.function}</span>
                  <span>€ {userRecord.budget}</span>
                </div>

                <div className="grid grid-cols-2 gap-5 my-1 font-semibold">
                  <span className="text-custom-blue">Department</span>
                  <span className="text-custom-blue">Depart. Code</span>
                </div>
                <div className="grid grid-cols-2 gap-5 my-1 mb-2 font-semibold">
                  <span>{userRecord.department_name}</span>
                  <span>{userRecord.department_code}</span>
                </div>

                

              </div>
          </div> : null 
    );
  }