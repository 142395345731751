import React, {useEffect, useState} from "react";
import axios from "axios";
import Select from "react-select";

// Icon Imports
import { FaBuilding, FaCirclePlus, FaDownload, FaUserPlus } from "react-icons/fa6";
import { BsBuildingFillGear } from "react-icons/bs";
import { FaEdit, FaSave, FaUserAlt, FaUserEdit } from "react-icons/fa";
import { FcCancel } from "react-icons/fc";


// Local Imports
import EditCom from "../company/EditCom";
import NewCom from "../company/NewCom";
import DisplayCom from "../company/DisplayCom";
import DisplayUser from "./DisplayUser";


export default function UserBase() {
    // reading user Data
    const user = JSON.parse(localStorage.getItem('user'));

    // options for Company & User
    const [comlist, setComlist] = useState([]);
    const [companyId, setCompanyId] = useState(null);
    const [comRecord, setComRecord] = useState(null);
    const [userlist, setUserlist] = useState([]);
    const [userId, setUserId] = useState(null);
    const [userRecord, setUserRecord] = useState(null);
    const [userOptionsList, setUserOptionsList] = useState([]);

    const getCompanies = async () => {
        try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/admin/comlist/`);
        setComlist(response.data);
        } catch (error) {
        console.error(`Error retrieving companies: ${error}`);
        }
    };
    useEffect(() => {
        getCompanies();
    }, []);
    //console.log("Companies:   " + comlist);
    const Company = comlist.map(com => ({
        value: com.id,
        label: com.cname
    }));
    useEffect(() => {
        if (companyId && comlist) { // Ensure both companyId and comList are not null
            const selectedCompany = comlist.find(com => com.id === companyId.value);
            if (selectedCompany) { // Ensure selectedCompany is found
                setComRecord(selectedCompany);
            }
        }
    }, [companyId]);


    useEffect(() => {
        //console.log("companyID: " + companyId?.value);
        if (companyId) {
          // Update user list
          const newUserlist = user.company_users
          .filter(user => user.company === companyId.value);
        const userOptions = newUserlist.map(user => ({
          value: user.id,
          label: user.fullname
        }));
        setUserOptionsList(userOptions);
        setUserlist(newUserlist);
    
          // Additional logic for setting pay record from company list
          const company = comlist.find(com => com.id === companyId.value);
          setComRecord(company);
        } else {
          setUserlist([]);
          setComRecord(null);
        }
      }, [companyId]);
      useEffect(() => {
        if (userId && userlist) { // Ensure both companyId and comList are not null
            const selectedUser = userlist.find(user => user.id === userId.value);
            if (selectedUser) {
                setUserRecord(selectedUser);
            }
        }
      }, [userId]);
    


    // Navigate Different Company Choices
    const [display, setDisplay] = useState(true);
    const [edit, setEdit] = useState(false);
    const [newCom, setNewCom] = useState(false);

    const displayInfo = () => {
        setDisplay(true);
        setEdit(false);
        setNewCom(false);
    };
    const editInfo = () => {
        setEdit(true);
        setDisplay(false);
        setNewCom(false);
    };
    const newInfo = () => {
        setNewCom(true);
        setDisplay(false);
        setEdit(false);
    };

    const buttons = [
        {id: 1,icon: <FaUserAlt className="text-lg mt-1" />, label: "Display User", func: displayInfo, con: display},
        {id: 2, icon: <FaUserEdit className="text-lg mt-1" />, label: "Edit User", func: editInfo, con: edit},
        {id: 3,icon: <FaUserPlus className="text-lg mt-1" />, label: "New User", func: newInfo, con: newCom},
    ];

    // Edit Action Buttons
    const saveChanges = () => {};
    const cancelChanges = () => {
        setDisplay(false);
        setEdit(false);
        setNewCom(false);
        setComRecord(null);
        setCompanyId(null);
    };

    const [resultFromChild, setResultFromChild] = useState(null);
    const handleDataReturn = (data) => {
        setResultFromChild(data);
    };

    return(
        <div className="flex flex-col justify-center items-center">

            <div className="flex flex-row gap-4 justify-center w-[70%] mb-4">
                {buttons.map((button) => (
                <div key={button.id} className="flex items-end md:mb-0 md:w-auto">
                    <button onClick={button.func} 
                            className={`w-full rounded-md text-custom-blue border-2
                                        px-10 py-2 font-semibold ${button.con ? 'bg-custom-blue text-white' : 'hover:bg-custom-blue hover:text-white'}
                                        sm:w-auto flex flex-row gap-2 justify-center`}>
                    {button.icon}
                    <span className="text-lg">{button.label}</span>
                    </button>
                </div>
                ))}
            </div>

            
            { display || edit || newCom ?
            <div className="w-[90%] border-1 border-custom-blue p-4 rounded-lg">
                { display || edit ?
                <div className="flex flex-row justify-center w-[90%]">
                    <div className="flex flex-row justify-center w-[70%]">
                        <label className="text-lg uppercase font-sans font-bold text-gray-700 mt-1">Company</label>
                        <div className="w-[50%] ml-8 text-gray-600 font-bold mb-4">
                            <Select options={Company}
                                    value={companyId}
                                    onChange={setCompanyId}/>
                        </div>
                    </div>
                    <div className="flex flex-row justify-center w-[70%]">
                        <label className="text-lg uppercase font-sans font-bold text-gray-700 mt-1">User</label>
                        <div className="w-[50%] ml-8 text-gray-600 font-bold mb-4">
                            <Select options={userOptionsList}
                                    value={userId}
                                    onChange={setUserId}/>
                        </div>
                    </div>
                </div> : null}


                {display?
                    <DisplayUser userRecord={userRecord} onDataReturn={handleDataReturn} />
                : null}


                {edit?
                    <EditCom comRecord={comRecord} onDataReturn={handleDataReturn} />
                : null}

                {newCom?
                    <NewCom comRecord={comRecord} onDataReturn={handleDataReturn} />
                : null}


                { edit || newCom ?
                <div className="flex items-start justify-center mb-4 gap-2"> 
                        <button  className=" w-full rounded-md text-red-500 border-2 px-6 py-2 font-semibold hover:bg-red-500 hover:text-white sm:w-auto flex flex-row gap-2 justify-center"
                                onClick={cancelChanges}>
                                <FcCancel className="text-xl"/>
                                <span>Cancel Changes</span>
                        </button>
                        <button  
                                className=" w-full rounded-md hover:text-custom-blue text-slate-50 border-2 px-6 py-2 font-semibold bg-custom-blue  hover:bg-white sm:w-auto flex flex-row gap-2 justify-center">
                                <FaSave className="text-xl"/>
                                <span>Save Changes</span>
                        </button>
                </div> : null}

                { display ?
                <div className="flex items-start justify-center mb-4 gap-2"> 
                        <button  className=" w-full rounded-md text-red-500 border-2 px-12 py-2 font-semibold hover:bg-red-500 hover:text-white sm:w-auto flex flex-row gap-2 justify-center"
                                onClick={cancelChanges}>
                                <FcCancel className="text-xl"/>
                                <span>Cancel</span>
                        </button>
                        <button  className=" w-full rounded-md hover:text-custom-blue text-slate-50 border-2 px-6 py-2 font-semibold bg-custom-blue  hover:bg-white sm:w-auto flex flex-row gap-2 justify-center"
                                onClick={editInfo}>
                                <FaEdit className="text-xl"/>
                                <span>Edit User</span>
                        </button>
                </div> : null}
            </div> : null}
        </div>
    );
}