import React, { useState } from "react";
import axios from 'axios';

// Icon Imports
import { FiUploadCloud } from 'react-icons/fi';
import { FaWindowClose } from "react-icons/fa";

export default function DocUpload({packID, packName, onClose})  {

    const [errorMessage, setErrorMessage] = useState(null);
    const [message, setMessage] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);

    
    const uploadFile = async () => {
        if (selectedFile) {
            // Rename, and Prepare fileData
            const newFileName = 'pack_' + packID + '.pdf';
            const renamedFile = new File([selectedFile], newFileName, { type: selectedFile.type });
            const formData = new FormData();
            formData.append('doc_file', renamedFile);
            // Save Uploaded File
            try {
                const fileresponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/media/package/upload/${packID}/`, 
                    formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setMessage("File Uploaded Sucessfully!");
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        } else {
            setErrorMessage('Please upload a file first.');
        }
    }
    
    
    const handleFileChange = async (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile && selectedFile.type !== 'application/pdf') {
        setErrorMessage('Only PDF files are allowed.');
      } else {
        setSelectedFile(selectedFile);
        setErrorMessage(null);
        setMessage(null);
      }
    };

    const handleClose = () => {
        onClose();
    };


    return (
        <div name="DocUpload">
            <div className="w-full flex justify-center items-center">
                <button
                    className="absolute top-4 right-2 text-red-500 rounded-full p-1"
                    onClick={handleClose}
                >
                    <FaWindowClose className="text-4xl"/>
                </button>
            </div>
           
            <div className="w-full flex flex-col items-center justify-center mt-10 p-2">
            <form encType="multipart/form-data" onSubmit={(e) => e.preventDefault()}>
                <div className="w-full mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
                <span className="text-2xl text-teal-400">
                    <FiUploadCloud />
                </span>
                <label className="font-semibold text-lg text-custom-blue">Upload Description File</label>
                <div className="grow">
                    <input
                    type="file"
                    className="input w-full text-gray-500"
                    name="upload_description_file"
                    accept=".pdf"
                    onChange={handleFileChange}
                    />
                </div>
                </div>
            </form>
            <div className="w-[75%] flex justify-center self-center mt-4">
                {errorMessage && <div className="text-red-500">{errorMessage}</div>}
                {message && <div className="text-teal-500 text-center">{message}</div>}
            </div>
            <div className="w-[75%] flex justify-center self-center flex-row gap-4 mt-4">
                <button 
                    className="p-2 px-3 font-bold border-1 rounded bg-custom-blue text-gray-50 hover:bg-white hover:text-custom-blue hover:border-custom-blue"
                    onClick={uploadFile}
                    type="button">
                    Change Doc.</button>
                <button
                    className="p-2 px-4 font-bold border-1 rounded bg-red-500 text-gray-50 hover:bg-white hover:text-red-500 hover:border-red-500"
                    onClick={handleClose}
                    type="button">
                    Cancel</button>
            </div>
        </div>
    </div>
    );
};