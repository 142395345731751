import React, { useState } from "react";


// In ComBase component
export default function DisplayUser({ userRecord, onDataReturn }) {
    // Example function that processes data and returns a result
    const processData = () => {
      const result = "Processed Data"; 
      onDataReturn(result); 
    };

    // console.log("User Record: ");
    // console.log(userRecord);

    const inputValues = {
        id: userRecord?.id || '',
        fullname: userRecord?.fullname || '',
        email: userRecord?.email || '',
        uphone: userRecord?.uphone || '',
        dateOfBirth: userRecord?.date_of_birth || '',
        dateJoined: userRecord?.date_joined || '',
        createdAt: userRecord?.created_at || '',
        updatedAt: userRecord?.updated_at || '',
        role: userRecord?.role === 'HD' ? 'Head User' :
            (userRecord?.role === 'MD' ? 'Manager User': 'Normal User'),
        languagePrefernce: userRecord?.language_prefernce === 'de' ? 'Dutch' : 
        (userRecord?.language_prefernce === 'en' ? 'English' : 'German'),
        function: userRecord?.function || '',
        departmentName: userRecord?.department_name || '',
        departmentCode: userRecord?.department_code || '',
        budget: userRecord?.budget ? parseFloat(userRecord.budget).toFixed(2) : '0.00',
        isApproved: userRecord?.is_approved ? 'Approved' : 'Not Approved',
    };
  
    
  
    return (
        <>
        {/** Head User Information Forms */}
        <div className="flex flex-row justify-between">
            <div className="w-[45%] h-full p-6 rounded-md">
              <form className="w-full max-w-lg">
              <div className="flex mb-6 self-center justify-center items-center">
                    <div className="w-full md:w-full mb-6 md:mb-0 justify-center items-center">
                        User Image
                    </div>
                </div>

                <div className="flex flex-wrap mb-6">
                    <div className="w-full md:w-full mb-6 md:mb-0">
                        <span className="text-lg text-custom-blue font-semibold">
                            Personal Information
                        </span>
                    </div>
                </div>

            <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-id">
                            ID
                        </label>
                        <input className="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-id" type="text" value={inputValues.id} readOnly/>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-full-name">
                            Full Name
                        </label>
                        <input className="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-full-name" type="text" value={inputValues.fullname} readOnly/>
                    </div>
                    <div className="ml-auto w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="role">
                        Role
                    </label>
                    <input className="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="role" type="text" value={inputValues.role} readOnly/>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-address">
                        Business Email
                    </label>
                    <input className="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-address" type="text" value={inputValues.email} readOnly/>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="phone">
                        Phone
                    </label>
                    <input className="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="phone" type="text" value={inputValues.uphone} readOnly/>
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="birth">
                        Date of Birth
                    </label>
                    <input className="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="birth" type="text" value={inputValues.dateOfBirth} readOnly/>
                </div>
            </div>
            </form>
        </div>
            
        <div className="w-[45%] h-full p-6 rounded-md">
          <form className="w-full max-w-lg">
            <div className="flex flex-wrap mb-6">
                <div className="w-full md:w-full mb-6 md:mb-0">
                    <span className="text-lg text-custom-blue font-semibold">
                        Professional Information
                    </span>
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="function">
                        Function (Job Title)
                    </label>
                    <input className="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="function" type="text" value={inputValues.function} readOnly/>
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="lang">
                        Preferred Language
                    </label>
                    <input className="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="lang" type="text" value={inputValues.languagePrefernce} readOnly/>
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-2/3 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="deptname">
                        Department Name
                    </label>
                    <input className="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="deptname" type="text" value={inputValues.departmentName} readOnly/>
                </div>
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="deptcode">
                        Code
                    </label>
                    <input className="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="deptcode" type="text" value={inputValues.departmentCode} readOnly/>
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="budget">
                        Assigned Budget
                    </label>
                    <input className="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="budget" type="number" value={inputValues.budget} readOnly/>
                </div>
            </div>

            <div className="flex flex-wrap mb-6 mt-10">
                <div className="w-full md:w-full mb-6 md:mb-0">
                    <span className="text-lg text-custom-blue font-semibold">
                        Admin Specific Information
                    </span>
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="is_approved">
                        Approved Status
                    </label>
                    <input className="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="is_approved" type="text" value={inputValues.isApproved} readOnly/>
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="created_at">
                        Created At
                    </label>
                    <input className="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="created_at" type="text" value={inputValues.createdAt.slice(0, 10)} readOnly/>
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="updated_at">
                        Updated At
                    </label>
                    <input className="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="updated_at" type="text" value={inputValues.updatedAt.slice(0, 10)} readOnly/>
                </div>
            </div>
          </form>
        </div> 
    </div>
    {/**End of Head User Information Forms */}
    </>
    );
  }