import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Local Imports
import Invoice from './invoice/Invoice';

// Icons
import { MdOutlineInventory } from 'react-icons/md';
import { IoChatbubblesOutline } from 'react-icons/io5';
import { MdAdminPanelSettings } from "react-icons/md";
import { BiSolidReport } from "react-icons/bi";
import { icon } from '@fortawesome/fontawesome-svg-core';
import { FaFileInvoiceDollar } from 'react-icons/fa';

export default function OrderBase() {
  // Display Pages at will once selected
  const [selectedPage, setSelectedPage] = useState('newInvoice');
  // const Reports = () => <Report/>;
  const NewInvoice = () => <Invoice/>;
  // const Invoices = () => <Invoice/>;
  // const Company = () => <ComBase/>;
  // const Notifications = () => <TL2/>;
  // const Chat = () => <ChatPage/>;

  const pages = {
    'newInvoice': NewInvoice,
  };

  // Data from Local Storage
  const user = JSON.parse(localStorage.getItem('user'));

  // Toggling between Tabs
  const tabs = [
    // { text: 'Add-Hock Reports', value: 'Reports', status: 'active', icon: <BiSolidReport/>},
    { text: 'New Invoice', value: 'newInvoice', status: 'inactive', icon: <FaFileInvoiceDollar />},
    // { text: 'Custom Invoices', value: 'Invoices', status: 'inactive', icon: <BiSolidReport />},
    // { text: 'Customer Info.', value: 'Company', status: 'inactive', icon: <BiSolidReport />},
    // { text: 'User Activity', value: 'Notifications', status: 'inactive', icon: <BiSolidReport />},
    // { text: 'Internal Chat', value: 'Chat', status: 'inactive', icon: <IoChatbubblesOutline />},
  ];

  const handleClick = (value) => {
    setSelectedPage(value);
  };

  const SelectedComponent = pages[selectedPage] || (() => <div>Page not found</div>);

  return (
    <div key="1" className="flex min-h-screen bg-gray-50">
      <aside className="w-1/6 bg-gray-50 p-4 shadow">
        <div>
          <ul className="flex flex-col">
            {tabs.map((tab, index) => (
              <li className="mb-1" key={index}>
                <a 
                  className={`bg-white inline-block leading-tight ${tab.value === selectedPage ? 'border-r-4 border-custom-blue' : ''} py-2 px-4 ${tab.value === selectedPage ? 'text-custom-blue font-bold' : 'text-custom-green font-semibold hover:font-bold hover:text-custom-blue'} cursor-pointer flex items-center`} 
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedPage(tab.value)
                  }}
                >
                  <span className='text-sm mr-2'>{tab.icon}</span>
                  {tab.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </aside>
      
      <main className="w-5/6 pt-6">
        <div className='p-2'>
          <SelectedComponent />
        </div>
      </main>
    </div>
  );
};