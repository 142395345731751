import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import Select from 'react-select';


// Style Attributes
import { FaDownload, FaFileInvoiceDollar } from "react-icons/fa6";
import { FaEuroSign } from "react-icons/fa";
import { TbPercentage, TbTruckDelivery } from "react-icons/tb";
import { FaThList } from "react-icons/fa";
import { BiSolidReport } from "react-icons/bi";
import { FaCirclePlus } from "react-icons/fa6";
import { LuEuro } from "react-icons/lu";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { GoMultiSelect } from "react-icons/go";
import { VscPreview } from "react-icons/vsc";
import { FcApproval } from "react-icons/fc";



// Local Imports
import { saveAs } from 'file-saver';
import generatePDF from "./generatePdf";





// Posting functions

function getOrderType(basketData) {
  // Prepare necessary Order Type Data to be sat
  let dsms = false;
  let products = false;
  let packages = false;

  basketData.flatMap(item => {
    if (item.type === 'dsm') {
      dsms = true;
    }
    else if (item.type === 'pack') {
      packages = true;
    }
    else {
      products = true;
    }
  })

  if (dsms || packages || products) {
    return 'mixed';
  } else if (dsms && !packages && !products) {
    return 'dsm';
  } else if (!dsms && packages && !products) {
    return 'pack';
  } else if (!dsms && !packages && products) {
    return 'products';
  }
}


function getProducts(basketData, productsData) {
  return basketData.flatMap(item => {
    let baseItem = [];
  
    if (item.type === 'dsm') {
      // Handle DSM products
      baseItem = baseItem.concat({
        product: item.record.productItem,
        quantity: item.qty,
        record_value_excl: Number((item.price * item.qty).toFixed(2)),
        record_value_incl: Number((item.price * item.qty * ((item.tsx + 1) || 1.14)).toFixed(2)),
        source: "dsm",
      });
    } 

    if (item.type === 'products') {
      baseItem.push({
        product: item.record.id,
        quantity: item.qty,
        record_value_excl: Number((item.price * item.qty).toFixed(2)),
        record_value_incl: Number((item.price * item.qty * ((item.tsx + 1) || 1.14)).toFixed(2)),
        source: "product",
      });
    }
    return baseItem;
  });
}


function getPackages(basketData) {
  return basketData.flatMap(item => {
    if (item.type === 'pack') {
      // console.log("print Package");
      // console.log(item);
      return {
        package: item.record.id,
        quantity: item.qty,
        num_of_products: item.record.num_of_products,
        record_value_excl: Number((item.record.total_pack_price * item.qty).toFixed(2)),
        record_value_incl: Number((item.record.total_pack_price * item.qty * ((item.tsx + 1) || 1.14)).toFixed(2)),
        record_type: 'purchase'
      };
    } else {
      return [];
    }
  });
}



function getNextOrderDate() {
  return null;
}



const saveInvoicePDF = async (pdfBlob, inv_id, invoice_number) => {
    const newFileName = `Invoice_${invoice_number}.pdf`;
    //const newFileName = 'Invoice.pdf';
    const pdfFile = new File([pdfBlob], newFileName, { type: 'application/pdf' });

    // Create FormData and append the file
    const formData = new FormData();
    formData.append('inv_file', pdfFile);

    // Save Uploaded File
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/media/invoice/issued/${inv_id}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Invoice issued successfully:', response.data);
    } catch (error) {
      console.error('Error issuing invoice PDF:', error);
    }
}



// Function to get today's date in YYYY-MM-DD format
const getFormattedDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};


async function createInvoiceRecord(orderData, payData, totals, pdf) {
  const invoiceData = {
    order: orderData.id,
    payment: payData.id,
    vat: null,
    company: null,
    dsm: null,
    discount: null,
    invoice_status: 'recieved',
    invoice_type: 'order',
    invoice_date: getFormattedDate(),
    invoice_total_excl: totals.total_excl,
    invoice_total_incl: totals.total_incl,
  };

  try {
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/orders/invoice/create/`, invoiceData);
    if (response.status === 201 || response.status === 200) {
      await saveInvoicePDF(pdf, response.data.id, response.data.invoice_number);
      //await sendInvoiceNotificationData(orderData, response.data);
      console.log('Invoice created successfully!');
      return response.data.invoice_number;
    } else {
      console.error('Failed to create invoice:', response.status);
    }
  } catch (error) {
    console.error('Error creating invoice:', error);
    //console.log(error.response.data);
  }

  return null;
}

async function postOrderRecord(data) {
  // Extract necessary information from the data
  // console.log("Repeating Post Order:");
  // console.log(data.repeatOrder);
  let orderNumber = null;
  let invoiceNumber = null;

  const postOrderData = {
    user: data.userData.id,
    company: data.companyData.id,
    payment: data.paymentData.id,
    delivery: data.deliveryData.id,
    onetimedelivery: null,
    order_type: getOrderType(data.basket),
    order_status: 'recieved',
    order_status_date: getFormattedDate(),
    products: getProducts(data.basket, data.products),
    dsms: [],
    packages: getPackages(data.basket),
    order_total: data.orderTotal.total_incl,
    repeat_order: null,
    repeat_order_type: null,
    repeat_order_subtype: null,
    next_order_date: getNextOrderDate(),
  }
  // console.log("OrderData: ");
  // console.log(postOrderData);


  // Posting new OrderData
  try {
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/orders/order/create/`, postOrderData);
    if (response.status === 201 || response.status === 200) {
      //dispatch(addToBasket(newPackageProduct));
      // console.log("Package response: ");
      // console.log(response.data);
      orderNumber = response.data.order_number;
      console.log('Order created successfully', orderNumber);
      invoiceNumber = await createInvoiceRecord(response.data, data.paymentData, data.orderTotal, data.pdf);
      //await sendNotificationData(response.data);
    } else {
      console.log('Failed to create order.');
    }
  } catch (error) {
    //console.error('Error creating package:', error);
    console.error('Error Creating Order!!');
  }


  // Return a single text value
  return { orderNumber, invoiceNumber };
}














export default function Invoice() {

  // reading user Data
  const user = JSON.parse(localStorage.getItem('user'));

  // options for Company
  const [comlist, setComlist] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [comRecord, setComRecord] = useState(null);

  const getCompanies = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/admin/comlist/`);
      //console.log("Companies:   " + JSON.stringify(response.data));
      setComlist(response.data);
    } catch (error) {
      console.error(`Error retrieving companies: ${error}`);
    }
  };
  useEffect(() => {
    getCompanies();
  }, []);
  //console.log("Companies:   " + comlist);
  const Company = comlist.map(com => ({
    value: com.id,
    label: com.cname
  }));
  //console.log("Companies List Options:   " + Company);


// Options for company users, Payment Profiles, and Delivery Profiles
const [userlist, setUserlist] = useState([]);

const [paylist, setPaylist] = useState([]);
const [payId, setPayId] = useState(null);
const [payRecord, setPayRecord] = useState(null);

const [dellist, setDellist] = useState([]);
const [delId, setDelId] = useState(null);
const [delRecord, setDelRecord] = useState(null);

  useEffect(() => {
    //console.log("companyID: " + companyId?.value);
    if (companyId) {
      setDsmOptions(null);
      setPackOptions(null);
      setSelectedItemOption(null);
      setSelectedDsm(null);
      setSelectedPack(null);
      // Update user list
      const newUserlist = user.company_users
        .filter(user => user.company === companyId.value)
        .map(user => user.id);
      setUserlist(newUserlist);

      // In practice, consider dependency or state update timing
      if (newUserlist.length > 0) {
        // Update payment profiles
        const newPay = user.payment_data
          .filter(pay => newUserlist.includes(pay.user))
          .map(pay => ({
            value: pay.id,
            label: pay.pay_name || pay.PO_number
          }));
        setPaylist(newPay);
        //console.log("Payment List:   " + JSON.stringify(newPay));

        // Update delivery profiles
        const newDel = user.delivery_data
          .filter(del => newUserlist.includes(del.user))
          .map(del => ({
            value: del.id,
            label: del.del_name || del.daddress
          }));
        setDellist(newDel);
        //console.log("Delivery List:   " + JSON.stringify(newDel));
      } else {
        setPaylist([]);
        setDellist([]);
      }

      // Additional logic for setting pay record from company list
      const company = comlist.find(com => com.id === companyId.value);
      setComRecord(company);
    } else {
      setUserlist([]);
      setPaylist([]);
      setDellist([]);
      setComRecord(null);
      setSelectedItemOption(null);
      setSelectedDsm(null);
      setSelectedPack(null);
    }
  }, [companyId]);

  useEffect(() => {
    if (payId) {
      const pay = user.payment_data.find(pay => pay.id === payId.value);
      setPayRecord(pay);
    } else {
      setPayRecord(null);
    }
  }, [payId]);

  useEffect(() => {
    if (delId) {
      const del = user.delivery_data.find(del => del.id === delId.value);
      setDelRecord(del);
    } else {
      setPayRecord(null);
    }
  }, [delId]);
  

  // Rows of the Table to construct the Invoice
  const rows = [
    {
      icon: <BiSolidReport className="mt-1" />,
      question: 'Which Company to Invoice?',
      options: Company,
      selectedItem: companyId,
      setSelectedItem: setCompanyId,
    },
    {
      icon: <FaEuroSign className="mt-1" />,
      question: 'Which Payment Profile to use?',
      options: paylist,
      selectedItem: payId,
      setSelectedItem: setPayId,
    },
    {
      icon: <TbTruckDelivery className="mt-1" />,
      question: 'Which Delivary Profile to use?',
      options: dellist,
      selectedItem: delId,
      setSelectedItem: setDelId,
    },
    // add more rows as needed
  ];


  
  // Item Options
  const ItemOptions = [
    { value: 'products', label: 'Product'},
    { value: 'dsm', label: 'DSM'},
    { value: 'pack', label: 'Package'}
  ];
  const [selectedItemOption, setSelectedItemOption] = useState(null);





  // Product Items Section
  const products = JSON.parse(localStorage.getItem('products'));
  const [basket, setBasket] = useState([]);
  const tableHeads = ['', 'Product name', 'Price per unit', 'Number of units', 'Total price (excl.)',
                      'Tax per. %', 'Tax amt', 'Total price (incl.)', ''];
  const [selectedItem, setSelectedItem] = useState(null);
  const [message, setMessage] = useState(null);

  const productsSelect = products.map(product => ({
    value: product,
    label: product.name
  }));
  productsSelect.unshift({ value: 'new', label: 'New Product' });


  // Product Values Section
  const [pQty, setPQty] = useState(1);
  const [price, setPrice] = useState(null);
  const [tax, setTax] = useState(14);
  const [total, setTotal] = useState((2 + (2 * 0.14)).toFixed(2));

  useEffect(() => {
    const calculatedTotal = (pQty * price + (pQty * price * (tax / 100))).toFixed(2);
    setTotal(calculatedTotal);
  }, [pQty, price, tax]);

  const addNewItem = () => {
      // Retrieve the values from the inputs
      const id = basket.length + 1;
      const price = document.getElementById('price').value || 2;
      const qty = document.getElementById('qty').value || 1;
      const tax = (document.getElementById('tax').value / 100) || 0.14;
      const type = 'products';
      const record = selectedItem.value;

      // Retrieve the selected item from the dropdown
      if (selectedItem === null) {
        setMessage("Select a Product first....");
        return;
      } else {
        setMessage(null);
      }
      const name = selectedItem.label;
                      
      // Create a new item
      const newItem = { id, name, price, qty, tax, type, record };
                      
      // Add the new item to the basket
      setBasket([...basket, newItem]);
    };

  const handleRemoveFromBasket = (id) => {
      const newBasket = basket.filter(item => item.id !== id);
      setBasket(newBasket);
    };


    // Get Product by its ID
    const getProductById = (id) => {
      return products.find(product => product.id === id);
    };





  // DSM Items Selection
  const [dsmItems, setDsmItems] = useState([]);
  const [dsmOptions, setDsmOptions] = useState([]);
  const [selectedDsm, setSelectedDsm] = useState(null);
  const [dsmMessage, setDsmMessage] = useState(null);
  const DsmTableHeads = ['', 'Product name', 'Price per unit', 'Product status', 'Lower limit', 'Upper limit', 'Number of units', 'Total price (incl.)', ''];

  useEffect(() => {
    const getDSM = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/products/dsm/all/`);
        if (response.status >= 200 && response.status < 300) {
          const filteredDsmItems = response.data.filter(dsm => userlist.includes(dsm.dsm_creator.id));
          setDsmItems(filteredDsmItems);

          const options = filteredDsmItems.map(dsm => ({
            value: dsm,
            label: dsm.dsm_name
          }));
          setDsmOptions(options);
        } else {
            console.log("Unsuccessful fetching of DSMs!");
        }
      } catch (error) {
        console.error(`Error retrieving DSM items: ${error}`);
      }
    };
    if (companyId && userlist.length > 0) {
      getDSM();
    } else {
      setDsmMessage("Select a Company first....");
    }
  }, [userlist, companyId]);
  
  // Handling Addding Dsm Item into Basket
  const [inputValues, setInputValues] = useState({});

  const handleInputChange = (id, value) => {
    setInputValues(prevValues => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const addNewDsmItem = (item, dsmQty) => {
    // Retrieve the selected item from the dropdown
    if (item === null) {
      setMessage("Select a DSM first....");
      return;
    } else {
      setMessage(null);
    }
    
    // Retrieve the values from the inputs
    const id = basket.length + 1;
    const qty = dsmQty || item.upper_spec_stock;
    const name = getProductById(item.product).name || 'NA';
    const price = getProductById(item.product).price_sales_c1 || 2;
    const tax = 0.14;
    const type = 'dsm';
    const record = {DsmItem: selectedDsm.value, productItem: item.product};               
    // Create a new item
    const newItem = { id, name, price, qty, tax, type, record };
                    
    // Add the new item to the basket
    setBasket([...basket, newItem]);
  };
  



    // Pack Items Selection
    const [packItems, setPackItems] = useState([]);
    const [packOptions, setPackOptions] = useState([]);
    const [selectedPack, setSelectedPack] = useState(null);
    
  
    useEffect(() => {
      const getPack = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/products/package/all/`);
          if (response.status >= 200 && response.status < 300) {
              const filteredPackItems = response.data.filter(pack => userlist.includes(pack.pack_creator.id));
              setPackItems(filteredPackItems);
    
              const options = filteredPackItems.map(pack => ({
                value: pack,
                label: pack.package_name
              }));
              setPackOptions(options);
              console.log("Packages retrived: Successfully!");
          } else {
              console.log("Unsuccessful fetching of Packages!");
          }
        } catch (error) {
          console.error(`Error retrieving Packages items: ${error}`);
        }
      };
  
      if (companyId && userlist.length > 0) {
        getPack();
        setMessage(null);
      } else {
        setMessage("Select a Company first....");
      }
    }, [userlist, companyId]);
  
    const addNewPackItem = () => {
        // Retrieve the selected item from the dropdown
        if (selectedPack === null) {
          setMessage("Select a Product first....");
          return;
        } else {
          setMessage(null);
        }
        // Retrieve the values from the inputs
        const id = basket.length + 1;
        const price = document.getElementById('packPrice').value || selectedPack.value.total_pack_price || 0;
        const qty = document.getElementById('packQty').value || 1;
        const tax = (document.getElementById('packTax').value / 100) || 0.14;
        const name = selectedPack.label;
        const type = 'pack';
        const record = selectedPack.value;
                        
        // Create a new item
        const newItem = { id, name, price, qty, tax, type, record };
                        
        // Add the new item to the basket
        setBasket([...basket, newItem]);
      };

    // Handle price updates
    useEffect(() => {
      if (selectedItemOption === 'pack') {
        const price = selectedPack ? selectedPack.value.total_pack_price : 0;
      } else if (selectedItemOption === 'products') {
        const price = selectedItem ? selectedPack.value.sales_prices_c1 : 2;
      }
      setPrice(price);
    }, [selectedPack, selectedItem]);

    


    // Invoice Review - Checkout Model
    const today = new Date();
    const formattedDate = today.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
    const [startCheckout, setCheckout] = useState(false);
    const initiateCheckoutModel = () => {
      setCheckout(true);
      // console.log("Basket Data: ");
      // console.log(basket);
    };
    
    
    const invoiceRef = useRef();
    const DownloadInvoice = async () => {
      // console.log("Basket Data: ");
      // console.log(basket);
      if (!issueInv) {
        try {
          const pdfBlob = await generatePDF(invoiceRef.current);
          saveAs(pdfBlob, 'invoice.pdf');
        } catch (error) {
          console.error('Error generating PDF:', error);
        }
      }
    };
    
    // Creating Order and Invoice records, POST them and save file
    const [issueInv, setIssueInv] = useState(false);
    const IssueInvoice = async (event) => {
      event.preventDefault();
      // issue invoice pdf Blob
      const pdfBlob = await generatePDF(invoiceRef.current);
            
      // Calculate Totals
      const totals = {
        total_excl: basket.reduce((sum, item) => sum + (item.price * item.qty), 0).toFixed(2),
        total_incl: basket.reduce((sum, item) => sum + (item.price * item.qty * (item.tax || 0.14)), 0).toFixed(2),
        total_tax: basket.reduce((sum, item) => sum + (item.price * item.qty * (item.tax || 0.14)), 0).toFixed(2),
      };
      // Send the invoice to the server
      const hdUser = user.company_users.find(user => user.role === 'HD' && user.company === companyId.value);
      const postData = {
          companyData: comRecord,
          userData: hdUser,
          deliveryData: delRecord,
          paymentData: payRecord,
          basket: basket,
          products: products,
          orderTotal: totals,
          pdf: pdfBlob,
      }
      
      // Call post order
      //await postOrderRecord(postData);
      try {
        const { orderNumber, invoiceNumber } = await postOrderRecord(postData);
      } catch (error) {
        console.error('Error creating order:', error);
      }
      
      // Redirect to the invoice page
      setCheckout(false);
      setBasket([]);
      setComRecord(null);
      setPayRecord(null);
      setDelRecord(null);
      setCompanyId(null);
      setUserlist([]);
      setPayId(null);
      setPaylist([]);
      setDelId(null);
      setDellist([]);
      setIssueInv(true);
    };
  

  return (

    <div className="w-full h-full flex flex-col">

    {/** Invoice Creation Section */}
    <div className="w-full h-full flex flex-row mt-6">
      
      {/**Section 1 - Building Invoice*/}
      <div id="products_file" className="w-[70%] flex justify-center">
        <div id="invoice-details" className="w-[95%] min-h-[500px] flex flex-col p-4 items-center border-1 border-custom-blue">

        <h1 id="title" 
            className="text-custom-blue font-serif font-semibold text-2xl">
              <span className="flex items-center gap-3">
                  <FaFileInvoiceDollar />
                  New Invoice
                </span>
          </h1>
          {/**Table Structure Invoice building section */}
          <div className="flex items-center w-full justify-center text-custom-blue">
          <table className="w-[90%] mt-6 border-collapse">
                  <tbody>
                  {rows.map((row, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td className="flex flex-row gap-2 mb-3 text-lg font-bold">
                            {row.icon}
                            {row.question}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="w-[75%] ml-8 text-gray-600 font-bold mb-4">
                              <Select options={row.options}
                                    value={row.selectedItem}
                                    onChange={row.setSelectedItem} />
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                        
                        <tr>
                          <td className="flex flex-row gap-2 mb-3 text-lg font-bold">
                            <FaThList className="mt-1"/>
                            What are you invoicing for?
                          </td>
                        </tr>

                        {/** Set Item Selection Option */}
                        <React.Fragment>
                        <tr>
                          <td>
                            <div className="w-[75%] ml-8 font-bold mb-4">
                            <Select options={ItemOptions} id="item"
                                    value={selectedItemOption}
                                    onChange={setSelectedItemOption} />
                            </div>
                          </td>
                          <td>
                          <div className="flex flex-row font-bold">
                            <p className="ml-2 mb-2 text-lg">
                              " {selectedItemOption? selectedItemOption.label : 'NA'} "</p>
                            </div>
                          </td>
                        </tr>
                        </React.Fragment>


                        {/** DSMs Selection */}
                        { selectedItemOption && selectedItemOption.value === 'dsm' ? (
                        <React.Fragment>
                        <tr>
                          <td>
                            <div className="w-[75%] ml-8 font-bold mb-4">
                            <Select options={dsmOptions} id="item"
                                    value={selectedDsm}
                                    onChange={setSelectedDsm} />
                            </div>
                          </td>
                          <td>
                          <div className="flex flex-row font-bold">
                            <label htmlFor="qty" className="font-bold text-black mb-4">
                            {selectedDsm ? `${selectedDsm.value.dsm_name} has ${selectedDsm.value.num_of_products} products` : null}</label>
                            </div>
                          </td>
                        </tr>
                        </React.Fragment>
                        ):null}



                        {/** Products Selection */}
                        { selectedItemOption && selectedItemOption.value === 'products' ? (
                        <React.Fragment>
                        <tr>
                          <td>
                            <div className="w-[75%] ml-8 font-bold mb-4">
                            <Select options={productsSelect} id="item"
                                    value={selectedItem}
                                    onChange={setSelectedItem} />
                            </div>
                          </td>
                          { selectedItem && (
                          <td>
                          <div className="flex flex-row font-bold">
                            <label htmlFor="qty" className="font-bold text-black mr-2 mb-4">Qty:</label>
                            <input type="number" id="qty" name="qty" min="1" step="1" placeholder="1"
                                   className="text-custom-blue font-bold border-2 rounded mb-4 w-[50%] px-2 py-1" 
                                   value={pQty}
                                   onChange={(e) => setPQty(Number(e.target.value))}/>
                            <p className="ml-2 mt-2 text-sm">items</p>
                            </div>
                          </td>
                          )}
                        </tr>
                        </React.Fragment>
                        ):null}

                        
                        {/** Products Place */}
                        { selectedItemOption && selectedItemOption.value === 'products' && selectedItem? (
                        <React.Fragment>
                        <tr>
                          <td>
                            <div className="flex">
                              <div className="flex w-auto flex-row ml-8 font-bold mb-4">
                                <label htmlFor="price" className="font-bold text-black mr-2 mb-4 mt-1">Price 
                                  <span className="text-gray-400 m-1 font-bold text-xs">(Excl.)</span>
                                  :</label>
                                <input type="number" id="price" name="price" min="1" 
                                      className="text-custom-blue font-bold border-2 rounded mb-4 w-[25%] px-2 py-1"
                                      placeholder={selectedItem.value.sales_price_c1 || 2}
                                      value={price || selectedItem.value.sales_price_c1 || 2}
                                      onChange={(e) => setPrice(Number(e.target.value))}/>
                                  <LuEuro className="mt-2 ml-1"/>
                              </div>
                              <div className="flex flex-row w-auto font-bold mb-4">
                                <label htmlFor="tax" className="font-bold text-black mr-2 mb-4 mt-1">Tax.:</label>
                                <input type="number" id="tax" name="tax" min="1" placeholder="14"
                                      className="text-custom-blue font-bold border-2 rounded mb-4 w-[25%] px-2 py-1"
                                      value={tax}
                                      onChange={(e) => setTax(Number(e.target.value))}
                                    />
                                  <TbPercentage className="mt-2 ml-1"/>
                              </div>
                            </div>
                          </td>

                          <td>
                          <div className="flex flex-row w-auto font-bold mb-4">
                                <label htmlFor="tax" className="font-bold mr-2 mb-4 mt-1">Total:</label>
                                <p id="total" name="total"
                                      className="text-custom-blue font-bold border-2 w-[50%] rounded mb-4 px-2 py-1"
                                    > {total} </p>
                                <LuEuro className="mt-2 ml-1"/>
                              </div>
                          </td>

                        </tr>
                        
                        <tr>
                          <td>
                            {message ? 
                              <p className="text-red-400 text-base font-bold ml-8">
                                {message}</p>:null}
                          </td>
                        <td>
                          <div className="flex items-end md:mb-0 md:w-auto">
                            <button onClick={addNewItem} 
                                    className=" w-full rounded-md text-custom-blue border-2
                                              px-6 py-2 font-semibold hover:bg-custom-blue hover:text-white 
                                              sm:w-auto flex flex-row gap-2 justify-center">
                                  <FaCirclePlus className="text-xl"/>
                                  <span>Add item</span>
                            </button>
                          </div>
                          </td>
                        </tr>
                        </React.Fragment>
                        ): null}
                        {/** Total section for all selected Item Options */}


                        {/** Package Selection */}
                        { selectedItemOption && selectedItemOption.value === 'pack' ? (
                        <React.Fragment>
                        <tr>
                          <td>
                            <div className="w-[75%] ml-8 font-bold mb-4">
                            <Select options={packOptions} id="item"
                                    value={selectedPack}
                                    onChange={setSelectedPack} />
                            </div>
                          </td>
                          { selectedPack && (
                          <td>
                          <div className="flex flex-row font-bold">
                            <label htmlFor="packQty" className="font-bold text-black mr-2 mb-4">Qty:</label>
                            <input type="number" id="packQty" name="packQty" min="1" step="1" placeholder="1"
                                   className="text-custom-blue font-bold border-2 rounded mb-4 w-[50%] px-2 py-1" 
                                   value={pQty}
                                   onChange={(e) => setPQty(Number(e.target.value))}/>
                            <p className="ml-2 mt-2 text-sm">items</p>
                            </div>
                          </td>
                          )}
                        </tr>
                        </React.Fragment>
                        ):null}

                        
                        {/** Pack Place */}
                        { selectedItemOption && selectedItemOption.value === 'pack' && selectedPack? (
                        <React.Fragment>
                        <tr>
                          <td>
                            <div className="flex">
                              <div className="flex w-auto flex-row ml-8 font-bold mb-4">
                                <label htmlFor="packPrice" className="font-bold text-black mr-2 mb-4 mt-1">Price 
                                  <span className="text-gray-400 m-1 font-bold text-xs">(Excl.)</span>
                                  :</label>
                                <input type="number" id="packPrice" name="packPrice" min="1" 
                                      className="text-custom-blue font-bold border-2 rounded mb-4 w-[35%] px-2 py-1"
                                      placeholder={selectedPack.value.total_pack_price || 'NA'}
                                      value={price || selectedPack.value.total_pack_price || 0}
                                      onChange={(e) => setPrice(Number(e.target.value))}/>
                                  <LuEuro className="mt-2 ml-1"/>
                              </div>
                              <div className="flex flex-row w-auto font-bold mb-4">
                                <label htmlFor="packTax" className="font-bold text-black mr-2 mb-4 mt-1">Tax.:</label>
                                <input type="number" id="packTax" name="packTax" min="1" placeholder="14"
                                      className="text-custom-blue font-bold border-2 rounded mb-4 w-[35%] px-2 py-1"
                                      value={tax}
                                      onChange={(e) => setTax(Number(e.target.value))}
                                    />
                                  <TbPercentage className="mt-2 ml-1"/>
                              </div>
                            </div>
                          </td>

                          <td>
                          <div className="flex flex-row w-auto font-bold mb-4">
                                <label htmlFor="tax" className="font-bold mr-2 mb-4 mt-1">Total:</label>
                                <p id="total" name="total"
                                      className="text-custom-blue font-bold border-2 w-[50%] rounded mb-4 px-2 py-1"
                                    > {total} </p>
                                <LuEuro className="mt-2 ml-1"/>
                              </div>
                          </td>

                        </tr>
                        
                        <tr>
                          <td>
                            {message ? 
                              <p className="text-red-400 text-base font-bold ml-8">
                                {message}</p>:null}
                            <p className="text-teal-500 text-base font-bold ml-8">
                              {selectedPack ? `${selectedPack.value.package_name} has ${selectedPack.value.num_of_products} products and is ${selectedPack.value.package_status}` : null}
                            </p>
                          </td>
                        <td>
                          <div className="flex flex-row items-end md:mb-0 md:w-auto">
                            <button onClick={addNewPackItem} 
                                    className=" w-full rounded-md text-custom-blue border-2
                                              px-6 py-2 font-semibold hover:bg-custom-blue hover:text-white 
                                              sm:w-auto flex flex-row gap-2 justify-center">
                                  <FaCirclePlus className="text-xl"/>
                                  <span>Add item</span>
                            </button>
                          </div>
                          </td>
                        </tr>
                        </React.Fragment>
                        ): null}
                        {/** Total section for all selected Item Options */}
                  </tbody>
                </table>
            </div>


            {/** Results section if selected option is DSM */}
            {selectedDsm  && selectedItemOption && selectedItemOption.value === 'dsm'? (
            <>
              <div className="mt-2 w-full"> 
                  <h2 className="text-left text-lg font-sans font-bold text-custom-blue">
                      {selectedDsm.value.dsm_name || ''} Products List (
                          <span className="text-sm text-teal-500">
                              set the required number of units, and add products by clicking on "+"
                          </span>):
                    </h2>
                </div>
              <table 
                className="w-full mt-2 text-xs text-custom-blue border-collapse border-1 border-custom-blue">
                  <thead>
                      <tr>
                        {DsmTableHeads.map((head, index) => (
                            <th key={index} 
                                className="border-1 border-custom-blue bg-[#e9edf2] text-custom-blue text-center p-2">
                                {head}
                            </th>
                        ))}
                      </tr>
                  </thead>
                  <tbody>
                      {selectedDsm.value.products.map((item, index) => (
                          <tr key={item.id}>
                              <td className="border-1 border-custom-blue p-2 bg-white text-center">{index + 1}</td>
                              <td className="border-1 border-custom-blue p-2 bg-ehite text-center">
                                  {getProductById(item.product).name || 'NA'}</td>
                              <td className="border-1 border-custom-blue p-2 bg-white text-center">
                                  € {getProductById(item.id).sales_price_c1 || 0.00}</td>
                              <td className="border-1 border-custom-blue p-2 bg-white text-center">
                                  {item.product_status || 'NA'}</td>
                              <td className="border-1 border-custom-blue p-2 bg-white text-center">
                                  {item.lower_spec_stock || 100}</td>
                              <td className="border-1 border-custom-blue p-2 bg-white text-center">
                                  {item.upper_spec_stock || 1000}</td>
                              <td className="border-1 border-custom-blue p-2 bg-white text-center">
                                <input
                                    id="dsmQty" 
                                    type="number"
                                    placeholder={item.upper_spec_stock || 0}
                                    step="1"
                                    style={{ width: '70px' }}
                                    value={inputValues[item.id] || ''}
              onChange={(e) => handleInputChange(item.id, e.target.value)}/>
                                      
                              </td>
                                    
                              <td className="border-1 border-custom-blue p-2 bg-white text-center">
                                  € {(getProductById(item.id).sales_price_c1 * (inputValues[item.id] || item.upper_spec_stock) * (0.14 + 1)).toFixed(2) || 0.00}</td>
                              <td className="border-1 border-custom-blue p-2 bg-white text-center">
                                <button 
                                    className="cursor-pointer" 
                                    onClick={() => addNewDsmItem(item, inputValues[item.id] || item.upper_spec_stock)}
                                    title="Add to Basket">
                                  <FaCirclePlus className="text-custom-blue text-lg" />
                                </button>
                              </td>
                          </tr>
                          ))}
                      </tbody>
                  </table>
              </>
            ): null}

            {/** Results Table  List of Products*/}
            {basket.length > 0 &&
            <>
              <div className="mt-4 w-full"> 
                  <h2 className="text-center p-2 text-xl bg-custom-blue font-sans font-bold text-white underline">
                      Basket Products
                    </h2>
                </div>
              <table className="mt-2 mx-auto text-xs text-custom-blue border-collapse border-1 border-custom-blue">
                  <thead>
                    <tr>
                      {tableHeads.map((head, index) => (
                        <th key={index} className="border-1 border-white bg-custom-blue text-white text-center p-2">
                          {head}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {basket.map((item, index) => (
                      <tr key={item.id}>
                        <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">{index + 1}</td>
                        <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          {item.name}</td>
                        <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          ${item.price}</td>
                        <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          {item.qty}</td>
                        <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          ${item.price * item.qty}</td>
                        <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">{(item.tax * 100).toFixed(2)} %</td>
                        <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          ${(item.price * item.qty * item.tax).toFixed(2)}</td>
                        <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          ${(item.price * item.qty * (item.tax + 1)).toFixed(2)}</td>
                        <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          <button className="cursor-pointer" onClick={() => handleRemoveFromBasket(item.id)}>
                            <RiDeleteBin5Fill className="text-red-400 text-base" />
                          </button>
                        </td>
                      </tr>
                    ))}
                    { basket.length > 0?
                      <tr>
                        <td className="border-1 border-white bg-custom-blue text-white p-2 font-semibold text-center"
                             colSpan="4">Total</td>
                        <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center font-bold">
                              ${basket.reduce((sum, item) => sum + (item.price * item.qty), 0).toFixed(2)}</td>
                        <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">-</td>
                        <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center font-bold">
                              ${basket.reduce((sum, item) => sum + (item.price * item.qty * 0.14), 0).toFixed(2)}</td>
                        <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center font-bold">
                              ${basket.reduce((sum, item) => sum + (item.price * item.qty * 1.14), 0).toFixed(2)}</td>
                      </tr> :null}
                    </tbody>
                  </table>
                </>}
        </div>
      </div>

      {/**Section 2 - Review Section*/}
      <div id="products_media" className="w-[30%] flex justify-center">
        <div id="media" className="w-[95%] min-h-[500px] flex flex-col gap-4 p-4 items-center border-1 border-custom-blue">
            <h1 id="title" 
                className="text-custom-blue font-serif font-semibold text-2xl">
                  <span className="flex items-center gap-3">
                      <FaFileInvoiceDollar />
                      Invoice Summary
                    </span>
              </h1>
            

              {/** Company Card */}
              { comRecord ?
              <div className="w-full mt-2 text-custom-blue bg-[#DCF6F7] p-4 rounded-lg h-fit">
                <h2 className="flex flex-row gap-1 font-bold text-lg">
                  <BiSolidReport className="mt-1"/>
                  <span>Company Info.</span>
                  </h2>
                <div className="my-3 text-sm">
                  <div className="my-1 mt-2 text-base font-bold flex justify-center">
                    <span>{comRecord.cname}</span>
                  </div>
                  <div className="grid grid-cols-2 gap-3 my-1 mb-2">
                    <p>COD number</p>
                    <span>{comRecord.cod_number? comRecord.cod_number : 22345}</span>
                  </div>
                  <div className="my-1 mt-2 font-semibold flex justify-center">
                    <p>Contract Info.</p>
                  </div>
                  <div className="grid grid-cols-2 gap-3 my-1">
                    <span>contract start date</span>
                    <span>contract end date</span>
                  </div>
                  <div className="grid grid-cols-2 gap-3 my-1 mb-1">
                    <span>{comRecord.contract_start_date}</span>
                    <span>{comRecord.contract_end_date}</span>
                  </div>

                  <div className="my-1 mt-2 font-semibold flex justify-center">
                    <p>Service Level Agreements</p>
                  </div>
                  <div className="grid grid-cols-1 my-1">
                    {Object.keys(comRecord)
                      .filter(key => key.startsWith('sla_') && comRecord[key])
                      .map((key, index) => (
                        <p key={index}>{comRecord[key]}</p>
                      )).length > 0 ? (
                        Object.keys(comRecord)
                          .filter(key => key.startsWith('sla_') && comRecord[key])
                          .map((key, index) => (
                            <p key={index}>- {comRecord[key]}</p>
                          ))
                      ) : (
                        <p>No service level agreements</p>
                      )}
                  </div>

                  <div className="my-1 mt-2 font-semibold flex justify-center">
                    <p>Location</p>
                  </div>
                  <div className="grid grid-cols-1 my-1">
                    <p>{comRecord.caddress}, {comRecord.cpostalcode}, {comRecord.ccity}, {comRecord.ccountry}</p>
                  </div>
                  <div className="grid grid-cols-2 gap-3 my-1 mb-1">
                    <p>Main Office</p>
                    <span>{comRecord.mainoffice}</span>
                  </div>
                </div>
            </div> : null}


            {/** Payment Card */}
            { payRecord ?
            <div className="w-full mt-2 text-custom-blue bg-[#DCF6F7] p-4 rounded-lg h-fit">
                <h2 className="flex flex-row gap-1 font-bold text-lg">
                  <FaEuroSign className="mt-1"/>
                  <span>Payment Profile Info.</span>
                  </h2>
                <div className="my-3 text-sm">
                  <div className="flex justify-center font-semibold my-1">
                    <p>Budget holder Info.</p>
                  </div>
                  <div className="grid grid-cols-1 my-1">
                    <span>- {payRecord.budget_holder_name}</span>
                  </div>
                  <div className="grid grid-cols-1 my-1">
                    <span>- {payRecord.budget_holder_phone}</span>
                  </div>
                  <div className="grid grid-cols-1 my-1">
                    <span>- {payRecord.budget_holder_email}</span>
                  </div>
                  <div className="flex justify-center font-semibold my-1">
                    <p>Payment Method Info.</p>
                  </div>
                  <div className="grid grid-cols-2 gap-3 my-1">
                    <p>Bank:</p>
                    <span>{payRecord.bank}</span>
                  </div>
                  <div className="grid grid-cols-2 gap-3 my-1">
                    <p>IBAN:</p>
                    <span>{payRecord.IBAN}</span>
                  </div>
                  <div className="grid grid-cols-2 gap-3 my-1">
                    <p>Swift code:</p>
                    <span>{payRecord.swift_code}</span>
                  </div>
                  <div className="grid grid-cols-2 gap-3 my-1">
                    <p>PO number:</p>
                    <span>{payRecord.PO_number}</span>
                  </div>
                </div>
            </div> : null}


          
            {/** Delivery Card */}
            { delRecord ? 
            <div className="w-full mt-2 text-custom-blue bg-[#DCF6F7] p-4 rounded-lg h-fit">
                               <h2 className="flex flex-row gap-1 font-bold text-lg">
                  <TbTruckDelivery className="mt-1"/>
                  <span>Delivery Profile Info.</span>
                  </h2>
                <div className="my-3 text-sm">
                  <div className="flex justify-center font-semibold my-1">
                    <p>Contact Personal Info.</p>
                  </div>
                  <div className="grid grid-cols-1 my-1">
                    <span>- {delRecord.contact_person_name}</span>
                  </div>
                  <div className="grid grid-cols-1 my-1">
                    <span>- {delRecord.contact_person_phone}</span>
                  </div>
                  <div className="grid grid-cols-1 my-1">
                    <span>- {delRecord.contact_person_email}</span>
                  </div>
                  <div className="flex justify-center font-semibold my-1">
                    <p>Delivery Address</p>
                  </div>
                  <div className="grid grid-cols-2 gap-3 my-1">
                    <p>route number:</p>
                    <span>{delRecord.route_number}</span>
                  </div>
                  <div className="grid grid-cols-2 gap-3 my-1">
                  <p>Address:</p>
                    <p>{delRecord.ddaddress}, {delRecord.dpostal}, {delRecord.dcity}</p>
                  </div>
                  
                </div>
            </div> : null}


              
              {/** Summary Card */}
              { basket.length > 0 ?
              <div className="w-full mt-2 text-custom-blue bg-[#DCF6F7] p-4 rounded-lg h-fit">
              <h2 className="font-bold text-xl">Invoice Totals</h2>
              <div className="my-3 text-sm">
                <div className="grid grid-cols-2 gap-3 my-1">
                  <p>Product Total:</p>
                  <span>${basket.reduce((sum, item) => sum + (item.price * item.qty), 0).toFixed(2)}</span>
                </div>
                <div className="grid grid-cols-2 gap-3 my-1">
                  <p>Tax Total:</p>
                  <span>${basket.reduce((sum, item) => sum + (item.price * item.qty * 0.14), 0).toFixed(2)}</span>
                </div>
                <div className="grid grid-cols-2 gap-3 my-1">
                  <p></p>
                  <span>----------------</span>
                </div>
                <div className="grid grid-cols-2 gap-3 my-1">
                  <p>Order Total:</p>
                  <span>${basket.reduce((sum, item) => sum + (item.price * item.qty * 1.14), 0).toFixed(2)}</span>
                </div>
              </div>
              <div className="mt-4">
                <button className="font-semibold w-full py-2 bg-custom-blue text-white rounded-md hover:bg-blue-600 text-sm m-auto"
                        onClick={initiateCheckoutModel}>Proceed to Checkout</button>
              </div>
            </div> : null}

            {/** Icon view if there were no selections yet! */}
            {basket.length === 0 && comRecord === null && payRecord === null && delRecord === null ? 
            <div className="flex flex-col min-h-[500px] items-center justify-center">
            <GoMultiSelect size={60} className="text-gray-400 mb-3" />
            <p className="text-sm font-semibold text-gray-500">No selections yet!</p>
          </div>
          : null}

        </div>
      </div>
      </div>



      {/** Checkout Summary Section */}
      { startCheckout ?
      <div className="w-full h-full flex justify-center mt-6">
          <div className='w-[75%] shadow rounded-lg p-3 '>
            <div className="flex items-start justify-end mb-4 gap-2"> 
                <button onClick={DownloadInvoice} 
                        className=" w-full rounded-md text-custom-blue border-2
                                    px-6 py-2 font-semibold hover:bg-custom-blue hover:text-white 
                                    sm:w-auto flex flex-row gap-2 justify-center">
                        <FaDownload className="text-xl"/>
                        <span>Download</span>
                  </button>
                  <button onClick={IssueInvoice} 
                        className=" w-full rounded-md hover:text-custom-blue text-slate-50 border-2 px-6 py-2 font-semibold bg-custom-blue  hover:bg-white sm:w-auto flex flex-row gap-2 justify-center">
                        <VscPreview className="text-xl"/>
                        <span>Issue Invoice</span>
                  </button>
              </div>
              <div id="invoice-details" ref={invoiceRef} 
                   className='border-1 border-custom-blue rounded-lg p-4'>
                  <div className='text-custom-blue flex w-full gap-3 border-b-4 border-b-custom-blue'>
                      <div className='w-2/5 border-t-4 border-t-custom-blue flex items-center'>
                        <img className='w-2/3 py-1' src='../../img/Logo.png' alt='logo' /></div>
                      <div className='w-2/5 text-xs border-t-4 border-t-custom-blue'>
                        <p className=' font-bold mb-2'>Name and Address</p>
                        <p className='font-bold'>{comRecord.cname}</p>
                        <p>{payRecord.invoicing_address}</p>
                        <p>{payRecord.budget_holder_phone}</p>
                        <p>{payRecord.PO_number}</p>
                        <p>
                          <span className='font-semibold'>Payment via: </span>
                          {payRecord.bank} - {payRecord.IBAN}</p>
                      </div>
              <div className='w-1/5 text-xs border-t-4 border-t-custom-blue'>
                  <div className='bg-[#e9edf2] flex flex-col pb-1'>
                      <p className=' font-bold '>Invoice NP </p>
                      <p className='flex justify-around p-1'>12456</p>
                    </div>
                  <div className='flex flex-col pb-1'>
                      <p className=' font-bold '>PO Number </p>
                      <p className='flex justify-around p-1'>{payRecord.PO_number}</p>
                  </div>
                  <div className='flex flex-col pb-1 '>
                      <p className=' font-bold border-t-4 border-t-custom-blue'>Date </p>
                      <p className='flex justify-around p-1'>{formattedDate}</p>
                  </div>
                </div>
              </div>
              {/** Results Table */}
              {basket.length > 0 &&
                <table className="mt-4 mx-auto text-xs text-custom-blue border-collapse border-1 border-custom-blue">
                    <thead>
                      <tr>
                        {tableHeads.slice(0,-1).map((head, index) => (
                          <th key={index} className="border-1 border-white bg-custom-blue text-white text-center p-2">
                            {head}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {basket.map((item, index) => (
                        <tr key={item.id}>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">{index + 1}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                            {item.name}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                            ${item.price}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                            {item.qty}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                            ${item.price * item.qty}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">{item.tax}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                            ${(item.price * item.qty * item.tax).toFixed(2)}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                            ${(item.price * item.qty * (item.tax + 1)).toFixed(2)}</td>
                        </tr>
                      ))}
                      { basket.length > 0?
                        <tr>
                          <td className="border-1 border-white bg-custom-blue text-white p-2 font-semibold text-center"
                              colSpan="4">Total</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center font-bold">
                                ${basket.reduce((sum, item) => sum + (item.price * item.qty), 0).toFixed(2)}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">-</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center font-bold">
                                ${basket.reduce((sum, item) => sum + (item.price * item.qty * 0.14), 0).toFixed(2)}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center font-bold">
                                ${basket.reduce((sum, item) => sum + (item.price * item.qty * 1.14), 0).toFixed(2)}</td>
                        </tr> :null}
                      </tbody>
                    </table>
                  }


              <table className=" mt-4 text-xs text-custom-blue border-collapse border-1 border-custom-blue">
                  <thead>
                    <tr>
                      <th className="border-1 border-custom-blue p-2">Order Number</th>
                      <th className="border-1 border-custom-blue p-2">Price</th>
                      <th className="border-1 border-custom-blue p-2">Contrat Discount</th>
                      <th className="border-1 border-custom-blue p-2">Price After Discount</th>
                      <th className="border-1 border-custom-blue p-2">Tax</th>
                      <th className="border-1 border-custom-blue p-2">Total Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center ">__</td>
                      <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                        ${basket.reduce((sum, item) => sum + (item.price * item.qty), 0).toFixed(2)}</td>
                      <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">20%</td>
                      <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">$100</td>
                      <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center"> 
                        ${basket.reduce((sum, item) => sum + (item.price * item.qty * 0.14), 0).toFixed(2)}</td>
                      <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                        ${basket.reduce((sum, item) => sum + (item.price * item.qty * 1.14), 0).toFixed(2)}</td>
                    </tr>
                  </tbody>
              </table>
                                
              <p className='font-bold text-custom-blue py-5 flex justify-end items-center gap-3'>
                  TOTAL AMOUNT TO PAY 
                  <span className='border-2 border-custom-blue py-1 px-4'>
                    ${basket.reduce((sum, item) => sum + (item.price * item.qty * 1.14), 0).toFixed(2)}</span></p>
            </div>
          </div>
      </div> : null}

      {/** Action if Invoice is issued */}
      {issueInv ? 
      <div className="flex flex-col min-h-[200px] items-center justify-center">
          <FcApproval size={60} className="text-gray-400 mb-3" />
          <p className="text-sm font-semibold text-gray-500">Your invoice has been issued successfully!</p>
          <p className="text-sm font-semibold text-gray-500">Preforma Invoice has been sent to the client via email for approval</p>
        </div>
      : null}
    
    </div>
  );
}