import React, { useState, useRef, useEffect } from "react";
// import Select from 'react-select';
import Radio from '@mui/material/Radio';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

// Data-Grid
import Handsontable from 'handsontable';
import 'handsontable/dist/handsontable.full.min.css';
import "pikaday/css/pikaday.css";
import { alignHeaders, addClassesToRows } from "./hooksCallbacks";
import { registerAllModules } from 'handsontable/registry';


import {
  registerPlugin,
  AutoColumnSize,
  ContextMenu,
  CopyPaste,
  DropdownMenu,
  Filters,
  HiddenColumns,
  HiddenRows,
  ManualRowMove,
  MultiColumnSorting,
  UndoRedo,
} from 'handsontable/plugins';

// Style Attributes
import { BiSolidAddToQueue } from "react-icons/bi";
import { LuClipboardEdit } from "react-icons/lu";
import { IoCopySharp } from "react-icons/io5";
import './style.css';
import { FaDownload, FaSave, FaUpload } from "react-icons/fa";
import { FcCancel } from "react-icons/fc";


registerAllModules();


export default function Report() {

  {/** Display Table section */}
  const [dataFetched, setDataFetched] = useState(false);

  {/** Filterung and selection vars */}
  const [selectedTables, setSelectedTables] = useState([]);

  const handleTableChange = (tableGroupIndex, event) => {
    const { value } = event.target;
    let newSelectedTables = [];
  
    value.forEach(val => {
      const table = tableGroups[tableGroupIndex].tables.find(table => table.value === val);
      if (table) {
        newSelectedTables.push({ value: val, groupIndex: tableGroupIndex, label: table.label });
      }
    });
  
    // Merge the new selection with the existing state
    setSelectedTables(prevTables => {
      // First, filter out the tables from the current group
      const filteredTables = prevTables.filter(table => table.groupIndex !== tableGroupIndex);
      // Then, add the new selection
      return [...filteredTables, ...newSelectedTables];
    });
  
    //console.log("SelectedTables: ", selectedTables);
  };

  // useEffect(() => {
  //   console.log("SelectedTables: ", selectedTables);
  // }, [selectedTables]);

  const handleDelete = (value) => {
    setSelectedTables(prevTables => prevTables.filter(table => table.value !== value));
  };

  const tables1 = [
    {label: 'Products', value: 'products'},
    {label: 'Packages', value: 'packs'},
    {label: 'DSMs', value: 'dsms'},
    {label: 'Reviews', value: 'reviews'},
    {label: 'Reasons', value: 'reasons'},
  ];

  const tables2 = [
    {label: 'Company', value: 'company'},
    {label: 'Users', value: 'users'},
    {label: 'Payments', value: 'payment'},
    {label: 'Dlivary', value: 'delivery'},
  ];

  const tables3 = [
    {label: 'Orders', value: 'orders'},
    {label: 'Invoices', value: 'invoices'},
    {label: 'Company Discounts', value: 'disc_com'},
    {label: 'Product Discount', value: 'disc_prod'},
  ];

  const tableGroups = [
    {title: 'Products', tables: tables1},
    {title: 'Customers', tables: tables2},
    {title: 'Orders', tables: tables3},
  ];

  {/** Importing Data of the system */}
  const products = JSON.parse(localStorage.getItem('products'));

  {/** Setting Down Spread View of the data */}
  const hotTableRef = useRef(null);
  const hotInstance = useRef(null);

  {/** Metrics calculation */}
  const [totalRecords, setTotalRecords] = useState(0);
  const [displayedPercentage, setDisplayedPercentage] = useState(100);

  useEffect(() => {
    if (hotTableRef.current) {
      if (hotInstance.current) {
        hotInstance.current.loadData(products);
        setTotalRecords(products.length);
      } else {
        const colHeaders = products.length > 0 ? Object.keys(products[0]) : [];
        const hot = new Handsontable(hotTableRef.current, {
          data: products,
          rowHeaders: true,
          colHeaders: colHeaders,
          dropdownMenu: true,
          hiddenColumns: {
            indicators: true,
          },
          contextMenu: true,
          multiColumnSorting: true,
          filters: true,
          rowHeaders: true,
          manualRowMove: true,
          navigableHeaders: true,
          autoWrapCol: true,
          autoWrapRow: true,
          afterGetColHeader: alignHeaders,
          beforeRenderer: addClassesToRows,
          afterChange: () => {
            if (hotInstance.current && totalRecords !== 0) {
              setDisplayedPercentage((hotInstance.current.length / totalRecords) * 100);
            }
          },
          afterFilter: () => {
            if (hotInstance.current && totalRecords !== 0) {
              setDisplayedPercentage((hotInstance.current.length / totalRecords) * 100);
            }
          },
          afterLoadData: () => {
            setTotalRecords(products.length);
            setDisplayedPercentage(100);
          },
          licenseKey: 'non-commercial-and-evaluation',
        });
        hotInstance.current = hot;
      }
    }
  }, [hotTableRef, products]);


  // const hot = new Handsontable(hotTableRef.current, {
  //   data: products,
  //   rowHeaders: true,
  //   colHeaders: colHeaders,
  //   licenseKey: 'non-commercial-and-evaluation',
  //   afterChange: (changes, source) => {
  //     if (source !== 'loadData') {
  //       changes.forEach(([row, prop, oldVal, newVal]) => {
  //         // Update your original data source here
  //         products[row][prop] = newVal;
  //       });
  //     }
  //   }
  // });


  {/** Save the updated data version to the database*/}
  const saveChanges = () => {
    console.log("Changes saved. This function will be implemented later.");
  };

  // Function to download data as CSV
  const downloadData = () => {
    if (hotInstance.current) {
        const exportPlugin = hotInstance.current.getPlugin('exportFile');

        // Get filtered data
        const filteredData = hotInstance.current.getData();

        // Export filtered data as CSV
        exportPlugin.downloadFile('csv', {
            filename: 'FilteredData',
            data: filteredData,
            columnHeaders: true,
        });
    }
  };


  return (
    <>
      {/**Filtration section Apply filter button */}
      <div className="flex w-full flex-wrap items-center p-2">
       {/**First Group */}
       <div className="flex space-x-3 p-1 w-[40%]">
       {tableGroups.map((group, index) => (
          <FormControl key={index} className="flex flex-col p-2">
            <InputLabel id={`select-table-label-${index}`}
              sx={{
                fontWeight: 'bold',
                marginLeft: 2,
              }}>
                {group.title}
              </InputLabel>
              <Select
                multiple
                value={selectedTables.filter(table => table.groupIndex === index).map(table => table.value)}
                onChange={event => handleTableChange(index, event)}
                renderValue={(selected) => selected.join(', ')}
                sx={{ 
                  minWidth: 150, 
                  maxHeight: 40,
                }}>
              <MenuItem disabled value="">
                <em>{group.title}</em>
              </MenuItem>
              {group.tables.map((table) => (
                  <MenuItem key={table.value} value={table.value}>
                    <Checkbox checked={selectedTables.map(table => table.value).includes(table.value)} />
                    <ListItemText primary={table.label} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        ))}
        </div>

        {/** Selected Table Display */}
        <div className="flex flex-wrap gap-2 w-[38%]">
          {selectedTables.map((table, index) => (
            <div key={index} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/6 border p-2 flex justify-between items-center text-sm font-semibold">
              <span>{table.label}</span>
              <button onClick={() => handleDelete(table.value)} className="bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center">
                x
              </button>
            </div>
          ))}
        </div>
        
        {/**Action Buttons */}
        <div className="flex flex-row w-[22%]">
        <div className="mb-2 flex w-full justify-center px-2 md:mb-0 md:w-auto">
          <button onClick={() => setSelectedTables([])} className="flex flex-row gap-1 text-sm w-full rounded-md text-red-500 border-2 border-red-500 px-4 py-2 font-semibold hover:bg-red-500 hover:text-white sm:w-auto">
            <FcCancel className="text-sm mt-1"/>
            <span>Clear Data</span>
          </button>
        </div>
        <div className="mb-2 flex w-full justify-center px-2 md:mb-0 md:w-auto">
          <button className="flex flex-row gap-1 text-sm w-full rounded-md border-2 border-custom-blue px-4 py-2 font-semibold text-custom-blue hover:bg-custom-blue hover:text-white sm:w-auto">
            <FaUpload className="text-sm mt-1"/>
            <span>Fetch Data</span>
          </button>
        </div>
        </div>
      
      </div>
    


    {/** Table Section */}
    <div className="flex w-full gap-4">
    {/** Metrics Section*/}
    <div className="flex w-1/5 min-h-[550px] flex-col gap-4 justify-between items-center p-2 mt-6">
        <div className="flex flex-col gap-2">
        <div className="stats shadow p-3">
          <div className="stat">
            <div className="stat-title text-sm font-semibold text-gray-400">Total Records</div>
            <div className="stat-value text-4xl font-extrabold">{totalRecords}</div>
            <div className="stat-desc text-xs font-semibold text-gray-400">records resulted from query</div>
          </div>
      
    </div>
          {/* <div className="flex flex-col items-center justify-center bg-[#DCF6F7] w-24 h-24 m-2">
              <span className="text-2xl text-custom-blue font-semibold">{totalRecords}</span>
              <span className="text-sm text-slate-600 font-semibold">records</span>
          </div> */}
          {/* <div className="flex flex-col items-center justify-center bg-[#DCF6F7] w-24 h-24 m-2">
              <span className="text-xl text-custom-blue font-semibold">{displayedPercentage}%</span>
              <span className="text-sm text-slate-600 font-semibold">displayed</span>
          </div> */}
        </div>
        {/**Action Buttons */}
        <div className="flex flex-col gap-2 mb-4">
            <div className="mb-2 flex w-full justify-center px-2 md:mb-0 md:w-auto">
                <button onClick={downloadData} className="flex flex-row gap-1 text-sm w-full rounded-md text-custom-blue border-2 border-custom-blue px-6 py-2 font-semibold hover:bg-custom-blue hover:text-white sm:w-auto">
                    <FaDownload className="text-sm mt-1"/>
                    <span>Download Data</span>
                </button>
            </div>
             <div className="mb-2 flex w-full justify-center px-2 md:mb-0 md:w-auto">
                <button onClick={saveChanges} className="flex flex-row gap-1 text-sm w-full rounded-md bg-custom-blue px-8 py-2 font-semibold text-white hover:bg-blue-700 hover:text-white sm:w-auto">
                    <FaSave className="text-sm mt-1"/>
                    <span>Save Changes</span>
                </button>
            </div> 
        </div>
    </div>


      {/** Hashionabal Table Display Section */}
      <div className="flex-grow p-1 min-h-[550px] w-full overflow-x-auto mb-4">
        <div ref={hotTableRef} id="example" className="bg-blue-300 handsontable-container w-full h-[550px]"></div>
      </div>
    </div>
  </>
  );
}